import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/Header/Search/styles';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  debounce,
} from '@mui/material';
import { EdibleLogo } from 'components/Header/EdibleLogo';
import { HideUpMd } from 'utils/hidden/hide-up-md';
import { Key } from 'enums/key';
import { Links } from 'enums/common-links';
import {
  MOBILE_CLOSE_SEARCH_ARIA_LABEL,
  MOBILE_SEARCH_ARIA_LABEL,
} from 'components/Header/translations/locale.constants';
import { SEARCH_QUERY_KEY } from 'containers/PLPContainer/query.constants';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { SuggestedItem } from 'components/Header/Search/SuggestedItem';
import { SuggestionsList } from 'components/Header/Search/SuggestionsList';
import { dynamicEvents } from 'service/dynamic-yield';
import {
  fetchProductsCatalog,
  fetchSearchCatalog,
} from 'components/Header/feature/actions';
import {
  getIsPending,
  getProductCatalog,
  getSearchCatalog,
} from 'components/Header/feature/selectors';
import { getSearchItemUrl } from 'components/Header/utils';
import { isEmpty } from 'utils/is-empty';
import { isNotEmpty } from 'utils/is-not-empty';
import { regexEscape } from 'utils/regex';
import { root } from 'utils/root';
import { useDispatch, useSelector } from 'react-redux';
import { usePlpSearchText } from 'containers/PLPContainer/hooks/query/use-plp-search-text';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  FC,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import i18next from 'i18next';

const initHelperText = i18next.t(TK.SEARCH_HELPER_TEXT);
const searchHelperText = i18next.t(TK.SEARCH_SUGGESTED_RESULTS);
const noSuggestionsHelperText = i18next.t(TK.SEARCH_NO_SUGGESTIONS);
const searchingSuggestionsText = i18next.t(TK.SEARCHING_TEXT);

export const Search: FC = () => {
  const [text] = usePlpSearchText();
  const [searchTerm, setSearchTerm] = useState<string>(text);
  const [suggestedTitles, setSuggestedTitles] = useState<string[]>([]);
  const [isFullWidthSearch, setIsFullWidthSearch] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>(initHelperText);
  const inputRef = useRef<HTMLInputElement>(null);
  const baseInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const searchCatalog = useSelector(getSearchCatalog);
  const productCatalog = useSelector(getProductCatalog);
  const isPending = useSelector(getIsPending);
  const ref = useRef<HTMLDivElement>(null);
  const fullWidthSearchPadding = useRef<number>(
    root?.scrollWidth ? document.body.offsetWidth - root?.scrollWidth : 0,
  );

  useEffect(() => {
    setSearchTerm(text);
  }, [text]);

  useEffect(() => {
    if (isFullWidthSearch && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, isFullWidthSearch]);

  useEffect(() => {
    if (isFullWidthSearch && isEmpty(searchCatalog)) {
      dispatch(fetchSearchCatalog());
    }
  }, [dispatch, isFullWidthSearch, searchCatalog]);

  const regex = new RegExp(`(${regexEscape(searchTerm.trim())})`, 'i');

  const suggestions = useMemo(
    () =>
      searchCatalog
        .filter((item) => {
          const clearedSearchTerm = searchTerm.trim().toLowerCase();
          return (
            item.KeywordName.includes(clearedSearchTerm) ||
            item.Name.toLowerCase() === clearedSearchTerm
          );
        })
        .sort()
        .slice(0, 5),
    [searchCatalog, searchTerm],
  );

  useEffect(() => {
    setSuggestedTitles(
      isEmpty(suggestions) && isNotEmpty(productCatalog)
        ? productCatalog.map((item) => item.Name)
        : suggestions.map((item) => item.Name),
    );
  }, [productCatalog, suggestions]);

  const getProductsCatalog = useMemo(
    () =>
      debounce((searchQuery: string) => {
        dispatch(fetchProductsCatalog({ searchQuery }));
      }, 500),
    [dispatch],
  );

  useEffect(() => {
    if (isNotEmpty(searchTerm) && isEmpty(suggestions)) {
      getProductsCatalog(searchTerm);
    }
  }, [dispatch, getProductsCatalog, searchTerm, suggestions]);

  useEffect(() => {
    if (isNotEmpty(searchTerm)) {
      setHelperText(
        isEmpty(suggestedTitles) ? noSuggestionsHelperText : searchHelperText,
      );
    } else {
      setHelperText(initHelperText);
    }
  }, [isPending, productCatalog, searchTerm, suggestions, suggestedTitles]);

  const handleOpen = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === Key.Tab) {
      e.stopPropagation();
    } else {
      setIsFullWidthSearch(true);
      baseInputRef.current?.blur();
    }
  };

  const handleOpenMobile = () => {
    setIsFullWidthSearch(true);
    baseInputRef.current?.blur();
  };

  const handleClose = () => {
    setSearchTerm('');
    setIsFullWidthSearch(false);
    if (baseInputRef.current != null) {
      baseInputRef.current.focus();
    }
  };

  const fullWidthSearch = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === Key.Tab) {
      e.stopPropagation();
    }
    if (e.key === Key.Escape) {
      handleClose();
    }
    if (
      e.key === Key.Tab &&
      ref.current != null &&
      (e.target as HTMLDivElement).getAttribute('data-test') === 'search-close'
    ) {
      ref.current.focus();
    }
  };
  const submitSearchOption = (option: string): void => {
    const trimmedOption = option.trim();
    if (isEmpty(trimmedOption)) {
      return;
    }

    const baseUrl = window.location.origin;
    let url = `${baseUrl}${
      Links.FruitArrangements
    }?${SEARCH_QUERY_KEY}=${regexEscape(encodeURIComponent(trimmedOption))}`;

    const directSuggestion = suggestions.find(
      (item) => item.Name.toLowerCase() === trimmedOption.toLowerCase(),
    );

    if (directSuggestion) {
      url = `${baseUrl}/${getSearchItemUrl(directSuggestion.URL)}`;
    } else {
      suggestions.forEach((suggestion) => {
        const keywords = suggestion.KeywordName.toLowerCase().split(',');
        if (keywords.includes(trimmedOption.toLowerCase())) {
          url = `${baseUrl}/${getSearchItemUrl(suggestion.URL)}`;
        }
      });
    }

    dynamicEvents.keywordSearch(trimmedOption);
    window.location.href = url;
  };

  return (
    <>
      <ShowUpMd>
        <TextField
          inputRef={baseInputRef}
          value={searchTerm}
          sx={styles.baseInput}
          variant="outlined"
          data-test="search-tb"
          placeholder={i18next.t(TK.DESKTOP_SEARCH_PLACEHOLDER)}
          onChange={({ target }) => setSearchTerm(target.value)}
          onKeyUp={handleOpen}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon data-test="search-icon" />
              </InputAdornment>
            ),
          }}
        />
      </ShowUpMd>
      <HideUpMd>
        <IconButton
          aria-label={i18next.t(MOBILE_SEARCH_ARIA_LABEL)}
          color="secondary"
          onClick={handleOpenMobile}
        >
          <SearchIcon />
        </IconButton>
      </HideUpMd>

      <Drawer
        transitionDuration={0}
        anchor="top"
        keepMounted
        onKeyDown={fullWidthSearch}
        tabIndex={-1}
        ref={ref}
        open={isFullWidthSearch}
        onClose={handleClose}
        sx={styles.paper(fullWidthSearchPadding.current)}
        variant="persistent"
      >
        <Box sx={styles.drawerWrapper}>
          <ShowUpMd>
            <EdibleLogo />
          </ShowUpMd>
          <Box sx={styles.autocompleteWrapper}>
            <Autocomplete
              inputValue={searchTerm}
              open={!!searchTerm.length}
              disablePortal
              autoComplete
              freeSolo
              options={suggestedTitles}
              filterOptions={() => suggestedTitles}
              PopperComponent={SuggestionsList}
              forcePopupIcon={false}
              noOptionsText=""
              clearIcon={
                <CancelIcon
                  color={isEmpty(searchTerm) ? 'disabled' : 'inherit'}
                />
              }
              onInputChange={(_event, value) => setSearchTerm(value)}
              onChange={(_, option) => {
                if (option) {
                  setSearchTerm(option);
                  submitSearchOption(option);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Key.Escape) {
                  handleClose();
                }
              }}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    inputRef={inputRef}
                    autoComplete="off"
                    sx={styles.autocompleteInput}
                    variant="outlined"
                    helperText={
                      <Typography
                        component="span"
                        variant="body2"
                        sx={styles.helperText}
                      >
                        {isPending && isEmpty(suggestedTitles)
                          ? searchingSuggestionsText
                          : helperText}
                      </Typography>
                    }
                    InputProps={{
                      ...params.InputProps,
                      autoFocus: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <HideUpMd>
                    <IconButton
                      sx={styles.closeButtonMobile}
                      color="secondary"
                      onClick={handleClose}
                      aria-label={i18next.t(MOBILE_CLOSE_SEARCH_ARIA_LABEL)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </HideUpMd>
                </>
              )}
              renderOption={(props, option) => (
                <SuggestedItem
                  props={props}
                  option={option}
                  regex={regex}
                  key={option}
                />
              )}
            />
            {!isEmpty(searchTerm) && (
              <>
                <HideUpMd>
                  <Divider sx={styles.divider} />
                </HideUpMd>
                <Button
                  onClick={() => submitSearchOption(searchTerm)}
                  color="primary"
                  sx={styles.allResults}
                  data-test="search-all-results"
                >
                  {i18next.t(TK.ALL_RESULTS)}
                </Button>
              </>
            )}
          </Box>
          <ShowUpMd>
            <Button
              sx={styles.closeButton}
              onClick={handleClose}
              data-test="search-close"
            >
              {i18next.t(TK.CLOSE)}
            </Button>
          </ShowUpMd>
        </Box>
      </Drawer>
    </>
  );
};
