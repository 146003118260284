export enum Key {
  EnablePaymentMethodPayPal = 'EnablePaymentMethodPayPal',
  UsePaypalVersion = 'UsePaypalVersion',
  EnablePrintibleStrikeThrough = 'EnablePrintibleStrikeThrough',
  NavBannerInfo = 'NavBannerInfo',
  FloralBoxFeeSetting = 'FloralBoxFeeSetting',
  RelationshipSliderPosition = 'RelationshipSliderPosition',
}

export enum KeyConst {
  PaypalVersion = 'V2',
}
