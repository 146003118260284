import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';

export const printContainer = {
  container: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
    '@page': {
      size: 'A4',
    },
  },
  marginTop: { md: '0', sm: '-115px', xs: '130px' },
};

export const orderPrintContainer = {
  container: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
    '@page': {
      size: 'A4',
      marginLeft: '40px',
    },
  },
  marginTop: { md: '0', sm: '0', xs: '-60px' },
};

export const wrapper: SxProps = {
  fontWeight: 400,
  fontSize: 14,
  fontFamily: `${fonts.poppins} !important`,
  width: '100%',
  maxWidth: '1060px',
  margin: '10px 0px',
  lineHeight: '143%',
  color: colors.disabledBlack,
  '& .title': {
    height: '42px',
    fontFamily: fonts.poppins,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '34px',
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
    color: colors.activeBlack,
  },
  '& .confirmSection': {
    marginTop: '30px',
    display: 'inline-block',
    width: '100%',
  },
  '& .checkTxt': {
    alignItems: 'center',
    padding: '12px 16px',
    gap: '12px',
    height: { md: '64px', sm: '64px', xs: '100px' },
    background: 'rgba(76, 175, 80, 0.08)',
    borderRadius: '16px',
    marginTop: { md: '35px', sm: '35px', xs: '10px' },
    '& img': {
      width: '17px',
      height: '17px',
    },
  },
  '& .mailConfirmMsg': {
    width: { md: '61%', sm: '100%', xs: '100%' },
  },
  '& .orderContainer': {
    display: { md: 'flex', sm: 'flex', xs: 'block' },
  },
};

export const desc: SxProps<Theme> = (theme) => ({
  fontSize: '16px',
  width: { md: '610px', sm: '100%', xs: '100%' },
  maxWidth: '1060px',
  marginTop: { md: '20px', sm: '20px', xs: '60px' },
  lineHeight: '150%',
  color: theme.palette.text.primary,
  minHeight: { md: '70px', sm: '70px', xs: '90px' },
});

export const mail: SxProps = {
  fontSize: '16px',
  fontWeight: '600',
  display: 'contents',
};

export const redBtnStyle: SxProps<Theme> = (theme) => ({
  width: { md: '297px', sm: '48.5%', xs: '100%' },
  height: { md: '56px', sm: '56px', xs: '40px' },
  marginBottom: { md: '0', sm: '0', xs: '25px' },
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: '600',
  fontSize: '15px',
  marginRight: '15px',
});

export const btnStyle: SxProps<Theme> = (theme) => ({
  width: { md: '297px', sm: '48.5%', xs: '100%' },
  height: { md: '56px', sm: '56px', xs: '40px' },
  marginBottom: { md: '0', sm: '0', xs: '25px' },
  background: theme.palette.primary.contrastText,
  border: `1px solid ${theme.palette.primary.main}`,
  fontWeight: '600',
  fontSize: '15px',
});

export const orderTxt: SxProps<Theme> = (theme) => ({
  fontWeight: '600',
  fontSize: '20px',
  marginTop: { md: '56px', sm: '56px', xs: '30px' },
  width: { md: '46%', sm: '80%', xs: '100%' },
  color: theme.palette.text.primary,
});

export const recipientTxt: SxProps = {
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '143%',
  marginTop: { md: '60px', sm: '60px', xs: '20px' },
};

export const imgStyle: SxProps = {
  width: '22px',
  height: '14px',
  marginTop: { md: '60px', sm: '60px', xs: '20px' },
  marginRight: '5px',
};

export const confirmWrapper: SxProps = {
  width: { md: '95%', sm: '100%', xs: '100%' },
  marginBottom: { md: '0', sm: '20px', xs: '20px' },
};

export const breadcrumbWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: 1,
  maxWidth: 1060,
  my: 4,
  mx: 'auto',
  px: [2.5, 5, 0],
};
