import { ChangePasswordStatusEnum } from 'api/account/type/account-request';
import { ForgotPasswordForm } from 'components/ForgotPassword/Form/ForgotPasswordForm';
import { ForgotPasswordSkeleton } from 'components/ForgotPassword/Skeleton';
import { Grid } from '@mui/material';
import { InvalidResetPasswordCode } from 'components/ForgotPassword/Form/InvalidResetPasswordCode';
import { accountApiService } from 'api/account/account.api.service';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

export const ForgotPassword: React.VoidFunctionComponent = () => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [searchParams] = useSearchParams();
  const resetCode = searchParams.get('key') || '';
  const resetDate = searchParams.get('date') || '';

  const isValidKeyAndDate = useMemo(
    () => resetCode !== '' && resetDate !== '',
    [resetCode, resetDate],
  );

  const validateResetKeyAndDate = async () => {
    const response = await accountApiService.validateResetPasswordKeyAndDate(
      resetCode,
      resetDate,
    );
    setIsLoading(false);
    setIsValid(response !== ChangePasswordStatusEnum.INVALID_KEY_TOKEN);
  };

  useEffect(() => {
    if (isValidKeyAndDate) {
      validateResetKeyAndDate();
    } else {
      setIsLoading(false);
      setIsValid(false);
    }
  }, [isValidKeyAndDate]);

  const renderComponent = useMemo(() => {
    if (isLoading) {
      return <ForgotPasswordSkeleton />;
    }
    if (isValid) {
      return <ForgotPasswordForm />;
    }
    return <InvalidResetPasswordCode />;
  }, [isLoading, isValid]);

  return (
    <Grid
      container
      justifyContent="center"
      my={{ xs: 4, sm: 5, md: 8 }}
      mb={{ xs: 4, sm: 8, md: 10 }}
    >
      <Grid item xs={12} md={8}>
        {renderComponent}
      </Grid>
    </Grid>
  );
};
