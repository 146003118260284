import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { styled } from '@mui/material';

export const Image = styled('img')({
  display: 'block',
  width: '100%',
  minHeight: '62px',
});

export const Headline = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
  [theme.breakpoints.up('md')]: {
    marginBottom: 40,
  },
}));

export const title: ThemedSxProps = (theme) => ({
  whiteSpace: 'wrap',
  fontWeight: '600',
  fontSize: '24px',
  flex: '1 1 auto',
  letterSpacing: '0.15px',
  lineHeight: '133.4%',
  marginLeft: '-10px',
  pr: 1.2,
  [theme.breakpoints.up('xs')]: {
    textAlign: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    textAlign: 'left',
  },
});

export const CarouselContainer = styled('div')(({ theme }) => ({
  '& .slick-slider': {
    '& .slick-list': {
      margin: '0 0',
      '& .slick-track': {
        display: 'flex',
        '& .slick-slide': {
          margin: '0px 10px',
          width: '100%',
          '& img': {
            border: `1px solid ${colors.dividerBlack}`,
            borderRadius: '0px',
            [theme.breakpoints.up('xs')]: {
              width: '120px',
              height: '120px',
            },
            [theme.breakpoints.up('sm')]: {
              width: '144px',
              height: '144px',
            },
            [theme.breakpoints.up('md')]: {
              width: '180px',
              height: '180px',
            },
          },
        },
      },
    },
    '& .slick-arrow': {
      border: `1px solid ${colors.dividerBlack}`,
      background: colors.white,
      color: colors.activeBlack,
      top: '90px',
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'center',
      width: '50px',
      height: '50px',
      '::before': {
        content: 'none',
      },
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        display: 'none !important',
      },
    },
    '& .slick-next': {
      right: '-25px',
    },
    '& .slick-prev': {
      left: '-25px',
    },
  },
}));

export const arrowControls: ThemedSxProps = (theme) => ({
  display: 'flex',
  position: 'relative',
  '& button:first-of-type': {
    mr: 1.2,
    [theme.breakpoints.up('sm')]: {
      ml: 2.5,
      mr: 2.5,
    },
  },
});

export const wrapper: ThemedSxProps = (theme) => ({
  position: 'relative',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { xs: '14px', sm: '20px' },
  color: theme.palette.common.white,
});

export const textWrapper: ThemedSxProps = (theme) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { xs: '14px', sm: '20px' },
  color: theme.palette.common.white,
});

export const banner: ThemedSxProps = {
  position: 'relative',
  cursor: 'pointer',
  width: '100%',
  marginBottom: { xs: '20px', md: '40px' },
  textDecoration: 'none',
};

export const bannerText: ThemedSxProps = {
  font: 'inherit',
  fontWeight: 400,
};

export const giftFinderText: ThemedSxProps = (theme) => ({
  width: '81px',
  height: '22px',
  borderRadius: '100px',
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '22px',
  color: theme.palette.primary.contrastText,
  marginLeft: '14%',
  marginTop: '3%',
});

export const giftFinder: ThemedSxProps = {
  background: colors.primaryBlack,
  borderRadius: '100px',
  width: '107.28px',
  height: '26.26px',
  margin: '10px',
};

export const bgWrapper: ThemedSxProps = {
  width: '100%',
  textDecoration: 'none',
  cursor: 'pointer',
  fontFamily: fonts.poppins,
  minHeight: { md: '40px', sm: '67px', xs: '67px' },
  textAlign: 'center',
  marginBottom: { xs: '20px', md: '40px' },
  '&:hover': {
    textDecoration: 'none',
  },
};

export const bgBanner: ThemedSxProps = (theme) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { xs: '24px', sm: '30px' },
  minHeight: { md: '40px', sm: '67px', xs: '67px' },
  color: theme.palette.common.white,
  paddingTop: '5px',
  paddingBottom: '5px',
});

export const bgBannerText: ThemedSxProps = (theme) => ({
  fontWeight: 400,
  color: theme.palette.common.black,
  marginLeft: '5px',
  marginRight: '5px',
  height: 'auto',
});

export const CarouselContainerHome = styled('div')(({ theme }) => ({
  '& .slick-slider': {
    '& .slick-list': {
      margin: '0 0',
      '& .slick-track': {
        display: 'flex',
        '& .slick-slide': {
          margin: '0px 10px',
          width: '100%',
          '& img': {
            border: `1px solid ${colors.dividerBlack}`,
            borderRadius: '0px',
            [theme.breakpoints.up('xs')]: {
              width: '120px',
              height: '120px',
            },
            [theme.breakpoints.up('sm')]: {
              width: '144px',
              height: '144px',
            },
            [theme.breakpoints.up('md')]: {
              width: '180px',
              height: '180px',
            },
          },
        },
      },
    },
    '& .slick-arrow': {
      border: `1px solid ${colors.dividerBlack}`,
      background: colors.white,
      color: colors.activeBlack,
      top: '90px',
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'center',
      width: '50px',
      height: '50px',
      '::before': {
        content: 'none',
      },
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        display: 'none !important',
      },
    },
    '& .slick-next': {
      right: '-25px',
    },
    '& .slick-prev': {
      display: 'none !important',
    },
  },
}));
