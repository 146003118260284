import { AddressType } from 'types/cart.types';
import { Curbside } from 'enums/curbside';
import { Recipient } from 'redux/cart/cart.types';

export const NULL_RECIPIENT: Recipient = {
  addressType: AddressType.NotSpecified,
  address1: '',
  address2: '',
  company: '',
  cardMessage: '',
  cellPhone: '',
  phone: '',
  instructions: '',
  workPhone: '',
  cityMlId: 0,
  firstName: '',
  lastName: '',
  occasionId: 0,
  pickupTime: '',
  recipientEmail: '',
  fulfillmentDate: '',
  curbside: Curbside.NO,
  carColor: '',
  carModel: '',
  upsellItems: [],
};
