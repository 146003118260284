/* eslint-disable no-template-curly-in-string */
import * as TK from 'components/Cart/Details/translations/locale.constant';
import { sale } from 'components/Cart/Details/translations/locale.constant';

export const headingLocale = {
  [TK.CART_ITEM_COUNT_MULTI]: 'Cart: {{productCount}} Items',
  [TK.CART_ITEM_COUNT_SINGLE]: 'Cart: {{productCount}} Item',
  [TK.RECIPIENT_COUNT_MULTI]: '{{recipientCount}} Recipients',
  [TK.RECIPIENT_COUNT_SINGLE]: '{{recipientCount}} Recipient',
  [TK.CONTINUE_SHOPPING]: 'Continue Shopping',
  [TK.RETURN_TO_CART]: 'Return to Cart',
  [TK.CHECKOUT_NOW]: 'Checkout Now',
  [TK.RECIPIENT]: 'Recipient {{recipientNumber}}',
  [TK.RECIPIENT_VIEW_EDIT_BUTTON]: 'View/Edit',
  [TK.RECIPIENT_VIEW_EDIT_HIDE_BUTTON]: 'Hide',

  [TK.FREE_DELIVERY_COMPLETED]: 'Congrats! You’ve unlocked FREE Delivery!',
  [TK.FREE_DELIVERY_REMAINING]:
    '${{amount}} away from FREE Next-Day & Beyond Delivery!',
  [TK.FREE_DELIVERY_COMPLETED_MOBILE]:
    'Congrats! You’ve unlocked FREE Delivery!',
};

export const orderSummaryLocale = {
  [TK.TITLE]: 'Order Summary',
  [TK.TOTAL]: 'Order Total:',
  [TK.CART_TOTAL]: 'Cart Total:',
  [TK.SUBTOTAL]: 'Subtotal:',
  [TK.DELIVERY_FEE]: 'Delivery Fees:',
  [TK.DISTANCE_FEE]: 'Distance Surcharge:',
  [TK.MP_SHIPPING_FEE]: '{{string}} Shipping: ',
  [TK.SHIPMENT_CHARGE]: 'Shipment Charge:',
  [TK.DISCOUNT_TOTAL]: 'Discount Total:',
  [TK.MARKETPLACE_TAX]: 'Tax',
  [TK.MP_PROCESSING_FEE]: 'Processing Fee',
  [TK.STATE_TAX]: 'State Sales Tax',
  [TK.LOCAL_TAX]: 'Local Sales Tax',
  [TK.PROMO]: 'Promo Code:',
  [TK.DELIVERY_POLICY]: 'View our Delivery Policies',
  [TK.SHIPMENT_POLICY]: 'View our Shipping Policies',
  [TK.EDIT_DELIVERY_INFO]: 'Edit Delivery Info',
  [TK.EDIT_DELIVERY_TITLE]: 'Delivering To',
  [TK.RECIPIENT_TOTAL]: 'Recipient {{number}} Order Total:',
  [TK.MADE_IT_SPECIAL]: 'You Made it Special With:',
  [TK.MAKE_IT_EXTRA_SPECIAL]: 'Make This Gift Extra Special',
  [TK.GIFT_TXT]: 'Gift Message:',
  [TK.GREETING_TXT]: 'Premium Greeting Card',
  [TK.SAVE_EDIT_TEXT]: 'SAVE',
  [TK.CANCEL_EDIT_TEXT]: 'CANCEL',
  [TK.GIFT_MESSAGE_LABEL]: 'Gift Message',
  [TK.HELP_GREY_ICON]: 'Help_Grey_Icon',
  [TK.GREETING_MSG]:
    'Your premium greeting card includes your chosen card front and personal gift message. It will be printed by the selected Edible store and placed in an envelope alongside your order.',
  [TK.UPGRADE_TEXT]: 'You selected these upgrades',
  [TK.CYOA_TITLE1]: 'Box with',
  [TK.CYOA_TITLE2]: 'fruit/topping combinations',
  [TK.CYODB_TITLE]: 'Board with baked goods and dipped fruit/berries',
  [TK.CYODB_NAME]: 'Create Your Own Desserts Board',
  [TK.CYO_DESSERT_NAME]: 'Create Your Own Desserts Box - One Size',
  [TK.CYO_DIPPEDFRUIT_NAME]: 'Create Your Own Dipped Fruit Box',
  [TK.CYO_6COOKIEBOX_TITLE]: 'Box with up to 3 cookie combinations',
  [TK.CYO_12COOKIEBOX_TITLE]: 'Box with up to 6 cookie combinations',
  [TK.CYO_6COOKIEBOX_NAME]: 'Create Your Own Cookie Box - 6 Cookies',
  [TK.CYO_12COOKIEBOX_NAME]: 'Create Your Own Cookie Box - 12 Cookies',
  [TK.PROMO_TEXT]: 'Add Promo Code',
  [TK.PROMO_CODE_TEXT]: 'Promo Code:',
  [TK.PROMO_EXPIRES]: 'Expires: ',
  [TK.PROMO_MESSAGE]: ' - {{message}}',
  [TK.MY_EDIBLE_REWARDS_TITLE]: 'My Edible Rewards®',
  [TK.PACKAGE_TITLE1]: 'Bundle containing',
  [TK.PACKAGE_TITLE2]: 'special treats',
  [TK.ADDON_IMAGE]: 'addon image',
  [TK.UPGRADE_IMAGE]: 'upgrade image',
  [TK.COMBINATION_IMAGE]: 'combination image',
  [TK.PROMO_TOOLTIP]: 'You can use only one Reward or Promo code per order.',
  [TK.DELIVERY_TOOLTIP]:
    'These fees help cover the cost of delivery for store owners.',
  [TK.MP_DELIVERY_TOOLTIP]:
    'These fees are set by the vendor and help cover the cost of shipping for smaller orders.',
  [TK.DISTANCE_FEE_TOOLTIP]:
    'Additional surcharges may incur for delivery that exceeds 15 miles. See Terms of Use for more details',
  [TK.CURB_PICKUP]: 'Curbside Pickup',
  [TK.NO_INSTRUCTIONS]: 'NO INSTRUCTIONS',
  [TK.ONE_HOUR_DELIVERY]: 'One-Hour Delivery',
};

export const deliveryLocale = {
  [TK.DELIVERY_TITLE]: 'delivery information',
  [TK.DELIVERY_METHOD]: 'Delivery Method',
  [TK.DELIVERY_DAY]: 'Delivering On',
  [TK.DELIVERY_INSTRUCTIONS]: 'Delivery Instructions',
  [TK.DELIVERY_INSTRUCTIONS_PLACEHOLDER]:
    'Provide delivery instructions like crossroads, landmarks, door codes, and where to deliver your order.',
  [TK.DELIVERING_TO]: 'Delivering To',
  [TK.DELIVERY_TO]: 'Delivery To',
  [TK.DELIVERY_ON]: 'Delivery on',
  [TK.DELIVERY_FROM]: 'Delivering From',
  [TK.HAND_DELIVERY]: 'Hand Delivery',
};

export const pickupLocale = {
  [TK.PICKUP_TITLE]: 'Pickup Information',
  [TK.PICKUP_METHOD]: 'Pickup Method',
  [TK.PICKUP_DAY]: 'Pickup Date & Time',
  [TK.PICKUP_INSTRUCTIONS]: 'Pickup Instructions',
  [TK.PICKUP_INSTRUCTIONS_LABEL]: 'Pickup Instructions (optional)',
  [TK.PICKUP_INSTRUCTIONS_PLACEHOLDER]:
    'Provide pickup instructions like wrapping requests, whether you may be late or early, or if someone else might pickup your order on your behalf.',
  [TK.PICKUP_TO]: 'Name On Order',
  [TK.PICKUP_FROM]: 'Pickup From',
  [TK.IN_STORE_PICKUP]: 'In-Store Pickup',
  [TK.VEHICLE_MODEL]: 'Vehicle Model',
  [TK.VEHICLE_COLOR]: 'Vehicle Color',
  [TK.EDIT_PICKUP_INFO]: 'Edit Pickup Info',
  [TK.PICKUP_TIME_PASSED_TITLE]: 'Oh no! This pickup time has passed.',
  [TK.PICKUP_TIME_PASSED_DESCRIPTION]:
    'To continue, please change your pickup time using the field above.',
  [TK.PICKUP_DATE_PASSED_TITLE]: 'Oh no! This pickup date has passed.',
  [TK.PICKUP_DATE_PASSED_DESCRIPTION]:
    'To continue, please change your pickup date by clicking the link provided.',
  [TK.PICKUP_CHANGE_DATE]: 'Change Pickup Date',
  [TK.PICKUP_CHANGE_TIME]: 'Change your pickup time',
  [TK.PICKUP_TO_CONTINUE]: 'To continue, click',
  [TK.PICKUP_BELOW_CHANGE_TIME]: 'below to change your pickup time.',
  [TK.CURB_SIDE_INFO]: 'curbside pickup information',
  [TK.IN_STORE_PICK_UP_INFO]: 'in-store pickup information',
};

export const shipmentLocale = {
  [TK.SHIPPING_INFORMATION]: 'shipping information',
  [TK.SHIPPING_DATE_TITLE]: 'Shipment date',
  [TK.SHIPPING_WINDOW_DATE_TITLE]: 'Delivery window',
  [TK.SHIPMENT_TITLE]: 'Shipment Information',
  [TK.SHIPMENT_METHOD]: 'Delivery Method',
  [TK.SHIPMENT_DAY]: 'Delivering On',
  [TK.SHIPMENT_TO]: 'Delivering To',
  [TK.SHIPPER]: 'FedEx',
  [TK.EDIT_SHIPPING_INFO]: 'Edit Shipping Info',
  [TK.SHIPMENT_METHOD_NAME]: 'For more info, contact Customer Service ',
  [TK.SHIPMENT_METHOD_GOODY_NAME]:
    'For questions regarding your order, contact',
  [TK.SHIPMENT_METHOD_GOODY_NAME_2]:
    'and the Goody customer support team will be happy to help!',
  [TK.SHIPMENT_METHOD_NAME_2]: 'at: 678-992-2390',
};

export const disclaimerLocale = {
  [TK.DISCLAIMER_1]: 'Unless specified, product containers may vary.',
  [TK.DISCLAIMER_2]:
    'For flower arrangements, floral type and colors may vary.',
  [TK.HELP_ICON]: 'Product disclaimer for flowers',
};

export const orderDetailsLocale = {
  [sale.TITLE]: 'Total Charged:',
  [sale.ITEM_COUNT]: '{{Number}} Item',
  [sale.QTY]: '{{#}}x',
  [sale.ADDON_QTY]: 'x{{#}}',
  // eslint-disable-next-line no-template-curly-in-string
  [sale.PRICE]: '${{Price}}',
  [sale.SUMMARY]: 'Order Summary',
  [sale.DELIVERY_FEE]: 'Delivery Fees',
  [sale.SHIPMENT_CHARGE]: 'Shipment Charge',
  [sale.SERVICE_FEE]: 'Service Fee:',
  [sale.SUBTOTAL]: 'Subtotal',
  [sale.TOTAL]: 'Order Total',
};

export const complimentaryLocale = {
  [TK.COMPLIMENTARY_MESSAGE_TITLE]: 'Complimentary Gift Message',
  [TK.COMPLIMENTARY_GIFT_MESSAGE]: 'Gift Message:',
  [TK.COMPLIMENTARY_NO_CARD_MESSAGE]: 'NO CARD MESSAGE',
  [TK.ADD_GIFT_MESSAGE]: 'Add Gift Message',
  [TK.ADD_GIFT_MESSAGE_WARING_TEXT1]: 'Remember to include your name!',
  [TK.ADD_GIFT_MESSAGE_WARING_TEXT2]:
    'Your message is the only way the recipient will know who sent their gift.',
  [TK.FREE]: 'FREE',
  [TK.EDIT]: 'Edit',
  [TK.REMOVE]: 'Remove',
};

export const emptyCartLocale = {
  [TK.START_SHOPPING]: 'Start Shopping',
  [TK.EMPTY_TITLE]: 'Oh No!',
  [TK.EMPTY_SUBTITLE]: 'It Looks Like Your Cart Is Currently Empty.',
  [TK.EMPTY_DESCRIPTION]:
    'Click the button below to browse our products and select the perfect treat for you or your loved one. Then come back here when you’re ready to place your order!',
};

export const removeCartLocale = {
  [TK.REMOVE_DIALOG_TITLE]: 'Remove From Cart',
  [TK.REMOVE_DIALOG_MESSAGE]:
    'Removing this product from your cart will also remove any upgrades and add-ons selected for that product.',
  [TK.REMOVE_DIALOG_BACK]: 'Go Back',
  [TK.REMOVE_DIALOG_CONFIRM]: 'Confirm',
  [TK.REMOVE_BUTTON_TITLE]: 'Remove',
};
