import {
  DesktopUpdateAvailabilityParams,
  MobileUpdateAvailabilityParams,
} from 'api/legacy-session/types';
import { OrderType } from 'types/cart.types';

export const DEFAULT_DESKTOP_AVAILABILITY: DesktopUpdateAvailabilityParams = {
  AreaID: '',
  AreaName: '',
  SelectedDate: '',
  ServiceOption: '0',
  StoreID: '0',
  FlexOptionID: '0',
  FlexFromDate: '1/1/0001 12:00:00 AM',
  FlexToDate: '1/1/0001 12:00:00 AM',
  FlexDiscount: '0',
  IsFlex: false,
  CurrentRecipientID: '0',
  ContactRecipientID: '0',
  DeliveryFeeDetailID: '0',
  ConfigDeliveryFeeCharges: '0',
  ConfigDeliveryDate: '1/1/0001 12:00:00 AM',
  IsCateringProductSelected: 'false',
  ArrangementGroupID: '5',
};

export const DEFAULT_MOBILE_AVAILABILITY: MobileUpdateAvailabilityParams = {
  AreaID: -2147483648,
  AreaName: '',
  SelectedDate: '',
  ServiceOption: OrderType.NotSpecified,
  StoreID: -2147483648,
  StoreName: '',
  StoreAddress: '',
  StorePhone: '',
  IsSet: true,
  IsFlex: false,
  IsSkipped: false,
  FlexFromDate: '1/1/0001 12:00:00 AM',
  FlexToDate: '1/1/0001 12:00:00 AM',
  FlexDiscount: 0,
  FlexOptionID: 0,
  CurrentRecipientID: -2147483648,
  ContactRecipientID: -2147483648,
  RecipientProductDetails: {
    ArrangementID: 5774,
    ArrangementGroupID: 344,
    ArrangementSizeID: 10006,
    ArrangementOption: '',
    ArrangementOptionID: 0,
    Quantity: 1,
    ProductID: 12886,
    AddonItems: [
      {
        ProductID: 0,
        Price: 0,
        Quantity: 1,
        ItemType: 0,
        OptionText: '',
        Sleeve: false,
        ImagePrefixSleeve: '',
        DefaultSleeve: false,
        ProductName: '',
        ProductImagePath: '',
        ParentProductID: 0,
        Numbers: null,
        Years: null,
        AddOnType: 0,
        CYOA: false,
        PrintibleCard: null,
        PrintibleCardResponse: null,
        Letters: null,
      },
    ],
    UpgradeItems: [
      {
        ProductID: 0,
        Price: 0,
        Quantity: 1,
        ItemType: 0,
        OptionText: '',
        Sleeve: false,
        ImagePrefixSleeve: '',
        DefaultSleeve: false,
        ProductName: '',
        ProductImagePath: '',
        ParentProductID: 0,
        Numbers: null,
        Years: null,
        AddOnType: 0,
        CYOA: false,
        PrintibleCard: null,
        PrintibleCardResponse: null,
        Letters: null,
      },
    ],
    FreeProducts: [null],
    ArrangementSleeve: false,
    IsCYOArrangement: false,
  },
  RecipientDetails: null,
  ArrangementSubType: 1,
  DeliveryFeeDetailID: -2147483648,
  ConfigDeliveryFeeCharges: 0,
  ConfigDeliveryDate: '',
  Catering: false,
  ArrangementGroupID: 5,
  StateCode: '',
  IsAvailableAfterTwoDays: false,
};
