import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/OrderConfirmation/OrderDetails/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { ChildProduct } from 'components/OrderConfirmation/OrderDetails/ChildProduct';
import { CombinationProduct } from 'components/OrderConfirmation/OrderDetails/CombinationProduct';
import { FreeProduct } from 'components/common/OrderDetails/FreeProduct';
import { HideOnXs } from 'utils/hidden/hide-on-xs';
import { Image } from 'components/common/Image/Image';
import { OrderItem, OrderSubItem } from 'redux/order-confirmation/types/types';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { ShowUpXs } from 'utils/hidden/show-up-xs';
import { UpgradeProduct } from 'components/OrderConfirmation/OrderDetails/Upgrade';
import { productUrl } from 'components/CustomerService/OrderTracking/utils/utils';
import { roundNumberValue } from 'utils/number-calculations/roundTwoDecimals';
import {
  selectAllAddOns,
  selectAllFreeProducts,
  selectAllUpgrades,
  selectCYOAtOrderSubItemCount,
} from 'redux/order-confirmation/selectors';
import { updateAssetUrls } from 'utils/assets-url-builder';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FC, useState } from 'react';
import i18next from 'i18next';

interface Props {
  product: OrderItem;
  childProducts?: OrderSubItem[];
}

export const Product: FC<Props> = ({ product, childProducts }) => {
  const CYOAtOrderSubItemCount = useSelector((state) =>
    selectCYOAtOrderSubItemCount(state, product.orderItemID),
  );
  const upgradesProducts = useSelector((state) =>
    selectAllUpgrades(state, product.orderItemID),
  );
  const freeProducts = useSelector((state) =>
    selectAllFreeProducts(state, product.orderItemID),
  );
  const addonsProducts = useSelector((state) =>
    selectAllAddOns(state, product.orderItemID),
  );
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleChange = () => () => {
    setExpanded(!expanded);
  };
  const [expandedUpgrade, setExpandedUpgrade] = useState<boolean>(true);

  const handleChangeUpgrade = () => () => {
    setExpandedUpgrade(!expandedUpgrade);
  };

  return (
    <>
      <Box>
        <Box sx={styles.product}>
          <Image
            src={updateAssetUrls(productUrl(`${product.image}`))}
            alt={i18next.t(TK.ORDER_CONFIRMATION_PAGE_PRODUCT_IMAGE)}
            errorFallback={productUrl(`${product.imagePrefix}${product.image}`)}
          />
          <Box width="100%" ml={2}>
            <HideOnXs>
              <Box
                className="productDescription"
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
                flexWrap="nowrap"
              >
                <PureHtmlTypography
                  sx={styles.productNameStyle}
                  variant="subtitle2"
                >
                  {product.name
                    .replace(`- ${product.sizeName}`, '')
                    .replace(`: ${product.sizeName}`, '')}
                </PureHtmlTypography>
                <Typography variant="subtitle2" sx={styles.totalStyle} mt={1}>
                  {`$${roundNumberValue(product.quantity * product.price)}`}
                </Typography>
              </Box>
            </HideOnXs>
            <ShowUpXs>
              <Box
                className="productDescription"
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
                flexWrap="nowrap"
              >
                <PureHtmlTypography
                  sx={styles.productNameStyle}
                  variant="subtitle2"
                >
                  {product.name
                    .replace(`- ${product.sizeName}`, '')
                    .replace(`: ${product.sizeName}`, '')}
                </PureHtmlTypography>
              </Box>
            </ShowUpXs>
            <HideOnXs>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="nowrap"
                flexDirection="row"
              >
                <Typography sx={styles.sizeStyle} mt={1} variant="subtitle2">
                  {product.sizeName}
                </Typography>
                <Typography variant="subtitle2" mt={1} sx={styles.qtyStyle}>
                  {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SALE_QUANTITY, {
                    '#': product.quantity,
                  })}
                </Typography>
                <Typography sx={styles.qtyCont} mt={1} variant="subtitle2">
                  {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SALE_PRICE, {
                    '#': product.quantity,
                    Price: roundNumberValue(product.price),
                  })}
                </Typography>
              </Box>
            </HideOnXs>
            <ShowUpXs>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="nowrap"
                flexDirection="row"
              >
                <Typography sx={styles.sizeStyle} mt={1} variant="subtitle2">
                  {product.sizeName}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography variant="subtitle2" mt={1} sx={styles.qtyStyle}>
                  {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SALE_QUANTITY, {
                    '#': product.quantity,
                  })}
                </Typography>
                <Typography sx={styles.qtyCont} mt={1} variant="subtitle2">
                  {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SALE_PRICE, {
                    '#': product.quantity,
                    Price: roundNumberValue(product.price),
                  })}
                </Typography>
                <Typography variant="subtitle2" sx={styles.totalStyle} mt={1}>
                  {`$${roundNumberValue(product.quantity * product.price)}`}
                </Typography>
              </Box>
            </ShowUpXs>
          </Box>
        </Box>
        {(product.buildOwnBox === true || product.CYOArrangement === true) && (
          <Box sx={styles.addonPosStyle}>
            <Accordion
              expanded={expanded}
              onChange={handleChange()}
              sx={styles.noSpace}
              className="test"
              style={{ position: 'inherit', width: '100%' }}
            >
              <AccordionSummary
                sx={styles.accordianText}
                expandIcon={<ExpandMoreIcon />}
                className="test2"
              >
                <Box width="100%">
                  {childProducts &&
                    childProducts.length !== 0 &&
                    (product.name.includes(
                      `${i18next.t(
                        TK.ORDER_CONFIRMATION_PAGE_CYO_DESSERT_NAME,
                      )}`,
                    ) ||
                      product.name.includes(
                        `${i18next.t(
                          TK.ORDER_CONFIRMATION_PAGE_CYO_DIPPEDFRUIT_NAME,
                        )}`,
                      )) && (
                      <Typography variant="body2">
                        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_CYOA_TITLE1)}{' '}
                        {CYOAtOrderSubItemCount}{' '}
                        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_CYOA_TITLE2)}
                      </Typography>
                    )}
                  {product.name.includes(
                    `${i18next.t(TK.ORDER_CONFIRMATION_PAGE_CYODB_NAME)}`,
                  ) && (
                    <Typography sx={styles.colorTxt} variant="body2">
                      {i18next.t(TK.ORDER_CONFIRMATION_PAGE_CYODB_TITLE)}
                    </Typography>
                  )}
                  {product.name.includes(
                    `${i18next.t(
                      TK.ORDER_CONFIRMATION_PAGE_CYO_6COOKIEBOX_NAME,
                    )}`,
                  ) && (
                    <Typography sx={styles.colorTxt} variant="body2">
                      {i18next.t(
                        TK.ORDER_CONFIRMATION_PAGE_CYO_6COOKIEBOX_TITLE,
                      )}
                    </Typography>
                  )}
                  {product.name.includes(
                    `${i18next.t(
                      TK.ORDER_CONFIRMATION_PAGE_CYO_12COOKIEBOX_NAME,
                    )}`,
                  ) && (
                    <Typography sx={styles.colorTxt} variant="body2">
                      {i18next.t(
                        TK.ORDER_CONFIRMATION_PAGE_CYO_12COOKIEBOX_TITLE,
                      )}
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={styles.noSpace}>
                <Box
                  display="contents"
                  justifyContent="space-between"
                  flexDirection={['column', 'row']}
                  className="productContainer"
                >
                  {childProducts?.map(
                    (childProduct) =>
                      childProduct.parentOrderItemID === product.orderItemID &&
                      childProduct.productID !==
                        window.PRINTABLE_ADD_ON_PRODUCT_ID &&
                      childProduct.name.includes('CYO') && (
                        <CombinationProduct
                          key={childProduct.id}
                          product={childProduct}
                        />
                      ),
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {upgradesProducts && upgradesProducts.length !== 0 && (
          <Box
            sx={
              product.buildOwnBox === true || product.CYOArrangement === true
                ? styles.upgradePosStyle
                : styles.upgradePosStyleTop
            }
            mb={2}
          >
            <Accordion
              expanded={expandedUpgrade}
              onChange={handleChangeUpgrade()}
              sx={styles.noSpace}
              className="test"
              style={{ position: 'inherit', width: '100%' }}
            >
              <AccordionSummary
                sx={styles.accordianText}
                expandIcon={<ExpandMoreIcon />}
                className="test2"
              >
                <Box mt={1}>
                  <Typography sx={styles.colorTxt}>
                    {i18next.t(TK.ORDER_CONFIRMATION_PAGE_UPGRADE_TEXT)}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={styles.noSpace}>
                {upgradesProducts?.map(
                  (upgradesProduct) =>
                    upgradesProduct.parentOrderItemID === product.orderItemID &&
                    upgradesProduct.productID !==
                      window.PRINTABLE_ADD_ON_PRODUCT_ID && (
                      <UpgradeProduct
                        key={upgradesProduct.id}
                        productUpgrade={upgradesProduct}
                      />
                    ),
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {!!freeProducts &&
          !!freeProducts?.length &&
          freeProducts?.map(
            (freeProduct) =>
              freeProduct.parentOrderItemID === product.orderItemID &&
              freeProduct.productID !== window.PRINTABLE_ADD_ON_PRODUCT_ID && (
                <FreeProduct key={freeProduct.id} freeProduct={freeProduct} />
              ),
          )}
        {product.buildOwnBox === true ||
          product.CYOArrangement === true ||
          (addonsProducts && addonsProducts?.length !== 0 && (
            <Typography sx={styles.splText} mt={4}>
              {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SPL_TXT)}:
            </Typography>
          ))}
        {addonsProducts &&
          (addonsProducts?.length > 1 || addonsProducts?.length === 1) &&
          (product.buildOwnBox === true || product.CYOArrangement === true) && (
            <Typography variant="subtitle2" mt={4}>
              {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SPL_TXT)}
            </Typography>
          )}
        {addonsProducts?.map(
          (addonsProduct) =>
            addonsProduct.parentOrderItemID === product.orderItemID &&
            addonsProduct.productID !== window.PRINTABLE_ADD_ON_PRODUCT_ID && (
              <ChildProduct key={addonsProduct.id} product={addonsProduct} />
            ),
        )}
      </Box>
    </>
  );
};
