import { ApiBaseService } from 'api/apibase.service';
import { AvailabilitySession } from 'redux/session/availability/availability.types';
import { AxiosPromise } from 'axios';
import {
  CallCenterSession,
  CallCenterSessionParams,
} from 'api/session/types/call-center-session.interface';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';
import {
  MarketplaceRecipient,
  Recipient,
} from 'api/session/types/recipient.interface';
import {
  UserAccountInformation,
  UserSession,
} from 'providers/Session/feature/user.types';

const path = IS_MOBILE_HOST ? '/mobile/availability' : '/availability';

class SessionApiService extends ApiBaseService {
  getUser(): Promise<UserSession> {
    return this.apibase
      .get<UserSession>('/sessions/user', {
        headers: { 'Cache-Control': 'no-cache, no-store' },
      })
      .then(({ data }) => data);
  }

  getUserAccountInformation(userId: number): Promise<UserAccountInformation> {
    return this.apibase
      .post<UserAccountInformation>(
        `/sessions/user/account-information/${userId}`,
        {
          headers: { 'Cache-Control': 'no-cache, no-store' },
        },
      )
      .then(({ data }) => data);
  }

  getAvailability(): Promise<AvailabilitySession> {
    return this.apibase
      .get<AvailabilitySession>(`/sessions${path}`, {
        headers: { 'Cache-Control': 'no-cache, no-store' },
      })
      .then(({ data }) => data);
  }

  getRecipients(): Promise<Recipient[]> {
    return this.apibase
      .get<Recipient[]>('/sessions/recipients', {
        headers: { 'Cache-Control': 'no-cache, no-store' },
      })
      .then(({ data }) => data);
  }

  createCallCenterSession(
    callCenterSessionParams: CallCenterSessionParams,
  ): Promise<CallCenterSession> {
    return this.apibase
      .post<CallCenterSession>(
        '/sessions/call-center-mode',
        callCenterSessionParams,
      )
      .then(({ data }) => data);
  }

  setCreditedStore(data: string): AxiosPromise<string> {
    return this.apibase.post<string>('/sessions/credited-store', {
      creditedStoreNumber: data,
    });
  }

  setCartCount(data: number): AxiosPromise<number> {
    return this.apibase.post<number>('/sessions/cart-count', {
      cartCount: data,
    });
  }

  setOrderConfirmationShown(data: boolean): AxiosPromise<boolean> {
    return this.apibase.post<boolean>('/sessions/order-confirmation', {
      isOrderConfirmationShown: data,
    });
  }

  updateSaleId(data: number): AxiosPromise<string> {
    return this.apibase.post<string>('/sessions/update-saleid', {
      saleId: data,
    });
  }

  updateMarketplaceRecipients(
    data: MarketplaceRecipient,
  ): AxiosPromise<MarketplaceRecipient> {
    return this.apibase.post<MarketplaceRecipient>(
      '/sessions/update-marketplace-recipients',
      data,
    );
  }
}

const sessionApiService = new SessionApiService();

export { sessionApiService };
