import * as TK from 'components/ForgotPassword/translations/locale.constants';
import * as styles from 'components/ForgotPassword/styles';
import { Image } from 'components/common/Image/Image';
import {
  rewardsTopBannerDesktop,
  rewardsTopBannerPhone,
  rewardsTopBannerTablet,
} from 'utils/cloudinaryAssets';
import React from 'react';
import i18next from 'i18next';

type TBannerImageProps = {
  size: 'desktop' | 'phone' | 'tablet';
};

export const BannerImage: React.VoidFunctionComponent<TBannerImageProps> = ({
  size,
}) => {
  const bannerSrc = (() => {
    switch (size) {
      case 'desktop':
        return rewardsTopBannerDesktop;
      case 'tablet':
        return rewardsTopBannerTablet;
      case 'phone':
        return rewardsTopBannerPhone;
      default:
        throw new Error(`Invalid size: ${size as string}`);
    }
  })();

  return (
    <Image
      alt={i18next.t(TK.REWARDS_TOP_BANNER)}
      src={bannerSrc}
      sx={styles.bannerImg}
    />
  );
};
