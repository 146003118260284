import { AddToCartProduct } from 'service/adobe-analytics/types';
import {
  Arrangement,
  Product,
} from 'redux/PDP/arrangement/types/arrangement.types';
import { AvailabilityOnDate } from 'api/availability-on-date/types/availability-on-date.interface';
import { CaseItem } from 'redux/case/caseOrder/case-order.types';
import {
  Order,
  OrderItem,
  OrderSubItem,
  Sale,
} from 'redux/order-confirmation/types/types';
import { Store } from 'redux/types/store.types';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { UpsellOption } from 'components/PDP/Upsells/feature/types/upsell-option';
import {
  UserAccountInformation,
  UserSession,
} from 'providers/Session/feature/user.types';
import { getByType } from 'utils/getProductPostfixByType';
import { sha256 } from 'crypto-hash';
import Cookies from 'js-cookie';

const { href: url, pathname } = window.location;
const { title, referrer } = window.document;

const getFriendlyUrl = () =>
  window.location.pathname.replace(/.*\/([^/]+)\/*$/, '$1');

export class SegmentAnalytics {
  protected analytics: SegmentAnalytics.AnalyticsJS;

  private static instance: SegmentAnalytics;

  constructor() {
    this.analytics = window.analytics;
  }

  public gclid = (): string => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('gclid') || '';
  };

  public fbclid = (): string => {
    const cookieValue = Cookies.get('_fbc');
    return cookieValue || '';
  };

  public fbp = (): string => {
    const cookieValue = Cookies.get('_fbp');
    return cookieValue || '';
  };

  public static getInstance(): SegmentAnalytics {
    if (!SegmentAnalytics.instance) {
      SegmentAnalytics.instance = new SegmentAnalytics();
    }
    return SegmentAnalytics.instance;
  }

  public identified = async (
    user: UserSession,
    accountInformation?: UserAccountInformation,
  ): Promise<void> => {
    const hashedEmail = await sha256(user.userEmail.toLowerCase());

    analytics.identify(`${hashedEmail}`, {
      name: user.userName,
      email: user.userEmail.toLowerCase(),
      cartCount: user.itemsInCart,
      accountInfo: accountInformation?.accountInfo || [],
      orderHistory: accountInformation?.accountOrderHistory || {},
      rewards: accountInformation?.rewards || [],
    });
  };

  public identify = async (email: string, name?: string): Promise<void> => {
    const hashedEmail = await sha256(email.toLowerCase());
    analytics.identify(`${hashedEmail}`, {
      name,
      email: email.toLowerCase(),
    });
  };

  public pageViewed = (
    name: string,
    productName?: string,
    catalogNumber?: number,
    variant?: Product[],
    brand?: string[],
    storeNumber?: number,
    storeAddress?: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.page(
      name,
      {
        name,
        title,
        url,
        pathname,
        productName,
        catalogNumber,
        variant,
        referrer,
        brand,
        storeNumber,
        storeAddress,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public trackSearched = (
    searchValue: string,
    searchCount: number,
    searchSKUResults: string[],
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Products Searched',
      {
        searchValue,
        searchCount,
        searchSKUResults,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public marketingOptIn = (
    signupValue: string,
    optInProperty: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Marketing Opt-In',
      {
        signupValue,
        optInProperty,
        url,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email:
              optInProperty === 'Email' ? signupValue : user?.userEmail || '',
            phone:
              optInProperty === 'SMS'
                ? signupValue
                : accountInformation?.accountInfo[0].cellPhone ||
                  accountInformation?.accountInfo[0].workPhone ||
                  accountInformation?.accountInfo[0].homePhone ||
                  '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public menuItemClicked = (
    menuItem: string,
    link?: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Menu Item Clicked',
      {
        menuItem,
        referrer,
        link,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public bannerClicked = (
    link: string,
    name: string,
    bannerPlacement: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Banner Clicked',
      {
        referrer,
        link,
        name,
        bannerPlacement,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public giftFinderClicked = (
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Gift Finder Clicked',
      {
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail,
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone,
            birthday: accountInformation?.accountInfo[0].birthday,
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city,
              state: accountInformation?.accountInfo[0].stateAbbreviation,
            },
          },
        },
      },
    );
  };

  public giftFinderDropDownUsed = (
    occasion: string[],
    price: string[],
    fruit: string[],
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Gift Finder Drop-Down Used',
      {
        occasion,
        price,
        fruit,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public occasionSelected = (
    name: string,
    link: string,
    sequence: number,
    location: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Occasion Selected',
      {
        name,
        link,
        sequence,
        location,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public productClicked = (
    name: string,
    link: string,
    sequence: number,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Product Clicked',
      {
        name,
        link,
        sequence,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public productVariantClicked = (
    product: Product,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Product Variant Clicked',
      {
        referrer,
        name: product.name,
        price: product.price,
        arrangementSizeId: product.arrangementSizeId,
        friendlyUrl: getFriendlyUrl(),
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public productQuantityChanged = (
    product: Product,
    quantity: number,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Product Quantity Changed',
      {
        referrer,
        name: product.name,
        price: product.price,
        arrangementSizeId: product.arrangementSizeId,
        quantity,
        friendlyUrl: getFriendlyUrl(),
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public productReviewsExpanded = (
    arrangement: Arrangement,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Product Reviews Expanded',
      {
        referrer,
        name: arrangement.name,
        catalogNumber: arrangement.catalogNumber,
        friendlyUrl: getFriendlyUrl(),
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public fulfillmentStoreExpanded = (
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Fulfillment Store Expanded',
      {
        referrer,
        friendlyUrl: getFriendlyUrl(),
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public fulfillmentStoreClicked = (
    selectedStore: Store,
    sequence: number,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Product Page Fulfillment Store Selected',
      {
        referrer,
        friendlyUrl: getFriendlyUrl(),
        selectedStore,
        sequence: sequence + 1,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public userLoginSuccess = async (
    userEmail: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): Promise<void> => {
    const hashedEmail = await sha256(userEmail.toLowerCase());
    analytics.track(
      'User Login Success',
      {
        referrer,
        url,
        email: userEmail.toLowerCase(),
        user_id: hashedEmail,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: userEmail || user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public userForgotPassword = async (
    userEmail: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): Promise<void> => {
    const hashedEmail = await sha256(userEmail.toLowerCase());
    analytics.track(
      'User Forgot Password Request',
      {
        referrer,
        url,
        email: userEmail.toLowerCase(),
        user_id: hashedEmail,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: userEmail || user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public storeLockResetClicked = (
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Store Lock Reset Clicked',
      {
        referrer,
        url,
        friendlyUrl: getFriendlyUrl(),
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public showMoreAddonsClicked = (
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Show More Addons Clicked',
      {
        referrer,
        url,
        friendlyUrl: getFriendlyUrl(),
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public addOnSelected = (
    upsell: UpsellOption,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Add-On Selected',
      {
        referrer,
        url,
        friendlyUrl: getFriendlyUrl(),
        upsell,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public printibleSelected = (
    location: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Printible Selected',
      {
        referrer,
        url,
        friendlyUrl: getFriendlyUrl(),
        location,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public complimentaryMessageSelected = (
    location: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Complimentary Message Selected',
      {
        referrer,
        url,
        friendlyUrl: getFriendlyUrl(),
        location,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public areaNotServicedEvent = (
    zipCode: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Area Not Serviced Event',
      {
        referrer,
        url,
        friendlyUrl: getFriendlyUrl(),
        zipCode,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public deliveryTypeSelected = (
    location: string,
    date?: Date | null,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Delivery Type Selected',
      {
        referrer,
        url,
        friendlyUrl: getFriendlyUrl(),
        deliveryDateSelected: date,
        location,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public trackFulfillmentMethod = (
    shippingZipCode?: string,
    selectedDeliveryDate?: Date | null,
    availableDeliveryMethods?: AvailabilityOnDate,
    hasPickupAvailable?: boolean,
    hasDeliveryAvailable?: boolean,
    shippingOnly?: boolean,
    previousStepComplete?: boolean,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Checkout Step Viewed',
      {
        referrer,
        url,
        shippingZipCode,
        selectedDeliveryDate,
        availableDeliveryMethods,
        hasPickupAvailable,
        hasDeliveryAvailable,
        shippingOnly,
        previousStepComplete,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public orderTrackSuccess = (
    orderNumber: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Order Track Success',
      {
        referrer,
        orderNumber,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public orderTrackFailed = (
    orderNumber: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Order Track Failed',
      {
        referrer,
        orderNumber,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public contactUsFormSubmitted = (
    caseData: CaseItem[],
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    analytics.track(
      'Contact Us Form Submitted',
      {
        caseData,
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public productAdded = ({
    product,
    addonItems,
    user,
    accountInformation,
  }: {
    product: AddToCartProduct;
    addonItems: UpsellItem[];
    user?: UserSession;
    accountInformation?: UserAccountInformation;
  }): void => {
    const addons = addonItems.map((addon) => ({
      productInfo: {
        id: `${addon.option.productId}${getByType(addon.productType)}`,
        units: Number(addon.quantity),
        price: addon.option.price,
      },
    }));

    analytics.track(
      'Product Added',
      {
        products: [{ productInfo: product }, ...addons],
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: user?.userEmail || '',
            phone:
              accountInformation?.accountInfo[0].cellPhone ||
              accountInformation?.accountInfo[0].workPhone ||
              accountInformation?.accountInfo[0].homePhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: (user && user.userName.split(' ')[0]) || '',
            lastName: (user && user.userName.split(' ')[1]) || '',
            address: {
              city: accountInformation?.accountInfo[0].city || '',
              state: accountInformation?.accountInfo[0].stateAbbreviation || '',
            },
          },
        },
      },
    );
  };

  public orderCompleted = (
    saleData: Sale,
    order: Order[],
    products: OrderItem[],
    addOns: OrderSubItem[],
    expressFulfillment?: string,
    user?: UserSession,
    accountInformation?: UserAccountInformation,
  ): void => {
    let fulfillment = '';
    const productsInfo = products.map((product) => ({
      productInfo: {
        id:
          product.type === 2
            ? `${product.productID}_package`
            : `${product.productID}_arr`,
        price: `${product.total}`,
        units: `${product.quantity}`,
        discount: `${product.discount}`,
      },
    }));

    const addOnInfo = addOns
      .filter((a) => a.type === 4 && a.itemType !== 2)
      .map((addOn) => ({
        productInfo: {
          id: `${addOn.productID}_addon`,
          price: `${addOn.total}`,
          units: `${addOn.quantity}`,
          discount: `${addOn.discount}`,
        },
      }));

    const upgradeInfo = addOns
      .filter((a) => a.type === 3)
      .map((addOn) => ({
        productInfo: {
          id: `${addOn.productID}_upgrade`,
          price: `${addOn.total}`,
          units: `${addOn.quantity}`,
          discount: `${addOn.discount}`,
        },
      }));

    if (order[0].orderType === 1) fulfillment = 'hand delivery';
    if (order[0].orderType === 2) fulfillment = 'pickup';
    if (order[0].orderType === 3) fulfillment = 'shipment';

    const deliveryMethod = expressFulfillment || fulfillment;

    analytics.track(
      'Order Completed',
      {
        products: [...productsInfo, ...addOnInfo, ...upgradeInfo],
        transaction: {
          couponCode: order[0].couponCode,
          deliveryOrShippingAmount: `${order[0].deliveryOrShipmentCharge}`,
          deliveryOrShippingDiscount: `${order[0].deliveryOrShipmentChargeDiscount}`,
          fulfillingStoreNumber: `${order[0].storeNumber}`,
          fulfillmentMethod: fulfillment,
          orderDiscount: `${order[0].orderDiscount}`,
          orderID: `${order[0].number}`,
          orderOccasion: `${order[0].occasion}`,
          paymentMethod: 'Visa',
          recipientZipCode: `${order[0].area}`,
          taxAmount: `${
            order[0].orderTax1 +
            order[0].orderTax2 +
            order[0].orderTax3 +
            order[0].orderTax4 +
            order[0].orderTax5
          }`,
          totalAmount: `${order[0].orderTotal}`,
          transactionID: `${order[0].number}`,
          deliveryMethod,
        },
        gclid: this.gclid(),
        fbclid: this.fbclid(),
        fbp: this.fbp(),
      },
      {
        context: {
          traits: {
            email: saleData.email || user?.userEmail,
            phone:
              saleData.cellPhone ||
              saleData.homePhone ||
              saleData.workPhone ||
              '',
            birthday: accountInformation?.accountInfo[0].birthday || '',
            firstName: saleData.customerName.split(' ')[0] || '',
            lastName: saleData.customerName.split(' ')[1] || '',
            address: {
              city: saleData.city || '',
              state: saleData.state || '',
              postalCode: saleData.area || '',
            },
          },
        },
      },
    );
  };

  public changedRelationshipStatus = (
    type: string,
    bannerPosition: number,
  ): void => {
    analytics.track(`Valentines day slider relations ship changed`, {
      referrer,
      type,
      gclid: this.gclid(),
      fbclid: this.fbclid(),
      fbp: this.fbp(),
      bannerPosition,
    });
  };

  public viewAllRelationshipEvent = (
    viewAllUrl: string,
    bannerPosition: number,
  ): void => {
    analytics.track(`Valentines day view all clicked`, {
      referrer,
      viewAllUrl,
      gclid: this.gclid(),
      fbclid: this.fbclid(),
      fbp: this.fbp(),
      bannerPosition,
    });
  };

  public ScrollRelationshipEvent = (bannerPosition: number): void => {
    analytics.track(`Valentines day slider scrolled event`, {
      referrer,
      bannerPosition,
      gclid: this.gclid(),
      fbclid: this.fbclid(),
      fbp: this.fbp(),
    });
  };

  public TrackProductRelationshipEvent = (
    bannerPosition: number,
    item: {
      sku: number;
      price: number;
      positionProduct: number;
    },
  ): void => {
    analytics.track(`Valentines day slider products`, {
      referrer,
      bannerPosition,
      gclid: this.gclid(),
      fbclid: this.fbclid(),
      fbp: this.fbp(),
      item,
    });
  };
}

export const segment: SegmentAnalytics = SegmentAnalytics.getInstance();
