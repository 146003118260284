import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/styles';
import { AccountBannerLogin } from 'components/Rewards/RewardSignup/AccountBannerLogin';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  TextField,
  Typography,
} from '@mui/material';
import { HideOnXs } from 'utils/hidden/hide-on-xs';
import { HideUpSm } from 'utils/hidden/hide-up-sm';
import { Image } from 'components/common/Image/Image';
import { Links } from 'enums/common-links';
import { RewardAlert } from 'components/Rewards/RewardSignup/RewardAlert';
import { RewardLoginBreadcrumb } from 'components/Rewards/RewardSignup/RewardLoginBreadcrumb';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { ShowUpXs } from 'utils/hidden/show-up-xs';
import { checkExistingEmailState } from 'components/Payment/BillingInformation/Options/feature/selectors';
import {
  checkIsLockedType,
  checkIsTruthyType,
  selectLogInIsFulfilled,
} from 'components/Authentication/LogIn/feature/selectors';
import { checkIsProcessing } from 'components/SignUp/feature/selectors';
import { dataLayer } from 'hooks/google/data-layer';
import { isNotUndefined } from 'utils/is-not-undefined';
import { logIn } from 'components/Authentication/LogIn/feature/log-in.action';
import { reset } from 'components/Authentication/LogIn/feature/slice';
import {
  rewardsTopBannerDesktop,
  rewardsTopBannerPhone,
  rewardsTopBannerTablet,
} from 'utils/cloudinaryAssets';
import { schema } from 'components/Authentication/LogIn/schema';
import { selectIsLoggedIn } from 'providers/Session/feature/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useOpenResetPasswordDialog } from 'components/Authentication/hooks/control';
import { validate } from 'components/Authentication/LogIn/feature/validate.action';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

type LogInFormValues = { email: string; password: string };

export const RewardsSignUp: FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const processing = useSelector(checkIsProcessing);
  const locked = useSelector(checkIsLockedType);
  const truthy = useSelector(checkIsTruthyType);
  const success = useSelector(selectLogInIsFulfilled);
  const checkExistingEmail = useSelector(checkExistingEmailState);
  const openResetPasswordDialog = useOpenResetPasswordDialog();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const navigate = useNavigate();

  const successPasswordReset = searchParams.get('successChanged') === 'true';
  const [showSuccessPasswordReset] = useState(successPasswordReset);

  if (isLoggedIn) navigate(Links.Rewards);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<LogInFormValues>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: checkExistingEmail,
    },
  });

  useEffect(() => {
    if (truthy) {
      dispatch(logIn(getValues()));
    }
  }, [dispatch, getValues, truthy]);

  useEffect(() => {
    if (success) {
      dataLayer.push({
        event: 'userLogin',
        userLogin: {
          email: getValues('email'),
          path: pathname,
        },
      });
      dispatch(reset());
      navigate(Links.Rewards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (successPasswordReset) {
      searchParams.delete('successChanged');
      setSearchParams(searchParams);
    }
  }, []);

  const onSubmit = handleSubmit((data) => {
    dispatch(validate(data));
  });

  return (
    <Box>
      <Box>
        <RewardLoginBreadcrumb />
        <HideUpSm>
          <HideOnXs>
            <Image
              alt={i18next.t(TK.REWARDS_TOP_BANNER)}
              src={rewardsTopBannerDesktop}
              sx={styles.bannerImg}
            />
          </HideOnXs>
        </HideUpSm>
        <HideOnXs>
          <ShowUpSm>
            <Image
              alt={i18next.t(TK.REWARDS_TOP_BANNER)}
              src={rewardsTopBannerTablet}
              sx={styles.bannerImg}
            />
          </ShowUpSm>
        </HideOnXs>
        <ShowUpXs>
          <Image
            alt={i18next.t(TK.REWARDS_TOP_BANNER)}
            src={rewardsTopBannerPhone}
            sx={styles.bannerImg}
          />
        </ShowUpXs>
      </Box>
      <Box mt={7} sx={styles.mainContainer} fontWeight="500">
        <RewardAlert />
        <Box sx={styles.loginContainer}>
          <Typography variant="h4">
            {i18next.t(TK.REWARD_LOG_IN_FORM_MY_ACCOUNT)}
          </Typography>
          <Typography variant="body1" mt={2}>
            {i18next.t(TK.REWARD_LOG_IN_FORM_EMAIL_MESSAGE)}
          </Typography>
          <Typography variant="body2" mt={2} mb={2}>
            {i18next.t(TK.REWARD_LOG_IN_FORM_REQUIRED_MESSAGE)}
          </Typography>
          <Collapse in={showSuccessPasswordReset}>
            <Box mb={2}>
              <Alert severity="success">
                Thank you! You have successfully reset your password. Please
                sign in below with your new password.
              </Alert>
            </Box>
          </Collapse>
          <form onSubmit={onSubmit}>
            <TextField
              {...register('email')}
              error={isNotUndefined(errors?.email?.message)}
              helperText={
                errors?.email?.message && i18next.t(errors?.email?.message)
              }
              disabled={processing || locked}
              sx={{ mt: 1 }}
              label={i18next.t(TK.REWARD_LOG_IN_FORM_EMAIL_LABEL)}
              type="email"
              placeholder="email@email.com"
              fullWidth
            />
            <TextField
              {...register('password')}
              error={isNotUndefined(errors?.password?.message)}
              helperText={
                errors?.password?.message &&
                i18next.t(errors?.password?.message)
              }
              disabled={processing || locked}
              sx={{ mt: 3 }}
              label={i18next.t(TK.REWARD_LOG_IN_FORM_PASSWORD_LABEL)}
              type="password"
              placeholder={i18next.t(
                TK.REWARD_LOG_IN_FORM_PASSWORD_PLACEHOLDER,
              )}
              fullWidth
            />
            <Button
              onClick={openResetPasswordDialog}
              sx={styles.forgotLink}
              variant="text"
              color="primary"
              fullWidth
            >
              {i18next.t(TK.REWARD_LOG_IN_FORM_RESET_LABEL)}
            </Button>
            <Button
              disabled={processing || locked}
              startIcon={
                processing ? (
                  <CircularProgress size={20} color="inherit" />
                ) : undefined
              }
              sx={styles.signinBtnStyle}
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              data-test="sign-in-btn"
            >
              {i18next.t(
                (processing && TK.REWARD_LOG_IN_FORM_SIGNING_IN) ||
                  (success && TK.REWARD_LOG_IN_FORM_SUCCESS) ||
                  TK.REWARD_LOG_IN_FORM_SIGN_IN,
              )}
            </Button>
          </form>
        </Box>
        <Box sx={styles.signupContainer}>
          {isLoggedIn === false && <AccountBannerLogin />}
        </Box>
      </Box>
    </Box>
  );
};
