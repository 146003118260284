export const CART_ITEM_COUNT_SINGLE = 'heading.cart_item_count_single';
export const CART_ITEM_COUNT_MULTI = 'heading.cart_item_count_multi';
export const RECIPIENT_COUNT_SINGLE = 'heading.recipient_count_single';
export const RECIPIENT_COUNT_MULTI = 'heading.recipient_count_multi';
export const CONTINUE_SHOPPING = 'heading.continue_shopping';
export const RETURN_TO_CART = 'heading.return_to_cart';
export const CHECKOUT_NOW = 'heading.checkout_now';
export const RECIPIENT = 'heading.recipient';
export const RECIPIENT_VIEW_EDIT_BUTTON = 'heading.recipient_view_edit_button';
export const RECIPIENT_VIEW_EDIT_HIDE_BUTTON =
  'heading.recipient_view_edit_hide_button';
export const FREE_DELIVERY_REMAINING = 'heading.free_delivery_remaining';
export const FREE_DELIVERY_COMPLETED = 'heading.free_delivery_completed';
export const FREE_DELIVERY_COMPLETED_MOBILE =
  'heading.free_delivery_completed_mobile';
export const TITLE = 'order_summary.title';
export const TOTAL = 'order_summary.total';
export const CART_TOTAL = 'order_summary.cart_total';
export const RECIPIENT_TOTAL = 'order_summary.recipient_total';
export const SUBTOTAL = 'order_summary.subtotal';
export const MP_SHIPPING_FEE = 'order_summary.marketplace_shipping_fee';
export const STATE_TAX = 'order_summary.state_tax';
export const MARKETPLACE_TAX = 'order_summary.mp_tax';
export const MP_PROCESSING_FEE = 'order_summary.processing_fee';
export const LOCAL_TAX = 'order_summary.local_tax';
export const DELIVERY_POLICY = 'order_summary.delivery_help';
export const SHIPMENT_POLICY = 'order_summary.shipment_help';
export const EDIT_DELIVERY_INFO = 'order_summary.edit_delivery_info';
export const EDIT_SHIPPING_INFO = 'order_summary.edit_shipping_info';
export const EDIT_PICKUP_INFO = 'pickup.edit_pickup_info';
export const EDIT_DELIVERY_TITLE = 'order_summary.edit_delivery_title';
export const DELIVERY_FEE = 'order_summary.delivery_fee';
export const DISTANCE_FEE = 'order_summary.distance_fee';
export const SHIPMENT_CHARGE = 'order_summary.shipment_charge';
export const DISCOUNT_TOTAL = 'order_summary.discount_total';
export const PROMO_TEXT = 'order_summary.promo_text';
export const PROMO_CODE_TEXT = 'order_summary.promo_code_text';
export const ONE_HOUR_DELIVERY = 'order_summary.one_hour_delivery';
export const PROMO_EXPIRES = 'order_summary.promo_expires_text';
export const PROMO_MESSAGE = 'order_summary.promo_message_text';
export const MY_EDIBLE_REWARDS_TITLE = 'order_summary.my_edible_rewards_title';
export const PROMO_TOOLTIP = 'order_summary.promo_tooltip';
export const DELIVERY_TOOLTIP = 'order_summary.delivery_tooltip';
export const MP_DELIVERY_TOOLTIP = 'order_summary.mp_delivery_tooltip';
export const DISTANCE_FEE_TOOLTIP = 'order_summary.distance_fee_tooltip';
export const PROMO = 'order_summary.promo_code';
export const ADDON_IMAGE = 'order_summary.addon_image';
export const UPGRADE_IMAGE = 'order_summary.upgrade_image';
export const COMBINATION_IMAGE = 'order_summary.combination_image';
export const PERSON_ICON = 'order_summary.bundle_image';
export const GREETING_TXT = 'order_summary.greeting_txt';
export const GIFT_TXT = 'order_summary.gift_txt';
export const HELP_GREY_ICON = 'order_summary.help_icon';
export const GREETING_MSG = 'order_summary.msg';
export const UPGRADE_TEXT = 'order_summary.upgrade_text';
export const CYOA_TITLE1 = 'order_summary.cyoa_title1';
export const CYOA_TITLE2 = 'order_summary.cyoa_title2';
export const CYODB_TITLE = 'order_summary.cyodb_title';
export const CYO_6COOKIEBOX_TITLE = 'order_summary.cyo6cookiebox_title';
export const CYO_12COOKIEBOX_TITLE = 'order_summary.cyo12cookiebox_title';
export const CYO_DESSERT_NAME = 'order_summary.cyodessert_name';
export const CYO_DIPPEDFRUIT_NAME = 'order_summary.cyodippedfruit_name';
export const CYO_6COOKIEBOX_NAME = 'order_summary.cyo6cookiebox_name';
export const CYO_12COOKIEBOX_NAME = 'order_summary.cyo12cookiebox_name';
export const CYODB_NAME = 'order_summary.cyodb_name';
export const PACKAGE_TITLE1 = 'order_summary.package_title1';
export const PACKAGE_TITLE2 = 'order_summary.package_title2';
export const CURB_PICKUP = 'order_summary.curbside_pickup';
export const VEHICLE_MODEL = 'pickup.vehicle_model';
export const VEHICLE_COLOR = 'pickup.vehicle_color';
export const NO_INSTRUCTIONS = 'order_summary.no_instructions';
export const DELIVERY_TITLE = 'delivery_information.title';
export const DELIVERY_METHOD = 'delivery_information.method';
export const DELIVERY_DAY = 'delivery_information.day';
export const DELIVERY_INSTRUCTIONS = 'delivery_information.instructions';
export const DELIVERY_INSTRUCTIONS_PLACEHOLDER =
  'delivery_information.placeholder';
export const DELIVERING_TO = 'delivering_information.customer';
export const DELIVERY_TO = 'delivery_information.customer';
export const DELIVERY_FROM = 'delivery_information.store';
export const HAND_DELIVERY = 'delivery_information.hand_delivery';
export const DISCLAIMER_1 = 'disclaimer.one';
export const DISCLAIMER_2 = 'disclaimer.two';
export const HELP_ICON = 'disclaimer.help_icon';
export const COMPLIMENTARY_MESSAGE_TITLE = 'complimentary_message.title';
export const COMPLIMENTARY_GIFT_MESSAGE = 'complimentary_message.gift_title';
export const COMPLIMENTARY_NO_CARD_MESSAGE = 'complimentary_message.no_card';
export const ADD_GIFT_MESSAGE_WARING_TEXT1 =
  'complimentary_message.mesage_warning_text1';
export const ADD_GIFT_MESSAGE_WARING_TEXT2 =
  'complimentary_message.mesage_warning_text2';
export const ADD_GIFT_MESSAGE = 'complimentary_message.giftmeassage';
export const FREE = 'complimentary_message.free';
export const EDIT = 'complimentary_message.edit';
export const REMOVE = 'complimentary_message.remove';
export const MADE_IT_SPECIAL = 'product.made_it_special';
export const MAKE_IT_EXTRA_SPECIAL = 'product.make_it_extra_special';
export const SAVE_EDIT_TEXT = 'complimentary_message.save_edit_text';
export const CANCEL_EDIT_TEXT = 'complimentary_message.cancel_edit_text';
export const GIFT_MESSAGE_LABEL = 'complimentary_message.gift_message_text';

export const START_SHOPPING = 'empty_cart.start_shopping';
export const EMPTY_TITLE = 'empty_cart.title';
export const EMPTY_SUBTITLE = 'empty_cart.subtitle';
export const EMPTY_DESCRIPTION = 'empty_cart.description';

export const PICKUP_TITLE = 'pickup_information.title';
export const PICKUP_METHOD = 'pickup_information.method';
export const PICKUP_DAY = 'pickup_information.day';
export const PICKUP_INSTRUCTIONS = 'pickup_information.instructions';
export const PICKUP_INSTRUCTIONS_LABEL = 'pickup_information.label';
export const PICKUP_INSTRUCTIONS_PLACEHOLDER = 'pickup_information.placeholder';
export const PICKUP_TO = 'pickup_information.customer';
export const PICKUP_FROM = 'pickup_information.store';
export const IN_STORE_PICKUP = 'pickup_information.in_store_pickup';
export const PICKUP_TIME_PASSED_TITLE = 'pickup_information.time_passed_title';
export const PICKUP_TIME_PASSED_DESCRIPTION =
  'pickup_information.time_passed_description';
export const PICKUP_DATE_PASSED_TITLE = 'pickup_information.date_passed_title';
export const PICKUP_DATE_PASSED_DESCRIPTION =
  'pickup_information.date_passed_description';
export const PICKUP_CHANGE_DATE = 'pickup_information.pickup_change_date';
export const PICKUP_CHANGE_TIME = 'pickup_information.pickup_change_time';
export const PICKUP_TO_CONTINUE = 'pickup_information.pickup_to_continue';
export const PICKUP_BELOW_CHANGE_TIME =
  'pickup_information.pickup_below_change_time';

export const SHIPMENT_TITLE = 'shipment_information.title';
export const SHIPMENT_METHOD = 'shipment_information.method';
export const SHIPMENT_DAY = 'shipment_information.day';
export const SHIPMENT_INSTRUCTIONS = 'shipment_information.instructions';
export const SHIPMENT_TO = 'shipment_information.customer';
export const SHIPPER = 'shipment_information.shipper';
export const SHIPMENT_METHOD_NAME = 'shipment_information.method';
export const SHIPMENT_METHOD_GOODY_NAME = 'shipment_information_goody.method';
export const SHIPMENT_METHOD_GOODY_NAME_2 =
  'shipment_information_goody.method_2';
export const SHIPMENT_METHOD_NAME_2 = 'shipment_information.method_2';
export const sale = {
  TITLE: 'customer_service_page.sale_title',
  ITEM_COUNT: 'customer_service_page.sale_item_count',
  QTY: 'customer_service_page.sale_quantity',
  ADDON_QTY: 'customer_service_page.sale_addon_quantity',
  PRICE: 'customer_service_page.sale_price',
  SUMMARY: 'customer_service_page.sale_summary',
  DELIVERY_FEE: 'customer_service_page.sale_delivery_fee',
  SHIPMENT_CHARGE: 'customer_service_page.sale_shipment_charge',
  SERVICE_FEE: 'customer_service_page.sale_service_fee',
  SALES_TAX: 'customer_service_page.sale_sales_tax',
  SUBTOTAL: 'customer_service_page.sale_subtotal',
  TOTAL: 'customer_service_page.sale_total',
};
export const REMOVE_DIALOG_TITLE = 'Remove From Cart';
export const REMOVE_DIALOG_MESSAGE =
  'Removing this product from your cart will also remove any upgrades and add-ons selected for that product.';
export const REMOVE_DIALOG_BACK = 'Go Back';
export const REMOVE_DIALOG_CONFIRM = 'Confirm';
export const REMOVE_BUTTON_TITLE = 'Remove';
export const EDIT_BUTTON_TITLE = 'Edit';
export const EDIT_DIALOG_TITLE = 'make it extra special';
export const EDIT_DIALOG_SUB_TITLE =
  'Choose any of our special upgrades below to enhance your gift.';

export const EDIT_DIALOG_SUB_TITLE_NO_UPGRADES =
  'We’re sorry, add-ons aren’t available for this product. Return to cart to continue.';
export const EDIT_DIALOG_SAVE = 'Save';
export const EDIT_DIALOG_CANCEL = 'Cancel';
export const EDIT_DIALOG_RETURN = 'Return To Cart';
export const ORDER_CONTACTS_CELL_PHONE = 'Pickup Phone Number*';
export const FIRST_NAME_PLACEHOLDER = 'Recipient First Name*';
export const LAST_NAME_PLACEHOLDER = 'Recipient Last Name*';
export const PICKUP_CURBSIDE_MODEL_LABEL = 'Vehicle Model*';
export const PICKUP_CURBSIDE_COLOR_LABEL = 'Vehicle Color*';
export const PICKUP_DATE_LABEL = 'Pickup Date';
export const ONE_HOUR_DELIVERY_POPULAR = 'Popular';
export const ONE_HOUR_DELIVERY_CONTENT_TITLE = 'Last-minute shopping?';
export const ONE_HOUR_DELIVERY_CONTENT =
  "You're in luck! This order qualifies for One-Hour Delivery.";
export const PAYMENT_ONE_HOUR_DELIVERY_CONTENT =
  "You selected one-hour delivery for this order. We'll see you soon!";
export const PAYMENT_ONE_HOUR_DELIVERY_SELECTED = 'One-Hour Delivery Selected';
export const DELIVERY_ON = 'delivery_information.on_day';

export const CURB_SIDE_INFO = 'curb_side_pick_up.information';
export const IN_STORE_PICK_UP_INFO = 'in_store_pick_up.information';
export const SHIPPING_INFORMATION = 'shipping_information.title';
export const SHIPPING_DATE_TITLE = 'shipping_date.title';
export const SHIPPING_WINDOW_DATE_TITLE = 'shipping_window_date.title';
