import { SxProps } from '@mui/material';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { fonts } from 'utils/theme/fonts';

export const itemLink: ThemedSxProps = (theme) => ({
  width: '100%',
  cursor: 'pointer',
  '& > div': {
    mb: 2,
  },
  '& img': {
    border: `1px solid ${theme.palette.divider}`,
    display: 'inline-block',
  },
  '&:hover': {
    textDecoration: 'none',
  },
});

export const promo =
  (color?: string): ThemedSxProps =>
  (theme) => ({
    color: color ?? theme.palette.primary.main,
    pb: 0.5,
    display: 'inline-block',
  });

export const title: SxProps = {
  pb: 0.5,
};

export const size: SxProps = {
  pt: 0.5,
};

export const priceWrapper: SxProps = {
  pb: 1,
};

export const regularPrice: ThemedSxProps = {
  textDecoration: 'line-through',
  pb: 1,
  pr: 1,
  color: (theme) => theme.palette.text.secondary,
};
export const salePrice =
  (color?: string): ThemedSxProps =>
  (theme) => ({
    color: color ?? theme.palette.primary.main,
    pb: 1,
    display: 'inline-block',
  });

export const productImageTagText: SxProps = {
  position: 'absolute',
  top: '0',
  fontWeight: '400',
  fontFamily: fonts.poppins,
  fontSize: '8px',
  lineHeight: '13.12px',
  backgroundColor: '#E10600',
  padding: '0px 6px',
  margin: '10px',
  color: '#ffffff',
};

export const image: ThemedSxProps = ({ breakpoints }) => ({
  width: '180px !important',
  height: '180px !important',

  [breakpoints.down('sm')]: {
    width: '120px !important',
    height: '120px !important',
  },
});
