import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/OrderConfirmation/OrderDetails/styles';
import { Box, Typography } from '@mui/material';
import { HideOnXs } from 'utils/hidden/hide-on-xs';
import { Image } from 'components/common/Image/Image';
import { OrderSubItem } from 'redux/order-confirmation/types/types';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { ShowUpXs } from 'utils/hidden/show-up-xs';
import { addOnUrl } from 'components/CustomerService/OrderTracking/utils/utils';
import { addonArrow } from 'utils/cloudinaryAssets';
import { roundNumberValue } from 'utils/number-calculations/roundTwoDecimals';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  product: OrderSubItem;
}

export const ChildProduct: FC<Props> = ({ product }) => (
  <Box sx={styles.childProduct}>
    <Image className="arrowStyle" src={addonArrow} />
    <Image className="productImg" src={addOnUrl(product.addOnOptionImage)} />
    <Box width="100%" ml={2}>
      <HideOnXs>
        <Box
          className="productDescription"
          justifyContent="space-between"
          flexDirection="row"
          display="flex"
        >
          <PureHtmlTypography
            sx={styles.nameSpace}
            className="fontStyle"
            variant="subtitle2"
          >
            {product.name
              .replace(`- ${product.sizeName}`, '')
              .replace(`: ${product.sizeName}`, '')}
          </PureHtmlTypography>
          <Typography
            variant="subtitle2"
            sx={styles.totalStyle}
            mt={1}
          >{`$${roundNumberValue(
            product.quantity * product.price,
          )}`}</Typography>
        </Box>
      </HideOnXs>
      <ShowUpXs>
        <Box
          className="productDescription"
          justifyContent="space-between"
          flexDirection="row"
          display="flex"
        >
          <PureHtmlTypography
            sx={styles.nameSpace}
            className="fontStyle"
            variant="subtitle2"
          >
            {product.name
              .replace(`- ${product.sizeName}`, '')
              .replace(`: ${product.sizeName}`, '')}
          </PureHtmlTypography>
        </Box>
      </ShowUpXs>
      <HideOnXs>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="nowrap"
          flexDirection="row"
        >
          <Typography sx={styles.addOnSizeStyle} mt={1} variant="body3">
            {product.sizeName}
          </Typography>
          <Typography variant="body2" mt={1} sx={styles.qtyStyle}>
            {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SALE_QUANTITY, {
              '#': product.quantity,
            })}
          </Typography>
          <Typography variant="subtitle2" mt={1} sx={styles.qtyCont}>
            {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SALE_PRICE, {
              Price: roundNumberValue(product.price),
            })}
          </Typography>
        </Box>
      </HideOnXs>
      <ShowUpXs>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="nowrap"
          flexDirection="row"
        >
          <Typography sx={styles.addOnSizeStyle} mt={1} variant="body3">
            {product.sizeName}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography variant="body2" mt={1} sx={styles.qtyStyle}>
            {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SALE_QUANTITY, {
              '#': product.quantity,
            })}
          </Typography>
          <Typography variant="subtitle2" mt={1} sx={styles.qtyCont}>
            {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SALE_PRICE, {
              Price: roundNumberValue(product.price),
            })}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={styles.totalStyle}
            mt={1}
          >{`$${roundNumberValue(
            product.quantity * product.price,
          )}`}</Typography>
        </Box>
      </ShowUpXs>
    </Box>
  </Box>
);
