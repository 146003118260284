import { AxiosError } from 'axios';
import { CardMessageParsedResponse } from 'types/legacy/response.types';
import { CartRecipientContact } from 'api/cart/types/cart-contact.type';
import {
  CouponCode,
  CouponCodeRemove,
} from 'api/cart/types/cart-coupon-code-type';
import {
  DynamicActionType,
  MpQuantityEditParams,
  QuantityEditParams,
} from 'components/Cart/feature/types/cart.interfaces';
import { ResponseError } from 'types/error.types';
import { arrangementApiService } from 'api/arrangements/arrangement.api.service';
import { cartApiService } from 'api/cart/cart.api.service';
import { cartLegacyApiService } from 'api/cart/cart-legacy.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { dynamicEvents } from 'service/dynamic-yield';
import { isUS } from 'utils/country';
import { cartApiService as legacyCartCountService } from 'api/cart.api.service';
import { parseAxiosError } from 'utils/errors';

type ComplimentaryMessageParams = { cartRecipientId: number; message: string };

export const fetchRecipientCart = createAsyncThunk(
  'retrieveRecipientCart/fetch',
  async (
    {
      cartRecipientId,
      userId,
      region,
    }: { cartRecipientId: number; userId?: number; region: number },
    { rejectWithValue },
  ) => {
    try {
      const data = await cartApiService.cartRetrieval(
        cartRecipientId,
        userId || 0,
        region,
      );
      return data;
    } catch (ex) {
      const err = ex as AxiosError;
      return rejectWithValue(parseAxiosError(err));
    }
  },
);

export const editMarketplaceQuantity = createAsyncThunk(
  'marketplace/editQuantity/cart',
  async (itemQuantityEditParams: MpQuantityEditParams, { rejectWithValue }) => {
    try {
      const res = await cartApiService.cartEditMarketplaceQuantity(
        itemQuantityEditParams.mpCartRecipientItemId,
        itemQuantityEditParams.quantity,
        itemQuantityEditParams.userId || 0,
        itemQuantityEditParams.region || -1,
      );
      return res;
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after some time',
          data: { key: 'Update item quantity failed' },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const removeMarketplaceItem = createAsyncThunk(
  'marketplace/removeItem/cart',
  async (itemQuantityEditParams: MpQuantityEditParams, { rejectWithValue }) => {
    try {
      const res = await cartApiService.cartRemoveMarketplaceItem(
        itemQuantityEditParams.mpCartRecipientItemId,
        itemQuantityEditParams.userId || 0,
        itemQuantityEditParams.region || -1,
      );
      return res;
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after some time',
          data: { key: 'Update item quantity failed' },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const editQuantity = createAsyncThunk(
  'editQuantity/cart',
  async (itemQuantityEditParams: QuantityEditParams, { rejectWithValue }) => {
    try {
      let res;
      if (itemQuantityEditParams.isParent && itemQuantityEditParams.quantity) {
        res = await cartApiService.cartEditParentQuantity(
          itemQuantityEditParams.cartRecipientItemId,
          itemQuantityEditParams.quantity,
          itemQuantityEditParams.callCenterMode
            ? itemQuantityEditParams.callCenterMode.id
            : 0,
          itemQuantityEditParams.userId ? itemQuantityEditParams.userId : 0,
          itemQuantityEditParams.region ? itemQuantityEditParams.region : -1,
        );
      }
      if (itemQuantityEditParams.quantity) {
        res = await cartApiService.cartEditChildQuantity(
          itemQuantityEditParams.cartRecipientItemId,
          itemQuantityEditParams.quantity,
          itemQuantityEditParams.callCenterMode
            ? itemQuantityEditParams.callCenterMode.id
            : 0,
          itemQuantityEditParams.userId ? itemQuantityEditParams.userId : 0,
          itemQuantityEditParams.region ? itemQuantityEditParams.region : -1,
        );
      }
      if (res) {
        const itemToAdd = res.cartItem && res.cartItem[0];
        if (itemQuantityEditParams.action === DynamicActionType.add) {
          dynamicEvents.addToCart({
            value: itemToAdd?.price ?? 0,
            catalogNumber: itemToAdd?.catalogNumber,
            sizeName: itemToAdd?.sizeName,
            currency: isUS ? 'USD' : 'CAD',
            quantity: itemToAdd?.quantity ?? 0,
          });
        } else {
          dynamicEvents.removeFromCart({
            value: itemToAdd?.price ?? 0,
            catalogNumber: itemToAdd?.catalogNumber,
            sizeName: itemToAdd?.sizeName,
            currency: isUS ? 'USD' : 'CAD',
            quantity: itemToAdd?.quantity ?? 0,
          });
        }
        return res;
      }
      return {
        recipient: undefined,
        cartItem: undefined,
        cardAddOn: undefined,
        addOn: undefined,
        contact: undefined,
      };
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after some time',
          data: { key: 'Update item quantity failed' },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const removeItem = createAsyncThunk(
  'removeItem/cart',
  async (itemQuantityEditParams: QuantityEditParams, { rejectWithValue }) => {
    try {
      let res;
      if (itemQuantityEditParams.isParent) {
        res = await cartApiService.cartRemove(
          itemQuantityEditParams.cartRecipientItemId,
          itemQuantityEditParams.userId ? itemQuantityEditParams.userId : 0,
          itemQuantityEditParams.region ? itemQuantityEditParams.region : -1,
        );
      }

      res = await cartApiService.cartRemoveChild(
        itemQuantityEditParams.cartRecipientItemId,
        itemQuantityEditParams.userId ? itemQuantityEditParams.userId : 0,
        itemQuantityEditParams.region ? itemQuantityEditParams.region : -1,
      );

      if (res.cartItem) {
        const productRemoved = res.cartItem[0];
        const dynamicRemovedItem = {
          value: productRemoved.price,
          catalogNumber: productRemoved.catalogNumber,
          sizeName: productRemoved.sizeName,
          currency: isUS ? 'USD' : 'CAD',
          quantity: itemQuantityEditParams.quantity ?? 1,
        };
        dynamicEvents.removeFromCart(dynamicRemovedItem);
      }

      return res;
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after some time',
          data: { key: 'Update item quantity failed' },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const removeItemRecipient = createAsyncThunk(
  'removeItemRecipient/cart',
  async (itemQuantityEditParams: QuantityEditParams, { rejectWithValue }) => {
    try {
      return await cartApiService.cartRemoveRecipient(
        itemQuantityEditParams.cartRecipientItemId,
        itemQuantityEditParams.userId ? itemQuantityEditParams.userId : 0,
      );
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after some time',
          data: { key: 'Update recipient remove failed' },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const updateRecipientContact = createAsyncThunk(
  'updateRecipientContact/cart',
  async (recipient: CartRecipientContact, { rejectWithValue }) => {
    try {
      const cart = await cartApiService.cartEditRecipientContact(recipient);
      return cart;
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after some time',
          data: { key: 'Update recipient contact failed' },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const addCouponCode = createAsyncThunk(
  'addCouponCodeNew/cart',
  async (
    {
      crId,
      isCallCenterQAManager,
      orderCount,
      couponCode,
      userSession,
      callCenterMode,
      region,
    }: CouponCode,
    { rejectWithValue },
  ) => {
    try {
      const result = await cartApiService.cartApplyCouponCode({
        crId,
        isCallCenterQAManager,
        orderCount,
        couponCode,
        userSession,
        callCenterMode,
        region,
      });

      dynamicEvents.promocodeEntered(couponCode);

      return result;
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after some time',
          data: { key: 'Apply coupon code failed' },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const getCartCount = createAsyncThunk(
  'cart/count',
  async (
    { cartId, region }: { cartId: number; region: number },
    { rejectWithValue },
  ) => {
    try {
      let count;
      if (window.COUNTRY === 'us') {
        count = await cartApiService.getCartCount(cartId, region);
      } else {
        count = await legacyCartCountService.getCartCount();
      }
      return count;
    } catch (err) {
      return rejectWithValue(parseAxiosError(<AxiosError>err));
    }
  },
);

export const removeCouponCode = createAsyncThunk(
  'addCouponRemove/cart',
  async (
    { crId, couponCode, region }: CouponCodeRemove,
    { rejectWithValue },
  ) => {
    try {
      return await cartApiService.cartRemoveCouponCode({
        crId,
        couponCode,
        region,
      });
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after some time',
          data: { key: 'Apply coupon code failed' },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const updateExpressCheckout = createAsyncThunk(
  'updateExpressCheckout/cart',
  async (
    {
      cartRecipientId,
      isSelected,
      userId,
      region,
    }: {
      cartRecipientId: number;
      isSelected: boolean;
      userId?: number;
      region: number;
    },
    { rejectWithValue },
  ) => {
    try {
      return await cartApiService.cartUpdateExpressCheckout(
        cartRecipientId,
        isSelected,
        userId || 0,
        region,
      );
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after some time',
          data: { key: 'Apply coupon code failed' },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const updateComplimentaryMessage = createAsyncThunk(
  'updateComplimentaryMessage/cart',
  async (
    { cartRecipientId, message }: ComplimentaryMessageParams,
    { rejectWithValue },
  ) => {
    let data: { d: string };
    try {
      const response = await cartLegacyApiService.updateComplimentaryMessage(
        cartRecipientId,
        message,
      );
      data = response.data;
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after some time',
          data: { key: 'Update Complimentary message failed' },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
    return JSON.parse(data.d) as CardMessageParsedResponse;
  },
);

export const fetchArrangementsOneHourDelivery = createAsyncThunk(
  'retrieveArrangementsOneHourDelivery/fetch',
  async () => {
    const data = await arrangementApiService.getArrangementsOneHourDelivery();
    return data;
  },
);
