import * as TK from 'components/PDP/Order/RecipientForm/components/Contacts/translations/constants';

export const orderContactsCommonLocale = {
  [TK.ORDER_CONTACTS_HEADER]: 'Recipient Contact Information',
  [TK.ORDER_CONTACTS_ALERT]:
    'Please provide at least one Recipient phone number, we will use this number in case of delivery complications',
  [TK.ORDER_CONTACTS_ADD_BUTTON]: 'Home And/Or Work Phone',
  [TK.ORDER_CONTACTS_HOME_PHONE]: 'Home Phone',
  [TK.ORDER_CONTACTS_CELL_PHONE]: 'Recipient Phone*',
  [TK.ORDER_CONTACTS_WORK_PHONE]: 'Work Phone',
  [TK.ORDER_CONTACTS_EMAIL_LABEL]: 'Recipient Email',
  [TK.ORDER_DELIVERY_TEXT_MESSAGE_DISCLAIMER]:
    'Message and data rates may apply.',
  [TK.ORDER_URL_DELIVERY_MESSAGE]:
    'After you place your order, we’ll give you a link to send your card to the recipient however you like.',
  [TK.MARKETPLACE_DIGITAL_CARD_DELIVERY_TITLE]:
    'How would you like to send your FREE digital card?',
  [TK.MARKETPLACE_DIGITAL_CARD_DELIVERY_BODY]:
    'Your digital card will include the card above and a summary of the products being sent.',
};
