import * as TK from 'components/ForgotPassword/translations/locale.constants';
import * as styles from 'components/ForgotPassword/styles';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  ChangePasswordStatusEnum,
  ResetPasswordRequest,
} from 'api/account/type/account-request';
import { InputPassword } from 'components/ForgotPassword/Form/InputPassword';
import { Links } from 'enums/common-links';
import { accountApiService } from 'api/account/account.api.service';
import { schema } from 'components/ForgotPassword/Form/schema';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState } from 'react';
import i18next from 'i18next';

type TForgotPasswordForm = {
  newPassword: string;
  confirmPassword: string;
};

export const ForgotPasswordForm: React.VoidFunctionComponent = () => {
  const siteKey = window.RECAPTCHA_SITE_KEY;
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [error, setError] = useState<string>('');
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const resetCode = searchParams.get('key') || '';
  const resetDate = searchParams.get('date') || '';

  const {
    handleSubmit,
    control,
    setError: setFormError,
    formState: { isValid, isSubmitting },
  } = useForm<TForgotPasswordForm>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onCaptchaChange = (value: string | null) => {
    setIsCaptchaValid(Boolean(value));
  };

  const onSubmit = handleSubmit(async ({ newPassword }) => {
    if (isCaptchaValid) {
      setError('');

      const request: ResetPasswordRequest = {
        key: resetCode,
        date: resetDate,
        password: newPassword,
      };
      const response = await accountApiService.resetPassword(request);

      if (response === ChangePasswordStatusEnum.SUCCESS) {
        navigate(`${Links.EdibleRewardsLogin}?successChanged=true`, {
          replace: true,
        });
      } else if (
        response ===
        ChangePasswordStatusEnum.PASSWORD_HISTORY_VALIDATION_FAILURE
      ) {
        setFormError('newPassword', {
          message: i18next.t(TK.INPUT_ERROR_NOT_SAME_PASSWORD),
        });
        setFormError('confirmPassword', {
          message: i18next.t(TK.INPUT_ERROR_NOT_SAME_PASSWORD),
        });
        setError(i18next.t(TK.ERROR_NOT_SAME_PASSWORD));
      } else {
        setError(i18next.t(TK.PASSWORD_FAILED_MSG));
      }
    }
  });

  return (
    <Stack component="form" onSubmit={onSubmit} spacing={3}>
      <Stack spacing={2}>
        <Typography variant="h4">{i18next.t(TK.TITLE)}</Typography>
        <Typography variant="body1" fontSize="16px">
          {i18next.t(TK.DESCRIPTION)}
        </Typography>
        <Typography variant="body2">{i18next.t(TK.REQUIRED_FIELD)}</Typography>
      </Stack>
      <Collapse in={Boolean(error)} style={{ margin: '0px' }}>
        <Alert severity="error" sx={styles.alertError}>
          {error}
        </Alert>
      </Collapse>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputPassword
              name="newPassword"
              control={control}
              fullWidth
              label={i18next.t(TK.NEW_PASSWORD_LABEL)}
              showStrengthMeter
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputPassword
              name="confirmPassword"
              control={control}
              fullWidth
              label={i18next.t(TK.CONFIRM_PASSWORD_LABEL)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <ReCAPTCHA
          sitekey={siteKey}
          data-size="compact"
          onChange={onCaptchaChange}
        />
      </Box>
      <Box>
        <Button
          type="submit"
          disabled={!isValid || isSubmitting || !isCaptchaValid}
          variant="contained"
          size="large"
          color="primary"
          sx={styles.saveBtn}
          startIcon={
            isSubmitting ? (
              <CircularProgress size={20} color="inherit" />
            ) : undefined
          }
        >
          {i18next.t(TK.SAVE_PASSWORD)}
        </Button>
      </Box>
    </Stack>
  );
};
