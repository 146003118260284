import * as ERR from 'components/Error/error.locale.constants';
import * as SOCIAL from 'translations/locales/social.locale.constants';
import * as TRANSLATION_KEYS from 'translations/locales/translation-keys.constant';
import { SignUpCommonLocale } from 'containers/SignupContainer/translations/common.locale';
import { aboutCommonLocale } from 'components/About/translations/common.locale';
import { accountAccessLocale } from 'components/Cart/translations/common.locale';
import { addToCartCommonLocale } from 'components/PDP/Order/AddToCart/translations/add-to-cart.common.locale';
import { addressBookCommonLocale } from 'containers/AddressBook/translations/common.locale';
import { areaCommonLocale } from 'components/AreaController/translations/common.locale';
import { arrangementGroupHeadMetaCommonLocale } from 'components/common/ArrangementGroup/HeadMeta/translations/common.locale';
import { arrangementsGridCommonLocale } from 'components/common/ArrangementsGrid/translations/common.locale';
import { arrangementsListCommonLocale } from 'components/PLP/ArrangementsList/translations/common.locale';
import { authenticationCommonLocale } from 'components/Authentication/translations/common.locale';
import { availabilityCommonLocale } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/translations/common.locale';
import { businessGiftingCommonLocale } from 'components/BusinessGifting/translations/common.locale';
import { callCenterLoginCommonLocale } from 'components/PLP/CallCenter/components/Login/translations/common.locale';
import { callCenterModalsCommonLocale } from 'components/PLP/CallCenter/translations/common.locale';
import { cartCommonLocale } from 'containers/CartContainer/translations/common.locale';
import {
  cartError,
  fullPageError,
  orderConfirmationErrorPage,
  paymentPageError,
  pressRoomPageError,
  productDisplayPageError,
  productListingPageError,
  storeFinderError,
  trackOrderError,
} from 'components/Error/translations/common.locale';
import {
  complimentaryLocale,
  deliveryLocale,
  disclaimerLocale,
  emptyCartLocale,
  headingLocale,
  orderDetailsLocale,
  orderSummaryLocale,
  pickupLocale,
  shipmentLocale,
} from 'components/Cart/Details/translations/common.locale';
import { contactUsAccountAccessLocale } from 'components/AccountAccess/translations/common.locale';
import { contactUsCommonLocale } from 'containers/ContactUsContainer/translations/common.locale';
import { continueButtonCommonLocale } from 'components/PDP/Details/components/ContinueButton/translations/continue-button.common.locale';
import {
  customerServiceAllergySafetyInformationLocale,
  customerServiceCertifiedHappinessLocale,
  customerServiceCommonLocale,
  customerServiceEdibleGuaranteeLocale,
  orderDetailsCommonLocale,
  trackYourOrderCommonLocale,
  trackingStatusCommonLocale,
} from 'components/CustomerService/translations/common.locale';
import { dateSelectionCommonLocale } from 'components/PDP/Details/components/PasControl/components/DateSelection/translations/date-selection.common.locale';
import { deliveryMethodsCommonLocale } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/translations/delivery-methods.common.locale';
import { faqCommonLocale } from 'components/PDP/Details/components/ProductAccordion/translations/common';
import { filterSortCommonLocale } from 'components/PLP/FilterSortMenu/translations/common.locale';
import { footerCommonLocale } from 'components/Footer/translations/common.locale';
import { forgotPasswordCommonLocale } from 'components/ForgotPassword/translations/common.locale';
import { fullPageErrorBoundariesCommonLocale } from 'components/Error/FullPageErrorBoundaries/translations/common.locale';
import { galleryCommonLocale } from 'components/PDP/Details/components/Gallery/translations/common.locale';
import { giftFinderCommonLocale } from 'components/GiftFinder/translations/common.locale';
import { greetingCardsCommonLocale } from 'components/PDP/Order/GreetingsCards/translations/common.locale';
import { headerCommonLocale } from 'components/Header/translations/common.locale';
import { homeHeadMetaCommonLocale } from 'components/Home/HeadMeta/translations/common.locale';
import { homePageCommonLocale } from 'components/Home/translations/common.locale';
import { instructionsCommonLocale } from 'components/PDP/Order/RecipientForm/components/DeliveryPickupInstructions/translations/common.locale';
import {
  legalCaliforniaPrivacyRightsPage,
  legalDeliveryPolicyPage,
  legalPageCommonLocale,
  legalShipmentPolicyPage,
} from 'components/LegalPages/translations/common.locale';
import { limitedTextFieldCommonLocale } from 'components/PDP/Order/common/components/LimitedTextField/translations/common.locale';
import { localSeoPageCommonLocale } from 'components/LocalSeoPage/translations/common.locale';
import { lockAlertCommonLocale } from 'components/Session/Alert/translations/common.locale';
import { loginOrAddressBookCommonLocale } from 'components/PDP/Order/LoginOrAddressBook/translations/common.locale';
import { marketPlaceDetailsCommonLocale } from 'components/PDP/Details/components/Marketplace/components/MarketplaceProductDetails/translations/marketplace-product-details.common.locale';
import { marketPlaceShippingCommonLocale } from 'components/PDP/Details/components/Marketplace/components/MarketplaceProductShipping/translations/marketplace-product-shipping.common.locale';
import { multipleRecipientLockCommonLocale } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/translations/common.locale';
import { notAvailableAlertCommonLocale } from 'components/PDP/Details/components/PasControl/components/DateSelection/components/NotAvailableAlert/translations/common.locale';
import { notificationBannerCommonLocale } from 'components/Home/NotificationBanner/translations/common.locale';
import { orderConfirmDetailsCommonLocale } from 'components/OrderConfirmation/translations/common.locale';
import { orderContactsCommonLocale } from 'components/Cart/Details/components/FulfillmentInformation/Recipient/Contacts/translations/order-contacts.common.locale';
import { ownBoxCommonLocale } from 'components/PDP/Details/components/OwnBox/translations/own-box.common.locale';
import { pasCommonLocale } from 'components/PDP/Details/components/PasControl/translations/common.locale';
import { paymentCommonLocale } from 'containers/PaymentContainer/translations/common.locale';
import { pdpHeadMetaCommonLocale } from 'components/PDP/Arrangement/translations/common.locale';
import { pdpOrderCommonLocale } from 'components/PDP/Order/translations/common.locale';
import { pdpOrderSuggestedAddressCommonLocale } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/translations/common.locale';
import { plpCommonLocale } from 'containers/PLPContainer/translations/common.locale';
import { plpFilterChipsCommonLocale } from 'components/PLP/ArrangementsListFilters/translations/common.locale';
import { pluralize } from 'translations/utils';
import { previewCarouselCommonLocale } from 'components/PDP/Details/components/Gallery/components/BigGallery/components/PreviewCarousel/translations/common.locale';
import { productCodeCommonLocale } from 'components/PDP/Details/components/ProductCode/translations/product-code.common.locale';
import { productOptionsCommonLocale } from 'components/PDP/Details/components/ProductOptions/translations/product-options.locale.common';
import { recipientLockCommonLocale } from 'components/Session/Alert/RecipientLockAlert/translations/common.locale';
import { reviewsCommonLocale } from 'components/PDP/Details/components/Reviews/translations/reviews.locale.common';
import { rewardCommonLocale } from 'components/Rewards/translations/common.locale';
import { rewardsCommonLocale } from 'containers/RewardsContainer/translations/common.locale';
import { sameDayDeliveryCommonLocale } from 'components/Home/SameDayDeliveryCutOffBanner/translations/common.locale';
import { saveEditCommonLocale } from 'components/Cart/Details/components/FulfillmentInformation/Recipient/SaveEdit/translations/save-edit.common.locale';
import { showMoreCommonLocale } from 'components/PDP/Upsells/components/Upsell/ShowMore/translations/common.locale';
import { sortSelectCommonLocale } from 'components/PLP/GI/FilterSortMenu/translations/common.locale';
import { stickyAlertCommonLocale } from 'components/common/StickyAlert/translations/locale.common';
import { storeComingSoonDetailsCommonLocale } from 'components/StoreComingSoon/translations/common.locale';
import { storeDetailsCommonLocale } from 'components/StoreDetails/translations/common.locale';
import { storeFinderCommonLocale } from 'components/StoreFinder/translations/locale.common';
import { storeListCommonLocale } from 'components/PDP/Details/components/PasControl/components/StoreSelector/translations/locale.common';
import { storeLockCommonLocale } from 'components/Session/Alert/StoreLockAlert/translations/common.locale';
import { storeWidgetCommonLocale } from 'components/common/StoreWidget/translations/locale.common';
import { subscriptionCommonLocale } from 'components/Subscription/translations/common.locale';
import { suggestedDatesCommonLocale } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates/translations/suggested-dates.common.locale';
import { suggestedProductsCommonLocale } from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/translations/locale.common';
import { unavailableByScheduleCommonLocale } from 'components/PDP/Arrangement/UnavailableBySchedule/translations/common.locale';
import { upsellsCommonLocale } from 'components/PDP/Upsells/translations/common.locale';
import { validationAlertCommonLocale } from 'components/PDP/Order/ValidationAlert/translations/common.locale';
import { viewAvailableProductsAlertCommonLocale } from 'components/PDP/Details/components/ProductOptions/components/ViewAvailableProductsAlert/translations/common.locale';

export const common = {
  [TRANSLATION_KEYS.SUBMITTING]: 'Submitting',
  [TRANSLATION_KEYS.LOG_IN]: 'Log in',
  [TRANSLATION_KEYS.OR]: 'or',
  [TRANSLATION_KEYS.SUBMIT]: 'Submit',
  [TRANSLATION_KEYS.EMAIL]: 'Email',
  [TRANSLATION_KEYS.RESULT]: 'Result',
  [TRANSLATION_KEYS.NO_RESULTS]: 'No results',
  [TRANSLATION_KEYS.LOADING]: 'Loading',
  [TRANSLATION_KEYS.PICKUP_FORM_ERROR_PHONE]: 'Enter a valid phone number',
  [pluralize(TRANSLATION_KEYS.RESULT)]: 'Results',
  [TRANSLATION_KEYS.STORE_TIMINGS]:
    'Store hours for {{selectedDate}} are {{openingTime}} to {{closingTime}}',
  [TRANSLATION_KEYS.SUGGESTED_CALENDAR_TOP]:
    "We're sorry, but this product is not available for {{deliveryType}} on the date you selected. Please select another date or ",
  [TRANSLATION_KEYS.SUGGESTED_CALENDAR_LINK]: 'Click',
  [TRANSLATION_KEYS.SUGGESTED_CALENDAR_BOTTOM]:
    ' this option to see other products available for your selected date',
  [TRANSLATION_KEYS.STORE_IS_NOT_FOUND_FOR_ZIP]: 'Store is not found for zip',
  [TRANSLATION_KEYS.STORE_IS_NOT_AVAILABLE_FOR_DATE]:
    'Store is not available for date',
  [TRANSLATION_KEYS.STORE_IS_NOT_FOUND_FOR_ARRANGEMENT_FOR_ZIP]:
    "Oops, we're sorry, this product is no longer available. Please select a different product.",
  [TRANSLATION_KEYS.SELECT_ADDRESS_TYPE]: 'Select address type',
  [TRANSLATION_KEYS.ENTER_BUSINESS_NAME]: 'Enter business name',
  [TRANSLATION_KEYS.ENTER_SCHOOL_NAME]: 'Enter school name',
  [TRANSLATION_KEYS.ENTER_HOSPITAL_NAME]: 'Enter hospital name',
  [TRANSLATION_KEYS.PAS_NETWORK_ERR]:
    'Please Check Your Network Connection and Try Again',
  [TRANSLATION_KEYS.RECIPIENT_INFORMATION_ERROR_PICKUP_TIME]:
    'Select Pickup Time',
  [TRANSLATION_KEYS.ERROR]: 'Error',
  [TRANSLATION_KEYS.LOGO]: 'Logo',
  [TRANSLATION_KEYS.MAIN_PAGE]: 'Main page',
  [TRANSLATION_KEYS.SLIDER_NEXT]: 'Next',
  [TRANSLATION_KEYS.SLIDER_PREVIOUS]: 'Previous',
  [TRANSLATION_KEYS.BACK]: 'Back',
  [ERR.ERROR_404_OOPS]: 'oops-a-pineapple-daisy',
  [ERR.ERROR_404_TEXT_1]: `It looks like the page you requested could not be accessed.
The link may not be valid.`,
  [ERR.ERROR_404_TEXT_2]: 'Please visit our homepage to continue browsing.',
  [ERR.ERROR_404_TITLE]: 'Edible Arrangements® Page Not Found',
  [ERR.ERROR_404_COPYRIGHT]:
    'Edible Arrangements®, the Fruit Basket Logo, and other marks mentioned herein are registered trademarks of Edible Arrangements,LLC.',
  [ERR.ERROR_404_RIGHTS_RESERVED]:
    '© 2009-{{year}} Edible Arrangements, LLC. All rights reserved.',
  [ERR.ERROR_500_TITLE]:
    'Edible Arrangements® Fresh Fruit Baskets, Gift Bouquets & Chocolate Covered Strawberries!',
  [ERR.ERROR_500_HEADING1]: "We're sorry, our site is super busy right now!",
  [ERR.ERROR_500_HEADING2]: 'Please check back soon',
  [ERR.ERROR_500_NEED_CALL]: 'Need something now? Call your local store',
  [ERR.ERROR_500_ZIP_CODE]: 'Zip code',
  [ERR.ERROR_500_CURRENT_LOCATION]: 'Current Location',
  [ERR.ERROR_500_FIND_STORE]: 'Find a store',
  [ERR.ERROR_500_YOUR_EMAIL_SUBMITTED]:
    'Your email has been submitted successfully!',
  [ERR.ERROR_500_TURN_GEOLOCATION]:
    'Please turn on your GPS locator so that we can find your location and closest store.',
  [ERR.ERROR_500_WE_CAN_EMAIL]: 'Or we can email you when we are back up',
  [ERR.ERROR_500_CALL_CUSTOM_CENTER]: 'Or Call our Customer Care Center at',
  [SOCIAL.SOCIAL_FOLLOW_INSTAGRAM]: 'Follow us on Instagram',
  [SOCIAL.SOCIAL_FOLLOW_FACEBOOK]: 'Follow us on Facebook',
  [SOCIAL.SOCIAL_FOLLOW_TWITTER]: 'Follow us on Twitter',
  [SOCIAL.SOCIAL_FOLLOW_PINTEREST]: 'Follow us on Pinterest',
  [SOCIAL.SOCIAL_FOLLOW_YOUTUBE]: 'Subscribe to YouTube channel',
  [TRANSLATION_KEYS.ERROR_SPL_CHARS_BUSINESS_NAME]:
    'Enter a valid business name',
  [TRANSLATION_KEYS.ERROR_SPL_CHARS_HOSPITAL_NAME]:
    'Enter a valid hospital name',
  [TRANSLATION_KEYS.ERROR_SPL_CHARS_SCHOOL_NAME]: 'Enter a valid school name',
  [TRANSLATION_KEYS.BLOG_CAROUSEL_TITLE]: 'Explore Latest Posts',
  [TRANSLATION_KEYS.PRODUCT_FAQ]: 'faqs',
  [TRANSLATION_KEYS.MARKETPLACE_ZIP_CODE_LABEL]: 'Zip code*',
  [TRANSLATION_KEYS.MARKETPLACE_STATE_LABEL]: 'State*',
  [TRANSLATION_KEYS.MARKETPLACE_RECIPIENT_STATE_OTHER]: 'Other',
  [TRANSLATION_KEYS.MARKETPLACE_STATE_SELECT]: 'Select',
  [TRANSLATION_KEYS.MARKETPLACE_UNAVAILABLE_ALERT_TITLE]:
    'Product is unavailable',
  [TRANSLATION_KEYS.MARKETPLACE_UNAVAILABLE_ALERT_BODY]:
    'This product can’t be delivered to this address, but others can be! Click below to shop available products.',
  [TRANSLATION_KEYS.MARKETPLACE_UNAVAILABLE_ALERT_LINK]:
    'Shop Available Products',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_TITLE]:
    'Is this a gift? Include a FREE digital card.',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_CHECKBOX_LABEL]:
    'Send notifications',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_CHECKBOX_LABEL_DESCRIPTION]:
    'Let recipients know a gift is on the way and send them a tracking number.',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_TOOLTIP]:
    'If you enable this, you’ll be given the option to send a free digital greeting card, and we’ll send notifications to your recipients using the delivery method you specify. If you disable this, we won’t send any notifications or reminders, and we’ll provide you with a gift link for you to send to your recipients manually.',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_FROM_LABEL]: 'From*',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_FROM_HELPER]:
    'Let the recipient know who sent their gift.',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_MESSAGE_LABEL]: 'Message*',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_MESSAGE_PLACEHOLDER]:
    'Write your gift message here.',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_IMAGE_PLACEHOLDER]:
    'Pick your card*',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_SELECTION_BUTTON]: 'Select Card',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_MESSAGE_ERROR]:
    'Write a gift message',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_EDIT_BUTTON]: 'Edit',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_DELETE_BUTTON]: 'Delete',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_MODAL_TITLE]: 'choose your card',
  [TRANSLATION_KEYS.MARKETPLACE_DIGITAL_CARD_MODAL_BODY]:
    'Find your favorite card from our list below.',
  [TRANSLATION_KEYS.MARKETPLACE_CART_DIGITAL_CARD]: 'Digital Greeting Card',
  [TRANSLATION_KEYS.MARKETPLACE_CART_DIGITAL_CARD_DESC]:
    'Your digital greeting card includes your selected design and your personal gift message. It will be delivered to the recipient via the delivery method specified below.',
  [TRANSLATION_KEYS.MARKETPLACE_CART_DIGITAL_CARD_FREE]: 'FREE',
  [TRANSLATION_KEYS.MARKETPLACE_CART_DIGITAL_CARD_NOTIFICATIONS_ENABLED]:
    'Notifications Enabled',
  [TRANSLATION_KEYS.MARKETPLACE_CART_DIGITAL_CARD_EMAIL_DELIVERY]:
    'Email Delivery',
  [TRANSLATION_KEYS.MARKETPLACE_CART_DIGITAL_CARD_TEXT_DELIVERY]:
    'Text Message Delivery',
  [TRANSLATION_KEYS.MARKETPLACE_CART_DIGITAL_CARD_LINK_DELIVERY]:
    'URL Delivery',
  [TRANSLATION_KEYS.MARKETPLACE_CART_DIGITAL_CARD_ADD]:
    'Add Digital Greeting Card',
  [TRANSLATION_KEYS.MARKETPLACE_CART_ORDER_PART_TITLE]:
    'Order - Part {{orderPart}}',
  [TRANSLATION_KEYS.MARKETPLACE_CART_SHIPPING]: 'Shipping:',
  [TRANSLATION_KEYS.MARKETPLACE_CART_SHIPPING_TIME]: '5-7 days',
  [TRANSLATION_KEYS.MARKETPLACE_CART_FREE_SHIPMENT_REMAINING]:
    '{{amount}} away from FREE shipping from {{brandName}}!',
  [TRANSLATION_KEYS.MARKETPLACE_CART_FREE_SHIPMENT_COMPLETED]:
    'Congrats! You get FREE shipping from {{brandName}}!',
  ...aboutCommonLocale,
  ...accountAccessLocale,
  ...contactUsAccountAccessLocale,
  ...addToCartCommonLocale,
  ...addressBookCommonLocale,
  ...areaCommonLocale,
  ...arrangementGroupHeadMetaCommonLocale,
  ...arrangementsGridCommonLocale,
  ...arrangementsListCommonLocale,
  ...authenticationCommonLocale,
  ...rewardCommonLocale,
  ...forgotPasswordCommonLocale,
  ...availabilityCommonLocale,
  ...businessGiftingCommonLocale,
  ...cartError,
  ...callCenterLoginCommonLocale,
  ...callCenterModalsCommonLocale,
  ...complimentaryLocale,
  ...contactUsCommonLocale,
  ...SignUpCommonLocale,
  ...continueButtonCommonLocale,
  ...dateSelectionCommonLocale,
  ...deliveryMethodsCommonLocale,
  ...deliveryLocale,
  ...disclaimerLocale,
  ...rewardsCommonLocale,
  ...emptyCartLocale,
  ...faqCommonLocale,
  ...filterSortCommonLocale,
  ...footerCommonLocale,
  ...legalPageCommonLocale,
  ...legalCaliforniaPrivacyRightsPage,
  ...legalDeliveryPolicyPage,
  ...legalShipmentPolicyPage,
  ...localSeoPageCommonLocale,
  ...storeWidgetCommonLocale,
  ...storeFinderCommonLocale,
  ...fullPageErrorBoundariesCommonLocale,
  ...fullPageError,
  ...galleryCommonLocale,
  ...greetingCardsCommonLocale,
  ...giftFinderCommonLocale,
  ...headerCommonLocale,
  ...headingLocale,
  ...homeHeadMetaCommonLocale,
  ...homePageCommonLocale,
  ...instructionsCommonLocale,
  ...limitedTextFieldCommonLocale,
  ...lockAlertCommonLocale,
  ...loginOrAddressBookCommonLocale,
  ...multipleRecipientLockCommonLocale,
  ...notAvailableAlertCommonLocale,
  ...orderSummaryLocale,
  ...orderDetailsLocale,
  ...orderConfirmationErrorPage,
  ...orderContactsCommonLocale,
  ...ownBoxCommonLocale,
  ...pasCommonLocale,
  ...paymentPageError,
  ...pdpHeadMetaCommonLocale,
  ...pdpOrderCommonLocale,
  ...pdpOrderSuggestedAddressCommonLocale,
  ...plpCommonLocale,
  ...plpFilterChipsCommonLocale,
  ...pickupLocale,
  ...previewCarouselCommonLocale,
  ...productDisplayPageError,
  ...productListingPageError,
  ...productCodeCommonLocale,
  ...productOptionsCommonLocale,
  ...recipientLockCommonLocale,
  ...reviewsCommonLocale,
  ...sameDayDeliveryCommonLocale,
  ...showMoreCommonLocale,
  ...sortSelectCommonLocale,
  ...shipmentLocale,
  ...saveEditCommonLocale,
  ...stickyAlertCommonLocale,
  ...storeDetailsCommonLocale,
  ...storeComingSoonDetailsCommonLocale,
  ...storeListCommonLocale,
  ...storeFinderError,
  ...storeLockCommonLocale,
  ...subscriptionCommonLocale,
  ...suggestedDatesCommonLocale,
  ...suggestedProductsCommonLocale,
  ...unavailableByScheduleCommonLocale,
  ...upsellsCommonLocale,
  ...validationAlertCommonLocale,
  ...viewAvailableProductsAlertCommonLocale,
  ...cartCommonLocale,
  ...customerServiceCommonLocale,
  ...trackYourOrderCommonLocale,
  ...trackOrderError,
  ...trackingStatusCommonLocale,
  ...orderDetailsCommonLocale,
  ...orderConfirmDetailsCommonLocale,
  ...customerServiceAllergySafetyInformationLocale,
  ...customerServiceCertifiedHappinessLocale,
  ...customerServiceEdibleGuaranteeLocale,
  ...notificationBannerCommonLocale,
  ...paymentCommonLocale,
  ...pressRoomPageError,
  ...marketPlaceDetailsCommonLocale,
  ...marketPlaceShippingCommonLocale,
};
