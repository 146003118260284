import * as TK from 'translations/locales/translation-keys.constant';
import * as select from 'redux/order-confirmation/selectors';
import * as styles from 'components/OrderConfirmation/OrderDetails/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from '@mui/material';
import { Delivery } from 'components/OrderConfirmation/OrderType/Delivery';
import { HelpGreyIcon, TrackOrderImg } from 'utils/cloudinaryAssets';
import { HideUpMd } from 'utils/hidden/hide-up-md';
import { HideUpSm } from 'utils/hidden/hide-up-sm';
import { Image } from 'components/common/Image/Image';
import { Order, OrderType } from 'redux/order-confirmation/types/types';
import { OrderSummary } from 'components/OrderConfirmation/OrderSummary/OrderSummary';
import { Pickup } from 'components/OrderConfirmation/OrderType/PickUp';
import { Product } from 'components/OrderConfirmation/OrderDetails/Product';
import { Shipment } from 'components/OrderConfirmation/OrderType/Shipment';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { ShowUpXs } from 'utils/hidden/show-up-xs';
import {
  checkIsOrderConfirmation,
  selectFilterOrderItemData,
  selectFilterOrderSubItemData,
  selectOrderData,
} from 'redux/order-confirmation/selectors';
import { roundNumberValue } from 'utils/number-calculations/roundTwoDecimals';
import { useGTagOrderConfirmationEvent } from 'components/OrderConfirmation/hooks/use-gtag-order-confirmation-event';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FC, useState } from 'react';
import i18next from 'i18next';

interface Props {
  order: Order;
  recipientNumber: number;
}

export const OrderDetails: FC<Props> = ({ order, recipientNumber }) => {
  useGTagOrderConfirmationEvent(order);
  const orderData = useSelector(selectOrderData);
  const products = useSelector((state) =>
    selectFilterOrderItemData(state, order.orderID),
  );
  const childProducts = useSelector((state) =>
    selectFilterOrderSubItemData(state, order.orderID),
  );
  const loading = useSelector(checkIsOrderConfirmation);
  const checkPrintableMessage = childProducts?.filter(
    (prod) => prod.catalogCode === 'Printible',
  );
  const [expanded, setExpanded] = useState<boolean>(true);
  const totalProductRecipient = useSelector((state) =>
    select.selectTotalProductCountForRecipient(state, order.orderID),
  );

  const handleChange = () => () => {
    setExpanded(!expanded);
  };
  return (
    <>
      {!loading && (
        <Box mt={10} key={order.orderID} sx={styles.wrapper}>
          <Box sx={styles.orderDetails}>
            <Box sx={styles.orderNumCont}>
              <Box sx={styles.orderNumberStyle}>
                <Typography sx={styles.orderNumberTxtStyle}>
                  {i18next.t(TK.ORDER_CONFIRMATION_PAGE_ORDER_NUMBER)}
                </Typography>
                <Typography sx={styles.orderNumberTxtStyle}>
                  {order.number}
                </Typography>
              </Box>
              <Box display="flex">
                <Image
                  src={TrackOrderImg}
                  alt={i18next.t(TK.ORDER_CONFIRMATION_PAGE_TRACK_ORDER_IMG)}
                  width={22}
                  height={22}
                  style={{ margin: '-5px 5px 0 0' }}
                />
                <Link
                  sx={styles.trackOrderTxt}
                  href={`/customer-service/track-your-order/?MPON=${order.number}`}
                >
                  {i18next.t(TK.ORDER_CONFIRMATION_PAGE_TRACK_ORDER)}
                </Link>
              </Box>
            </Box>
            <Accordion
              expanded={expanded}
              onChange={handleChange()}
              sx={styles.noSpace}
              className="test"
              style={{ position: 'inherit' }}
            >
              <AccordionSummary
                sx={styles.accordianText}
                expandIcon={<ExpandMoreIcon />}
                className="test2"
              >
                <Box width="100%">
                  <Typography sx={styles.multiRecipientCount}>
                    {orderData && orderData?.length > 1
                      ? `${i18next.t(TK.ORDER_CONFIRMATION_PAGE_RECIPIENT, {
                          RecipientNumber: recipientNumber,
                        })}`
                      : ''}
                  </Typography>
                  <Typography variant="subtitle1" mb={2} sx={styles.itemTxt}>
                    {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SALE_ITEM_COUNT, {
                      Number: totalProductRecipient,
                    })}
                    {totalProductRecipient !== 1 && 's'}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={styles.noSpace}>
                <Box
                  display="contents"
                  justifyContent="space-between"
                  flexDirection={['column', 'row']}
                  className="productContainer"
                >
                  <hr />
                  <HideUpMd>
                    <ShowUpSm>
                      {order.orderType === OrderType.Delivery && (
                        <Delivery order={order} />
                      )}
                      {order.orderType === OrderType.Pickup && (
                        <Pickup order={order} />
                      )}
                      {order.orderType === OrderType.Shipment && (
                        <Shipment order={order} />
                      )}
                      <hr />
                    </ShowUpSm>
                  </HideUpMd>
                  <HideUpSm>
                    <ShowUpXs>
                      {order.orderType === OrderType.Delivery && (
                        <Delivery order={order} />
                      )}
                      {order.orderType === OrderType.Pickup && (
                        <Pickup order={order} />
                      )}
                      {order.orderType === OrderType.Shipment && (
                        <Shipment order={order} />
                      )}
                      <hr />
                    </ShowUpXs>
                  </HideUpSm>
                  <Box display="flex" sx={styles.disclaimerTextContainer}>
                    <Box width={16} height={16}>
                      <Image
                        src={HelpGreyIcon}
                        alt={i18next.t(
                          TK.ORDER_CONFIRMATION_PAGE_HELP_GREY_ICON,
                        )}
                        width={14}
                        height={14}
                      />
                    </Box>
                    <Typography sx={styles.disclaimerText}>
                      {i18next.t(
                        TK.ORDER_CONFIRMATION_PAGE_DISCLAIMER_TEXT_LINE1,
                      )}
                      <br />
                      {i18next.t(
                        TK.ORDER_CONFIRMATION_PAGE_DISCLAIMER_TEXT_LINE2,
                      )}
                    </Typography>
                  </Box>
                  <hr />
                  <Box width="100%">
                    {products?.map((product) => (
                      <Product
                        key={product.orderItemID}
                        product={product}
                        childProducts={childProducts}
                      />
                    ))}
                  </Box>
                  {childProducts?.map(
                    (childProduct) =>
                      childProduct.productID ===
                        window.PRINTABLE_ADD_ON_PRODUCT_ID && (
                        <Box
                          mt={3}
                          mb={3}
                          key={childProduct.id}
                          display="inline-flex"
                          width="100%"
                        >
                          <Image
                            width={120}
                            height={186}
                            alt={childProduct.addOnAltText}
                            src={childProduct.image}
                            className="giftImg"
                          />
                          <Box ml={1.5} sx={styles.giftCard}>
                            <Typography sx={styles.giftCardTitle}>
                              {i18next.t(
                                TK.ORDER_CONFIRMATION_PAGE_GREETING_TXT,
                              )}
                            </Typography>
                            <Typography sx={styles.giftCardMsg}>
                              {i18next.t(TK.ORDER_CONFIRMATION_PAGE_GIFT_MSG)}
                            </Typography>
                            <Box
                              width="100%"
                              display="flex"
                              sx={styles.greetingStyle}
                            >
                              <Box width={16} height={16}>
                                <Image
                                  src={HelpGreyIcon}
                                  alt={i18next.t(
                                    TK.ORDER_CONFIRMATION_PAGE_HELP_GREY_ICON,
                                  )}
                                  width={14}
                                  height={14}
                                />
                              </Box>
                              <Box width="100%">
                                <Typography
                                  sx={styles.disclaimerText}
                                  className="greeting_txt"
                                >
                                  {i18next.t(
                                    TK.ORDER_CONFIRMATION_PAGE_GREETING_MSG,
                                  )}
                                </Typography>
                              </Box>
                              <Typography
                                sx={styles.giftCardPrice}
                              >{`$${roundNumberValue(
                                childProduct.total,
                              )}`}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      ),
                  )}
                  {checkPrintableMessage?.length === 0 && (
                    <Box
                      mt={3}
                      mb={3}
                      display="inline-flex"
                      sx={styles.giftCardWidth}
                    >
                      <Box sx={styles.giftCardWidth}>
                        <Typography sx={styles.giftCardTitle}>
                          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_GIFT_MSG)}
                        </Typography>
                        {order.cardMessage !== 'NO CARD MESSAGE' && (
                          <>
                            <ShowUpMd>
                              <Typography sx={styles.giftCardFreeTxt}>
                                {i18next.t(TK.ORDER_CONFIRMATION_PAGE_FREE_TXT)}
                              </Typography>
                            </ShowUpMd>
                            <HideUpMd>
                              <ShowUpSm>
                                <Typography sx={styles.giftCardFreeTxt}>
                                  {i18next.t(
                                    TK.ORDER_CONFIRMATION_PAGE_FREE_TXT,
                                  )}
                                </Typography>
                              </ShowUpSm>
                            </HideUpMd>
                            <Typography sx={styles.trackOrderTxt}>
                              Gift Message:
                            </Typography>
                            <Typography sx={styles.giftCardTxt}>
                              <pre>{order.cardMessage}</pre>
                            </Typography>
                          </>
                        )}
                        {order.cardMessage === 'NO CARD MESSAGE' && (
                          <>
                            <Typography sx={styles.giftCardTxt}>
                              {i18next.t(TK.ORDER_CONFIRMATION_NO_MESSAGE_TEXT)}
                            </Typography>
                          </>
                        )}
                        <HideUpSm>
                          <ShowUpXs>
                            <Typography sx={styles.giftCardFreeTxt}>
                              {i18next.t(TK.ORDER_CONFIRMATION_PAGE_FREE_TXT)}
                            </Typography>
                          </ShowUpXs>
                        </HideUpSm>
                      </Box>
                    </Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
            <hr />
            <Box sx={styles.orderCont}>
              <OrderSummary order={order} />
            </Box>
          </Box>
          <ShowUpMd>
            {order.orderType === OrderType.Delivery && (
              <Delivery order={order} />
            )}
            {order.orderType === OrderType.Pickup && <Pickup order={order} />}
            {order.orderType === OrderType.Shipment && (
              <Shipment order={order} />
            )}
          </ShowUpMd>
        </Box>
      )}
    </>
  );
};
