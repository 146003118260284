import { Cart } from 'api/cart/types/cart-retrieval.type';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import {
  addCouponCode,
  editMarketplaceQuantity,
  editQuantity,
  fetchArrangementsOneHourDelivery,
  fetchRecipientCart,
  getCartCount,
  removeCouponCode,
  removeItem,
  removeItemRecipient,
  removeMarketplaceItem,
  updateComplimentaryMessage,
  updateExpressCheckout,
  updateRecipientContact,
} from 'components/Cart/feature/actions';
import { createSlice } from '@reduxjs/toolkit';
import { updateAddOnsUpgrades } from 'components/Cart/Details/components/Upsells/feature/actions';

export interface RecipientCartState {
  recipientCart: Cart;
  count: number;
  status: Status;
  cardMessage: string;
  retrieveCart: boolean;
  retrieveRecipient: boolean;
  cartCountStatus: Status;
  addCouponStatus: Status;
  cardMessageStatus: Status;
  removeCouponStatus: Status;
  editQuantityStatus: Status;
  error: ResponseError | null;
  oneHourDeliveryStatus: Status;
  arrangementsOneHourDelivery: number[];
  couponOptions: {
    strErrorMessage: string;
    crId: number;
    showCoupons: boolean;
  };
  editMarketplaceQuantityStatus: Status;
}

const initialState: RecipientCartState = {
  recipientCart: {
    recipient: undefined,
    cartItem: undefined,
    cardAddOn: undefined,
    addOn: undefined,
    contact: undefined,
    marketplaceCart: undefined,
  },
  count: 0,
  error: null,
  cardMessage: '',
  retrieveCart: true,
  retrieveRecipient: false,
  status: Status.INIT,
  addCouponStatus: Status.INIT,
  cartCountStatus: Status.INIT,
  cardMessageStatus: Status.INIT,
  arrangementsOneHourDelivery: [],
  removeCouponStatus: Status.INIT,
  editQuantityStatus: Status.INIT,
  oneHourDeliveryStatus: Status.INIT,
  couponOptions: { strErrorMessage: '', crId: 0, showCoupons: true },
  editMarketplaceQuantityStatus: Status.INIT,
};

const slice = createSlice({
  name: 'recipientCart',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecipientCart.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchRecipientCart.fulfilled, (state, { payload }) => {
        state.recipientCart = payload;
        state.status = Status.FULFILLED;
        state.retrieveCart = false;
      })
      .addCase(fetchRecipientCart.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.status = Status.ERROR;
      })
      .addCase(editQuantity.pending, (state) => {
        state.editQuantityStatus = Status.PENDING;
      })
      .addCase(editQuantity.fulfilled, (state, { payload }) => {
        if (
          !payload.recipient &&
          !payload.contact &&
          !payload.cartItem &&
          !payload.cardAddOn &&
          !payload.addOn
        ) {
          state.editQuantityStatus = Status.ERROR;
        } else {
          state.recipientCart = payload;
          state.editQuantityStatus = Status.FULFILLED;
          state.retrieveCart = false;
        }
      })
      .addCase(editQuantity.rejected, (state) => {
        state.editQuantityStatus = Status.ERROR;
      })
      .addCase(removeItem.pending, (state) => {
        state.retrieveRecipient = true;
        state.editQuantityStatus = Status.PENDING;
      })
      .addCase(removeItem.fulfilled, (state) => {
        state.retrieveCart = true;
        state.retrieveRecipient = false;
        state.editQuantityStatus = Status.FULFILLED;
        window.scrollTo(0, 0);
      })
      .addCase(removeItem.rejected, (state) => {
        state.retrieveRecipient = false;
        state.editQuantityStatus = Status.ERROR;
      })
      .addCase(removeItemRecipient.pending, (state) => {
        state.editQuantityStatus = Status.PENDING;
      })
      .addCase(removeItemRecipient.fulfilled, (state) => {
        state.editQuantityStatus = Status.FULFILLED;
      })
      .addCase(removeItemRecipient.rejected, (state) => {
        state.editQuantityStatus = Status.ERROR;
      })
      .addCase(updateRecipientContact.pending, (state) => {
        state.editQuantityStatus = Status.PENDING;
      })
      .addCase(updateRecipientContact.fulfilled, (state, { payload }) => {
        state.recipientCart = payload;
        state.editQuantityStatus = Status.FULFILLED;
        state.retrieveCart = false;
      })
      .addCase(updateRecipientContact.rejected, (state) => {
        state.editQuantityStatus = Status.ERROR;
      })
      .addCase(updateAddOnsUpgrades.pending, (state) => {
        state.editQuantityStatus = Status.PENDING;
      })
      .addCase(updateAddOnsUpgrades.fulfilled, (state) => {
        state.editQuantityStatus = Status.FULFILLED;
        state.retrieveCart = true;
      })
      .addCase(updateAddOnsUpgrades.rejected, (state) => {
        state.editQuantityStatus = Status.ERROR;
      })
      .addCase(updateComplimentaryMessage.pending, (state) => {
        state.cardMessageStatus = Status.PENDING;
      })
      .addCase(updateComplimentaryMessage.fulfilled, (state, { payload }) => {
        state.cardMessageStatus = Status.FULFILLED;
        state.cardMessage = payload._Data;
        state.retrieveCart = true;
      })
      .addCase(updateComplimentaryMessage.rejected, (state) => {
        state.cardMessageStatus = Status.ERROR;
      })
      .addCase(getCartCount.fulfilled, (state, { payload }) => {
        state.count = payload;
        state.cartCountStatus = Status.FULFILLED;
      })
      .addCase(getCartCount.pending, (state) => {
        state.cartCountStatus = Status.PENDING;
      })
      .addCase(getCartCount.rejected, (state) => {
        state.cartCountStatus = Status.ERROR;
      })
      .addCase(fetchArrangementsOneHourDelivery.pending, (state) => {
        state.oneHourDeliveryStatus = Status.PENDING;
      })
      .addCase(
        fetchArrangementsOneHourDelivery.fulfilled,
        (state, { payload }) => {
          state.oneHourDeliveryStatus = Status.FULFILLED;
          state.arrangementsOneHourDelivery = payload;
        },
      )
      .addCase(fetchArrangementsOneHourDelivery.rejected, (state) => {
        state.oneHourDeliveryStatus = Status.ERROR;
      })
      .addCase(updateExpressCheckout.pending, (state) => {
        state.editQuantityStatus = Status.PENDING;
      })
      .addCase(updateExpressCheckout.fulfilled, (state) => {
        state.retrieveCart = true;
        state.editQuantityStatus = Status.FULFILLED;
      })
      .addCase(updateExpressCheckout.rejected, (state) => {
        state.editQuantityStatus = Status.ERROR;
      })
      .addCase(addCouponCode.pending, (state) => {
        state.addCouponStatus = Status.PENDING;
      })
      .addCase(addCouponCode.fulfilled, (state, { payload }) => {
        state.recipientCart = payload.cart;
        state.retrieveCart = false;
        state.couponOptions.showCoupons = payload.strErrorMessage !== '';
        state.addCouponStatus = Status.FULFILLED;
        state.couponOptions.strErrorMessage = payload.strErrorMessage;
        state.couponOptions.crId = payload.crId;
      })
      .addCase(addCouponCode.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.addCouponStatus = Status.ERROR;
      })
      .addCase(removeCouponCode.pending, (state) => {
        state.removeCouponStatus = Status.PENDING;
      })
      .addCase(removeCouponCode.fulfilled, (state, { payload }) => {
        state.recipientCart = payload;
        state.retrieveCart = false;
        state.removeCouponStatus = Status.FULFILLED;
        state.couponOptions.showCoupons = true;
      })
      .addCase(removeCouponCode.rejected, (state) => {
        state.removeCouponStatus = Status.ERROR;
      })
      .addCase(editMarketplaceQuantity.pending, (state) => {
        state.editMarketplaceQuantityStatus = Status.PENDING;
      })
      .addCase(editMarketplaceQuantity.fulfilled, (state, { payload }) => {
        state.editMarketplaceQuantityStatus = Status.FULFILLED;
        if (!payload.marketplaceCart) {
          state.editMarketplaceQuantityStatus = Status.ERROR;
        } else {
          state.recipientCart = payload;
          state.editMarketplaceQuantityStatus = Status.FULFILLED;
          state.retrieveCart = false;
        }
      })
      .addCase(editMarketplaceQuantity.rejected, (state) => {
        state.editMarketplaceQuantityStatus = Status.ERROR;
      })
      .addCase(removeMarketplaceItem.fulfilled, (state, { payload }) => {
        state.recipientCart = payload;
        state.editMarketplaceQuantityStatus = Status.FULFILLED;
        window.scrollTo(0, 0);
      })
      .addCase(removeMarketplaceItem.pending, (state) => {
        state.editMarketplaceQuantityStatus = Status.PENDING;
      })
      .addCase(removeMarketplaceItem.rejected, (state) => {
        state.editMarketplaceQuantityStatus = Status.ERROR;
      })
      .addDefaultCase((state) => state);
  },
  reducers: {
    resetCouponState: (state) => {
      state.couponOptions = initialState.couponOptions;
      state.addCouponStatus = initialState.addCouponStatus;
      state.removeCouponStatus = initialState.removeCouponStatus;
    },
  },
});

export const recipientCartReducer = slice.reducer;
export const { resetCouponState } = slice.actions;
