import { AvailabilitySessionState } from 'redux/session/availability/availability.types';
import { DeliveryNote, OrderType } from 'types/cart.types';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import {
  getAvailabilitySession,
  resetAvailabilitySession,
  updateAvailabilitySession,
} from 'redux/session/availability/availability.action';

const initialState: AvailabilitySessionState = {
  data: {
    areaId: -2147483648,
    areaName: '',
    selectedDate: '',
    storeId: -2147483648,
    storeNumber: null,
    flexFromDate: '',
    flexToDate: '',
    fulfillmentDate: null,
    currentRecipientId: -2147483648,
    contactRecipientId: -2147483648,
    deliveryFeeDetailId: -2147483648,
    configDeliveryDate: '',
    arrangementGroupId: 5,
    serviceOption: OrderType.NotSpecified,
    storeAddress: '',
    storePhone: '',
    storeName: '',
    isPrintibleInOrder: false,
    isSet: false,
    isFlex: false,
    cartRecipient: {
      cardMessage: '',
      isCurbside: false,
      occasionId: 0,
      recipientContact: {
        id: 0,
        contactId: 0,
        title: '',
        name: '',
        company: '',
        addressType: 0,
        address1: '',
        address2: '',
        areaId: 0,
        areaMlId: 0,
        cityId: 0,
        cityMlId: 0,
        stateId: 0,
        countryId: 0,
        workPhone: '',
        phone: '',
        cellPhone: '',
        recipientEmail: '',
        firstName: '',
        lastName: '',
      },
      recipientNote: {
        note: '',
        type: DeliveryNote.NotSpecified,
        carInfo: '',
      },
      shippingMode: OrderType.NotSpecified,
      visualSentimentId: 0,
    },
  },
  status: Status.INIT,
  error: null,
};

const availabilitySessionSlice = createSlice({
  name: 'availabilitySession',
  initialState,
  reducers: {
    setSessionInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailabilitySession.pending, () => ({
        ...initialState,
        status: Status.PENDING,
      }))
      .addCase(getAvailabilitySession.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.status = Status.ERROR;
      })
      .addCase(getAvailabilitySession.fulfilled, (state, { payload }) => {
        state.data = { ...state.data, ...payload };
        state.status = Status.FULFILLED;
      })
      .addCase(updateAvailabilitySession.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(updateAvailabilitySession.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.status = Status.ERROR;
      })
      .addCase(updateAvailabilitySession.fulfilled, (state) => {
        state.status = Status.FULFILLED;
      })
      .addCase(resetAvailabilitySession.pending, () => ({
        ...initialState,
        status: Status.PENDING,
      }))
      .addCase(resetAvailabilitySession.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.status = Status.ERROR;
      })
      .addCase(resetAvailabilitySession.fulfilled, () => ({
        ...initialState,
        status: Status.FULFILLED,
      }))
      .addDefaultCase((state) => state);
  },
});

export const { reducer: availabilitySessionReducer } = availabilitySessionSlice;

export const availabilitySessionActions = {
  ...availabilitySessionReducer,
  getAvailabilitySession,
};
