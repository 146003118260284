import * as TK from 'components/ForgotPassword/translations/locale.constants';

export const forgotPasswordCommonLocale = {
  [TK.REWARDS_TOP_BANNER]: 'Rewards Top Banner',
  [TK.TITLE]: 'reset my password',
  [TK.DESCRIPTION]:
    'Your password must be at least 8 characters, and must contain at least 1 special character (!@#$%^&*). A good password should contain a mix of capital and lower-case letters, numbers and symbols.',
  [TK.EXPIRED_TITLE]: 'this reset password page has expired',
  [TK.EXPIRED_DESCRIPTION1]:
    'For security reasons, reset password pages stop working after you use them to reset a password.',
  [TK.EXPIRED_DESCRIPTION2_PART1]: `If you're trying to reset your password, please`,
  [TK.SIGN_IN_LINK]: 'go back to the sign in screen',
  [TK.EXPIRED_DESCRIPTION2_PART2]: `and click the "forgot password" link to reset it again.`,
  [TK.REQUIRED_FIELD]: '* Required field',
  [TK.NEW_PASSWORD_LABEL]: 'New Password*',
  [TK.CONFIRM_PASSWORD_LABEL]: 'Confirm Password*',
  [TK.SAVE_PASSWORD]: 'Save',
  [TK.ERROR_ENTER_PASSWORD]: 'Please enter a password',
  [TK.ERROR_PASSWORD_MINIMUM]: 'Password must be at least 8 characters',
  [TK.ERROR_PASSWORD_INVALID]:
    'Password must contain at least 1 special character (!@#$%^&*)',
  [TK.ERROR_VERIFY_PASSWORD_MATCH]: 'Passwords do not match',
  [TK.PASSWORD_FAILED_MSG]: 'Oops! Something went wrong.',
  [TK.ERROR_NOT_SAME_PASSWORD]:
    'To keep your account secure, you cannot reuse any of your previous 5 passwords.',
  [TK.INPUT_ERROR_NOT_SAME_PASSWORD]: 'Please enter a different password',
};
