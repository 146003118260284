import { Helmet } from 'react-helmet-async';
import { Locale, Locales } from 'enums/locale';
import React, { FC } from 'react';

type Props = {
  locale?: Locales;
};

export const HeadMeta: FC<Props> = ({ locale = Locale.EN_US }) => {
  const hrefLangUs = locale === Locale.EN_US ? 'en-us' : 'fr-us';
  const hrefLangCa = locale === Locale.EN_US ? 'en-ca' : 'fr-ca';

  return (
    <Helmet>
      <link
        rel="alternate"
        href={window.location.href.replace('.ca', '.com').split('?')[0]}
        hrefLang={hrefLangUs}
      />
      <link
        rel="alternate"
        href={window.location.href.replace('.com', '.ca').split('?')[0]}
        hrefLang={hrefLangCa}
      />
    </Helmet>
  );
};
