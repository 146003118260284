export const RECIPIENT_NAME = 'recipient_form.recipient_name';
export const REQUIRED_FIELD = 'recipient_form.required_field';
export const FIRST_NAME_PLACEHOLDER = 'recipient_form.first_name_placeholder';
export const FIRST_NAME_TEXT = 'recipient_form.first_name_text';
export const FIRST_NAME_TEXT_PICKUP = 'recipient_form.first_name_text_pickup';
export const LAST_NAME_PLACEHOLDER = 'recipient_form.last_name_placeholder';
export const ADDRESS_TITLE = 'recipient_form.address_title';
export const ADDRESS_SUBTITLE = 'recipient_form.address_sub_title';
export const ADDRESS_TYPE_LABEL = 'recipient_form.address_type_label';
export const BUSINESS_NAME_LABEL = 'recipient_form.business_name_label';
export const CITY_LABEL = 'recipient_form.city_label';
export const PO_BOX_NOTE = 'recipient_form.po_box_note';
export const STREET_ADDRESS = 'recipient_form.street_address';
export const APT_FLOOR_SUITE_BUTTON = 'recipient_form.apt_floor_suite_button';
export const APT_FLOOR_SUITE_LABEL = 'recipient_form.apt_floor_suite_label';
export const BUSINESS_WARNING = 'recipient_form.business_warning';
export const CHOOSE_OPTION = 'recipient_form.choose_option';
export const PICKUP_TIME = 'recipient_form.pickup_time';
export const ENTER_FIRST_NAME = 'recipient_form.enter_first_name';
export const ENTER_LAST_NAME = 'recipient_form.enter_last_name';
export const SAVE_TIME_TITLE = 'recipient_form.shipment_title';
export const SAVE_TIME_SHIPMENT = 'recipient_form.shipment_save_time';
export const ERROR_ALERT_ADD_TO_CART_HEADING =
  'recipient_form.error_modal.add_to_cart.heading';
export const ERROR_ALERT_ADD_TO_CART_BODY =
  'recipient_form.error_modal.add_to_cart.body';
export const ERROR_DELIVERY_CUTOFF_PASSED =
  'recipient_form.error_modal.delivery_cutoff';
export const ERROR_PICKUP_CUTOFF_PASSED =
  'recipient_form.error_modal.pickup_cutoff';
export const ERROR_PICKUP_IN_PAST = 'recipient_form.error_modal.pickup_in_past';
export const ERROR_CHOOSE_SAME_DAY_PICKUP_TIME_AFTER =
  'recipient_form.error_modal.choose_same_day_pickup_time_after';
export const ERROR_CHOOSE_PICKUP_TIME_AFTER =
  'recipient_form.error_modal.choose_pickup_time_after';
export const ERROR_SAME_DAY_SHIPMENT =
  'recipient_form.error_modal.same_day_shipment';
export const ERROR_NEXT_DAY_SHIPMENT_CUTOFF =
  'recipient_form.error_modal.next_day_shipment_cutoff';
export const ERROR_NO_SERVICE_OPTION =
  'recipient_form.error_modal.no_service_option';
export const ERROR_SERVICE_OPTION_STORE =
  'recipient_form.error_modal.service_option_store';
export const ERROR_STORE_IS_CLOSED =
  'recipient_form.error_modal.store_is_closed';
export const ERROR_DELIVERY_NOT_AVAILABLE_ON_DATE_FOR_STORE =
  'recipient_form.error_modal.delivery_not_available_on_date_for_store';
export const ERROR_PICKUP_NOT_AVAILABLE_ON_DATE_FOR_STORE =
  'recipient_form.error_modal.pickup_not_available_on_date_for_store';
export const ERROR_ORDER_DATE_IN_PAST =
  'recipient_form.error_modal.order_date_in_past';
export const ERROR_STORE_CANNOT_FULFILL_ON_DATE =
  'recipient_form.error_modal.store_cannot_fulfill_on_date';
export const ERROR_STORE_IS_CLOSED_ON_DATE =
  'recipient_form.error_modal.store_closed_on_date';
export const ERROR_UNCOMPLETED_REQUIRED_FIELDS =
  'recipient_form.error_modal.uncompleted_required_fields';
export const ERROR_FIRST_NAME_SPL_CHARS =
  'recipient_form.error_first_name_spl_chars';
export const ERROR_LAST_NAME_SPL_CHARS =
  'recipient_form.error_last_name_spl_chars';
export const ERROR_STREET_ADDRESS_SPL_CHARS =
  'recipient_form.error_street_address_spl_chars';
export const ERROR_REQUIRED = 'recipient_form.error_required';
export const ERROR_NAME_SPL_CHARS = 'recipient_form.error_name_spl_chars';
export const ERROR_OCCASION_ID = 'recipient_form.error_occasion_id';
