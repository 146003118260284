import * as TK from 'components/ForgotPassword/translations/locale.constants';
import * as yup from 'yup';
import i18next from 'i18next';

const passwordValidation = new RegExp(/^(?=.*[!@#$%^&*])(?=.{8,})/);

export const schema = yup.object({
  newPassword: yup
    .string()
    .trim()
    .min(8, i18next.t(TK.ERROR_PASSWORD_MINIMUM))
    .matches(passwordValidation, i18next.t(TK.ERROR_PASSWORD_INVALID))
    .required(i18next.t(TK.ERROR_ENTER_PASSWORD)),
  confirmPassword: yup
    .string()
    .trim()
    .required(i18next.t(TK.ERROR_ENTER_PASSWORD))
    .oneOf([yup.ref('newPassword')], i18next.t(TK.ERROR_VERIFY_PASSWORD_MATCH)),
});
