import { Container, Stack } from '@mui/material';
import { ForgotPassword } from 'components/ForgotPassword';
import { RewardsBanner } from 'components/ForgotPassword/Components/RewardsBanner';
import React from 'react';

export const ForgotPasswordContainer: React.FunctionComponent = () => (
  <Stack>
    <RewardsBanner />
    <Container>
      <ForgotPassword />
    </Container>
  </Stack>
);
