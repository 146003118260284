import { SxProps } from '@mui/system';
import { theme } from 'utils/theme/theme';

export const headerWrapper: SxProps = {
  borderBottom: `1px solid ${theme.palette.divider}`,
  minHeight: {
    md: '165px',
  },
};

export const desktopNavWrapper: SxProps = {
  top: 0,
  backgroundColor: theme.palette.primary.contrastText,
  zIndex: 999,
  width: '100%',
};

export const desktopNavWrapperSticky: SxProps = {
  top: 0,
  backgroundColor: theme.palette.primary.contrastText,
  zIndex: 999,
  width: '100%',
  position: 'fixed',
  animation: 'slideDown 0.5s ease-out',
  boxShadow: '0 5px 16px rgba(0, 0, 0, 0.1)',
  '@keyframes slideDown': {
    from: {
      transform: 'translateY(-100%)',
    },
    to: {
      transform: 'translateY(0)',
    },
  },
};

export const mobileNavWrapper: SxProps = {
  top: 0,
  backgroundColor: theme.palette.primary.contrastText,
  zIndex: 999,
  width: '100%',
};
