import {
  Control,
  FieldError,
  FieldPath,
  FieldValues,
  useController,
} from 'react-hook-form';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { StrengthMeter } from 'components/Payment/BillingInformation/strength-meter';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useMemo, useState } from 'react';

type TInputPasswordProperties<T> = TextFieldProps & {
  name: FieldPath<T>;
  control: Control<T>;
  showStrengthMeter?: boolean;
};

export const InputPassword = <T extends FieldValues>({
  showStrengthMeter,
  name,
  control,
  ...rest
}: TInputPasswordProperties<T>): React.ReactElement => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    field,
    fieldState: { invalid, error, isDirty },
  } = useController<T>({
    control,
    name,
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const isInputError = useMemo(
    () => Boolean(invalid && isDirty),
    [invalid, isDirty],
  );

  return (
    <>
      <TextField
        {...field}
        type={showPassword ? 'text' : 'password'}
        sx={{
          'input::-ms-clear': {
            display: 'none',
          },
          'input::-ms-reveal': {
            display: 'none',
          },
        }}
        error={isInputError}
        helperText={isInputError && (error as FieldError)?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ marginRight: 1 }}>
              <IconButton
                aria-label={showPassword ? 'Hide password' : 'Show password'}
                onClick={togglePasswordVisibility}
                tabIndex={-1}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
      {showStrengthMeter && <StrengthMeter password={field.value} />}
    </>
  );
};
