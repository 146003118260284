export enum ItemType {
  NotSpecified = 0,
  Arrangement = 1,
  Package = 2,
  Upgrade = 3,
  AddOn = 4,
  Container = 5,
}

export enum DeliveryNote {
  NotSpecified = 0,
  DeliveryNote = 1,
  PickupNote = 2,
  ShipmentNote = 3,
  DirectionNote = 4,
  GeneralNote = 5,
}

export enum MpDigitalCardDeliveryMethods {
  TEXT = 0,
  EMAIL = 1,
  LINK = 3,
}

export type CartStoreNumberSetting = {
  AllStoreEnabled: false;
  EnabledStoreList?: string;
};

export type DeliveryNoteType = typeof DeliveryNote[keyof typeof DeliveryNote];
export type ItemTypes = typeof ItemType[keyof typeof ItemType];

export type CartUpsell = {
  AddOnType?: 0;
  CYOA?: false;
  DefaultSleeve?: false;
  ImagePrefixSleeve?: '';
  ItemType: ItemType;
  Numbers?: null;
  OptionText: string;
  ParentProductID?: 0;
  Price: string;
  PrintibleCard: string;
  PrintibleCardResponse: string;
  ProductID: number;
  ProductImagePath?: '';
  ProductName?: '';
  Quantity: number;
  Sleeve?: false;
  Years?: null;
};

export type FreeProduct = {
  Quantity: number;
  ArrangementID: number;
  ArrangementProductID: number;
  ArrangementPromotionID: number;
};

export enum OrderType {
  NotSpecified = 0,
  Delivery = 1,
  Pickup = 2,
  Shipment = 3,
  WalkIn = 4,
}

export type FulfillType = typeof OrderType[keyof typeof OrderType];

export enum AddressType {
  NotSpecified = 0,
  Residential = 1,
  Business = 2,
}

export type AddressTypes = typeof AddressType[keyof typeof AddressType];

export type OrderTypeDependencies = {
  AddressType: AddressTypes;
  FulfillmentDate: string;
  IsCurbSide?: boolean;
  CarInfo?: string;
};

export type FlexCartParams = {
  FlexOptionID: number;
  FlexFromDate: string;
  FlexToDate: string;
  FlexDiscount: number;
};

export type MarketPlaceParams = {
  MarketPlaceRecipient: boolean;
  MPCardSenderName: string;
  MPCardMessage: string;
  MPCardMessageID: string;
  MarketPlaceProduct: {
    MarketPlaceProductID: string;
    MarketPlaceVariantID: string;
  };
  MPCardDeliveryMethod: MpDigitalCardDeliveryMethods;
  MPCardMessageEmail: string;
  MPCardMessageUrl?: string;
  MPCardMessageRecipientPhone: string;
};

export type LegacyAddToCartParams = OrderTypeDependencies &
  FlexCartParams &
  MarketPlaceParams & {
    OrderType: FulfillType;
    EditRecipientID: string;
    AreaID: number;
    AreaName: string;
    StateCode: string;
    StoreID: number;
    ArrangementID: number;
    ArrangementSizeID: string | number;
    Quantity: number;
    ProductID: string | number;
    FirstName: string;
    LastName: string;
    RecipientEmail: string;
    DeliveryOrPickupInstructions: string;
    Address1: string;
    Address2: string;
    Company: string;
    CityName: string;
    CityMLID: string;
    HomePhone: string;
    WorkPhone: string;
    CellPhone: string;
    OccassionID: string;
    CardMessage: string;
    ArrangementGroupID: number;
    AddonItems: CartUpsell[];
    UpgradeItems: CartUpsell[];
    ServiceOption: OrderType;
    CurrentRecipientID: number;
    VaultedCartID: string;
    EditRecipientMPID: string;
  };

export type FullLegacyAddToCartParams = LegacyAddToCartParams & {
  FreeProducts?: FreeProduct[];
  ArrangementOption: string;
  ArrangementOptionID: number;
  DeliveryFeeDetailID: number;
  ConfigDeliveryFeeCharges: string;
  ConfigDeliveryDate: string;
  CallRecipient: null;
  OccassionRec: boolean;
  FulfillmentTimeFrom: string;
  FulfillmentTimeTo: string;
  OccassionRecDate: string;
  EditRecipientID: string;
  skipOnDelivery: '0';
  RecipientEmail: string;
  Pickup7Minute: false;
  Catering: false;
};
export type FulfillmentOptionType =
  | OrderType.Shipment
  | OrderType.Delivery
  | OrderType.Pickup;

export type ValidOrderTypes = FulfillmentOptionType | OrderType.NotSpecified;

export type UpdatePickUpTimeParams = {
  StateCode: string;
  AreaName: string;
  ServiceOption: OrderType;
  AreaID: number;
  FirstName: string;
  LastName: string;
  AddressType: number;
  Company: string;
  Address1: string;
  Address2: string;
  CityName: string;
  CityMLID: string;
  HomePhone: string;
  WorkPhone: string;
  CellPhone: string;
  RecipientEmail: string;
  OccasionID: number;
  EditCartRecipientID: number;
  FulFillmentDate: string;
  IsCurbSide: boolean;
  CarInfo: string;
};

export type FullUpdatePickUpTimeParams = UpdatePickUpTimeParams & {
  OccasionRec: boolean;
  OccasionRecDate: string;
  SkipOnDelivery: number;
  ToValidateAddress: true;
  Pickup7Minute: false;
  CallRecipient: null;
  FulfillmentTimeFrom: string;
  FulfillmentTimeTo: string;
};

export enum UpdateAddOnsStatus {
  OK = 'OK',
  Error = 'Error',
  NotFound = 'NotFound',
}

export type UpdateAddOnsResponse = {
  _Status?: string;
  _Data?: string;
};
type UpdateAddOnsSuccess = {
  _Status: UpdateAddOnsStatus.OK;
  _Data: boolean;
};

type UpdateAddOnsError = {
  _Status: UpdateAddOnsStatus.Error;
  _Data: string;
};

export type UpdateAddOnsParsedResponse =
  | UpdateAddOnsSuccess
  | UpdateAddOnsError;
