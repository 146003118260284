import * as TK from 'components/PDP/Order/RecipientForm/translations/translation-keys.constant';

export const recipientFormCommonLocale = {
  [TK.RECIPIENT_NAME]: 'Recipient contact information',
  [TK.REQUIRED_FIELD]: '* Required field',
  [TK.FIRST_NAME_PLACEHOLDER]: 'First Name*',
  [TK.FIRST_NAME_TEXT]: 'The recipient is the one RECEIVING this item',
  [TK.FIRST_NAME_TEXT_PICKUP]: 'The recipient is the one PICKING UP',
  [TK.LAST_NAME_PLACEHOLDER]: 'Last Name*',
  [TK.ADDRESS_TITLE]: 'Recipient address',
  [TK.ADDRESS_SUBTITLE]:
    'Select Residential or Business for your address type, then enter details.',
  [TK.ADDRESS_TYPE_LABEL]: 'Address Type*',
  [TK.BUSINESS_NAME_LABEL]: '{{type}} Name',
  [TK.CITY_LABEL]: 'City / Town*',
  [TK.PO_BOX_NOTE]: 'We cannot deliver to P.O. Boxes.',
  [TK.STREET_ADDRESS]: 'Street Address*',
  [TK.APT_FLOOR_SUITE_BUTTON]: 'Apt/Floor/Suite',
  [TK.APT_FLOOR_SUITE_LABEL]: 'Apt/Floor/Suite',
  [TK.PICKUP_TIME]: 'Pickup Time',
  [TK.CHOOSE_OPTION]: 'Please Choose',
  [TK.BUSINESS_WARNING]:
    'We do not advise same day business deliveries to be placed after 3:00 PM as many businesses close at 5:00 PM. Your local store may contact you if you place a same day order for delivery to reschedule your order for another business day.',
  [TK.ENTER_FIRST_NAME]: 'Enter first name.',
  [TK.ENTER_LAST_NAME]: 'Enter last name.',
  [TK.SAVE_TIME_TITLE]: 'Select existing recipient',
  [TK.SAVE_TIME_SHIPMENT]:
    'Save time with auto-fill! Choose an existing recipient from your cart, or login to choose a recipient from your address book.',
  [TK.ERROR_ALERT_ADD_TO_CART_HEADING]: 'Whoops a daisy!',
  [TK.ERROR_ALERT_ADD_TO_CART_BODY]:
    "We're having trouble adding this item to the cart. Please try again in a few minutes.",
  [TK.ERROR_DELIVERY_CUTOFF_PASSED]:
    'Oops, the delivery cutoff time has passed for today. Please select another date.',
  [TK.ERROR_PICKUP_CUTOFF_PASSED]:
    'Oops, the pickup cutoff time has passed for today. Please select another date.',
  [TK.ERROR_PICKUP_IN_PAST]:
    'Oops, the pickup date may not be in the past. Please select another date.',
  [TK.ERROR_CHOOSE_SAME_DAY_PICKUP_TIME_AFTER]:
    'We’re sorry but store {{store}} does not accept same-day pickup orders after {{time}}. Please select a different date.',
  [TK.ERROR_CHOOSE_PICKUP_TIME_AFTER]:
    'Oops, the pickup time selected is not available. Please choose a time after {{time}} or contact the store.',
  [TK.ERROR_SAME_DAY_SHIPMENT]:
    'Oops, we currently do not offer same-day shipping, please select another date.',
  [TK.ERROR_NEXT_DAY_SHIPMENT_CUTOFF]:
    'Oops, the next-day shipment is no longer available today, please select another date.',
  [TK.ERROR_NO_SERVICE_OPTION]:
    'Oops, we are unable to process your selection. Please refresh the page.',
  [TK.ERROR_SERVICE_OPTION_STORE]:
    'We are sorry, the product size that you selected is not available at this store. Please select a different size or store.',
  [TK.ERROR_ORDER_DATE_IN_PAST]:
    'The order date is in the past and is no longer available. Please select another date.',
  [TK.ERROR_STORE_IS_CLOSED_ON_DATE]:
    'We’re sorry but the store you’ve selected is closed on this day. Please select another date.',
  [TK.ERROR_STORE_CANNOT_FULFILL_ON_DATE]:
    'We’re sorry but the store you’ve selected cannot complete the order on {{date}}. Please select another date.',
  [TK.ERROR_STORE_IS_CLOSED]:
    'We’re sorry but the store you’ve selected is closed on this day. Please select another date.',
  [TK.ERROR_DELIVERY_NOT_AVAILABLE_ON_DATE_FOR_STORE]:
    'We’re sorry, delivery is currently not available on {{date}} from the store you selected {{store}}. Please select another date.',
  [TK.ERROR_PICKUP_NOT_AVAILABLE_ON_DATE_FOR_STORE]:
    'We’re sorry, pick up is currently not available on {{date}} from the store you selected {{store}}. Please selected another date.',
  [TK.ERROR_UNCOMPLETED_REQUIRED_FIELDS]:
    'Please complete all required fields: {{fields}}',
  [TK.ERROR_FIRST_NAME_SPL_CHARS]: 'Enter a valid first name',
  [TK.ERROR_LAST_NAME_SPL_CHARS]: 'Enter a valid last name',
  [TK.ERROR_STREET_ADDRESS_SPL_CHARS]: 'Enter a valid street address.',
  [TK.ERROR_REQUIRED]: 'required',
  [TK.ERROR_NAME_SPL_CHARS]: 'Enter a valid name or send anonymously',
  [TK.ERROR_OCCASION_ID]: 'Select an occasion',
};
