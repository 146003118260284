export const REWARDS_TOP_BANNER = 'rewards.top.banner';
export const TITLE = 'forgot.password.title';
export const DESCRIPTION = 'forgot.password.description';
export const EXPIRED_TITLE = 'forgot.password.expired.title';
export const EXPIRED_DESCRIPTION1 = 'forgot.password.expired.description1';
export const EXPIRED_DESCRIPTION2_PART1 =
  'forgot.password.expired.description2.part1';
export const EXPIRED_DESCRIPTION2_PART2 =
  'forgot.password.expired.description2.part2';
export const SIGN_IN_LINK = 'forgot.password.sign.in.link';
export const REQUIRED_FIELD = 'forgot.password.required.field';
export const NEW_PASSWORD_LABEL = 'forgot.password.new.password.label';
export const CONFIRM_PASSWORD_LABEL = 'forgot.password.confirm.password.label';
export const SAVE_PASSWORD = 'forgot.password.save.password';
export const ERROR_ENTER_PASSWORD = 'forgot.password.error.enter.password';
export const ERROR_PASSWORD_MINIMUM = 'forgot.password.error.password.minimum';
export const ERROR_PASSWORD_INVALID = 'forgot.password.error.password.invalid';
export const PASSWORD_FAILED_MSG = 'forgot.password.failed.msg';
export const ERROR_VERIFY_PASSWORD_MATCH =
  'forgot.password.error.verify.password.match';
export const ERROR_NOT_SAME_PASSWORD =
  'forgot.password.error.not.same.password';
export const INPUT_ERROR_NOT_SAME_PASSWORD =
  'forgot.password.input.error.not.same.password';
