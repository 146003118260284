import * as TK from 'components/ForgotPassword/translations/locale.constants';
import { Box, Link, Typography } from '@mui/material';
import { useOpenLogInDialog } from 'components/Authentication/hooks/control';
import React from 'react';
import i18next from 'i18next';

export const InvalidResetPasswordCode: React.VoidFunctionComponent = () => {
  const openLogInDialog = useOpenLogInDialog();

  const handleLoginClick = () => {
    openLogInDialog();
  };

  return (
    <Box>
      <Typography variant="h4">{i18next.t(TK.EXPIRED_TITLE)}</Typography>
      <Typography variant="body1" mt={2}>
        {i18next.t(TK.EXPIRED_DESCRIPTION1)}
        <br />
        <br />
        {i18next.t(TK.EXPIRED_DESCRIPTION2_PART1)}
        &nbsp;
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link component="button" variant="body1" onClick={handleLoginClick}>
          {i18next.t(TK.SIGN_IN_LINK)}
        </Link>
        &nbsp;
        {i18next.t(TK.EXPIRED_DESCRIPTION2_PART2)}
      </Typography>
    </Box>
  );
};
