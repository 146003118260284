import { BannerImage } from 'components/ForgotPassword/Components/BannerImage';
import { Box, Hidden } from '@mui/material';
import React from 'react';

export const RewardsBanner: React.VoidFunctionComponent = () => {
  return (
    <Box>
      <Hidden mdDown>
        <BannerImage size="desktop" />
      </Hidden>
      <Hidden smDown mdUp>
        <BannerImage size="tablet" />
      </Hidden>
      <Hidden xsDown smUp>
        <BannerImage size="phone" />
      </Hidden>
    </Box>
  );
};
