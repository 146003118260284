export const ORDER_CONTACTS_ADD_BUTTON = 'order.contacts.add.button';
export const ORDER_CONTACTS_WORK_PHONE = 'order.contacts.work_phone';
export const ORDER_CONTACTS_HOME_PHONE = 'order.contacts.phone';
export const ORDER_CONTACTS_EMAIL_LABEL = 'order.contacts.email.label';
export const ORDER_CONTACTS_CELL_PHONE = 'order.contacts.cell_phone';
export const ORDER_CONTACTS_HEADER = 'order.contacts.header';
export const ORDER_CONTACTS_ALERT = 'order.contacts.alert';
export const ORDER_URL_DELIVERY_MESSAGE = 'order.url.message';
export const ORDER_DELIVERY_TEXT_MESSAGE_DISCLAIMER =
  'order.delivery.text.message.disclaimer';
export const MARKETPLACE_DIGITAL_CARD_DELIVERY_TITLE =
  'order.delivery.marketplace_card.title';
export const MARKETPLACE_DIGITAL_CARD_DELIVERY_BODY =
  'order.delivery.marketplace_card.body';
