import { AREA_NOT_FOUND } from 'components/AreaController/feature/area.constants';
import { AxiosError } from 'axios';
import { ProcessPaymentRequest } from 'api/payment/types/process-payment-request.type';
import { ResponseError } from 'types/error.types';
import { areaApiService } from 'api/area/area.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import { paymentApiService } from 'api/payment/payment.api.service';

export const processPayment = createAsyncThunk(
  'processPayment/fetch',
  async (request: ProcessPaymentRequest, { rejectWithValue }) => {
    try {
      const data = await paymentApiService.processPayment(request);
      return data;
    } catch (ex) {
      const error = ex as AxiosError;

      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after some time',

          data: { key: 'process payment failed' },

          status: 404,
        };

        return rejectWithValue(responseError);
      }

      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const getCountries = createAsyncThunk(
  'getCountries/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const data = await paymentApiService.getCountries();
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(<AxiosError>err));
    }
  },
);

export const getStates = createAsyncThunk(
  'getStates/fetch',
  async (countryId: number, { rejectWithValue }) => {
    try {
      const data = await paymentApiService.getStates(countryId);
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(<AxiosError>err));
    }
  },
);

export const getArea = createAsyncThunk(
  'area/fetch',
  async (name: string, { rejectWithValue }) => {
    try {
      const { data } = await areaApiService.getAreas(name, 1);

      if (!data.length) {
        const error: ResponseError = {
          message: 'Area not found',
          data: { key: AREA_NOT_FOUND },
          status: 404,
        };
        return rejectWithValue(error);
      }

      return {
        id: data[0].id,
        cities: data[0].cities,
        state: data[0].stateCode,
        country: data[0].countryCode,
      };
    } catch (err) {
      return rejectWithValue(parseAxiosError(err as AxiosError));
    }
  },
);

export const isEmailAlreadyExist = createAsyncThunk(
  'emailAleadyExist/fetch',
  async (email: string, { rejectWithValue }) => {
    try {
      const data = await paymentApiService.isEmailAlreadyExist(email);
      return data;
    } catch (ex) {
      const error = ex as AxiosError;

      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after some time',

          data: { key: 'Email validation failed' },

          status: 404,
        };

        return rejectWithValue(responseError);
      }

      return rejectWithValue(parseAxiosError(error));
    }
  },
);
