import * as TK from 'translations/locales/translation-keys.constant';
import {
  ENTER_VALID_ZIP,
  ZIP_SHOULD_BE_VALID,
} from 'utils/pas-control.constants';
import { ResourceLanguage } from 'i18next';
import { common } from 'translations/locales/common';
import { pluralize } from 'translations/utils';

export const locale: ResourceLanguage = {
  translations: {
    ...common,
    [TK.WE_GOT_AN_ERROR]: 'We got an error. Please try again in a few minutes',
    'pdp.general.error.something_went_wrong':
      'Something went wrong. Please try again in few moments',
    'pdp.main.error.smth_went_wrong':
      'Something went wrong while getting available dates for shipment. Please try again in few moments',
    'pdp.main.error.ship_on_given_date': 'Select another date for delivery',
    'pdp.main.error.store_not_found_for_mode': 'Store is not found for mode',
    'pdp.main.error.no_argument_available_for_catalog':
      'No Arrangement available for catalog: {{catalogNumber}}',
    'pdp.main.error.no_catalog_number_available_for_url':
      'No catalog number available for page friend url: {{pageFriendlyUrl}}',
    'pdp.main.error.no_ingredient_available_for_arrangement':
      'No Ingredients available for arrangementId: {{arrangementId}}',
    'pdp.main.error.no_products_available_for_arrangement':
      'No Products available for arrangementId: {{arrangementId}}',
    'pdp.main.error.no_reviews_available_for_arrangement':
      'No Reviews available for arrangementId: {{arrangementId}}',
    'pdp.main.error.cannot_find_store': 'Cannot find store to process order',
    'pdp.main.error.city_not_found': 'City not found',
    'pdp.main.error.cannot_parse_response': "Can't parse response",
    [ZIP_SHOULD_BE_VALID]: 'Enter a valid zip code',
    [ENTER_VALID_ZIP]: 'Enter a valid zip code to continue',
    'pdp.main.error.provided_zip': 'Zip should be provided',
    'pdp.main.error.provided_date': 'Date should be provided',
    'pdp.main.error.enter_zip_and_date':
      'Enter a zip code and choose a date to continue',
    'pdp.choose_recipient': 'Choose Recipient',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_MODEL]: 'Enter Car Model',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_COLOR]: 'Enter Car Color',
    [TK.RECIPIENT_INFORMATION_ERROR_VEHICLE_MODEL]: 'Enter Vehicle Model',
    [TK.RECIPIENT_INFORMATION_ERROR_VEHICLE_COLOR]: 'Enter Vechicle Color',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_MODEL_SPL_CHARS]:
      'Enter a valid car model',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_COLOR_SPL_CHARS]:
      'Enter a valid car color',
    [TK.NUMBER_OF_SIZES_ONE]: 'One Size',
    [TK.NUMBER_OF_SIZES_TWO]: 'Two Sizes',
    [TK.NUMBER_OF_SIZES_THREE]: 'Three Sizes',
    [TK.NUMBER_OF_SIZES_FOUR]: 'Four Sizes',
    [TK.NUMBER_OF_SIZES_FIVE]: 'Five Sizes',
    [TK.NUMBER_OF_SIZES_SIX]: 'Six Sizes',
    [TK.NUMBER_OF_SIZES_SEVEN]: 'Seven Sizes',
    [TK.NUMBER_OF_SIZES_EIGHT]: 'Eight Sizes',
    [TK.NUMBER_OF_SIZES_NINE]: 'Nine Sizes',
    [TK.NUMBER_OF_SIZES_TEN]: 'Ten Sizes',
    [TK.NUMBER_OF_SIZES_STARTING_AT]: 'Starting At',
    [TK.NUMBER_OF_SIZES_NUMERIC_QUANTITY]: 'Sizes Starting At',
    [TK.NUMBER_OF_SIZES_NO_SIZES]: 'There are no available sizes',
    [TK.IMAGE_NOT_AVAILABLE]: 'Not available',
    [TK.CLOSE]: 'Close',
    [TK.DAY_OF_WEEK_SUNDAY_LONG]: 'Sunday',
    [TK.DAY_OF_WEEK_MONDAY_LONG]: 'Monday',
    [TK.DAY_OF_WEEK_TUESDAY_LONG]: 'Tuesday',
    [TK.DAY_OF_WEEK_WEDNESDAY_LONG]: 'Wednesday',
    [TK.DAY_OF_WEEK_THURSDAY_LONG]: 'Thursday',
    [TK.DAY_OF_WEEK_FRIDAY_LONG]: 'Friday',
    [TK.DAY_OF_WEEK_SATURDAY_LONG]: 'Saturday',
    [TK.DAY_OF_WEEK_SUNDAY_SHORT]: 'Sun',
    [TK.DAY_OF_WEEK_MONDAY_SHORT]: 'Mon',
    [TK.DAY_OF_WEEK_TUESDAY_SHORT]: 'Tue',
    [TK.DAY_OF_WEEK_WEDNESDAY_SHORT]: 'Wed',
    [TK.DAY_OF_WEEK_THURSDAY_SHORT]: 'Thu',
    [TK.DAY_OF_WEEK_FRIDAY_SHORT]: 'Fri',
    [TK.DAY_OF_WEEK_SATURDAY_SHORT]: 'Sat',
    [TK.HEADER_USER_PROFILE_MENU_SIGN_UP]: 'Sign Up',
    [TK.HEADER_USER_PROFILE_MENU_SIGN_OUT]: 'Sign Out',
    [TK.HEADER_USER_PROFILE_ACCOUNT]: 'Account',
    [TK.HEADER_USER_PROFILE_MY_REWARDS]: 'My Rewards',
    [TK.HEADER_USER_PROFILE_FAQ]: 'FAQs & Benefits',
    [TK.HEADER_USER_PROFILE_MY_INFORMATION]: 'My Information',
    [TK.HEADER_USER_PROFILE_ORDER_HISTORY]: 'Order History',
    [TK.HEADER_USER_PROFILE_GIFT_REMINDERS]: 'Gift Reminders',
    [TK.HEADER_USER_PROFILE_ADDRESS_BOOK]: 'Address Book',
    [TK.HEADER_HELP]: 'Help',
    [TK.HEADER_FIND_STORE]: 'Find a Store',
    [TK.HEADER_EDIBLE_REWARDS]: 'Edible Rewards',
    [TK.HEADER_SHOPPING_CART]: 'Shopping Cart',
    [TK.HEADER_HELP_TRACK_YOUR_ORDER]: 'Track Your Order',
    [TK.HEADER_HELP_CONTACT_US]: 'Contact Us',
    [TK.HEADER_HELP_ALLERGY_SAFETY_INFORMATION]: 'Allergy & Safety Information',
    [TK.HEADER_HELP_PROMOTION_TERMS_CONDITIONS]: 'Promotion Terms & Conditions',
    [TK.HEADER_HELP_CERTIFIED_HAPPINESS]: 'Certified Happiness',
    [TK.HEADER_GET_HELP]: 'Get Help',
    [TK.HEADER_HELP_EDIBLE_GUARANTEE]: 'Edible Guarantee',
    [TK.HEADER_HELP_FAQS]: 'FAQs',
    [TK.HEADER_HELP_PRODUCTS_POLICY]: 'Products Policy',
    [TK.HEADER_STORES_FRANCHISE_OPPORTUNITIES]: 'Franchise Opportunities',
    [TK.HEADER_STORES_EDIBLE_TREATS]: 'Edible® treats',
    [TK.HEADER_ABOUT_WHO_WE_ARE]: 'Who we are',
    [TK.HEADER_ABOUT_OUR_MISSION]: 'Our mission: to wow you®',
    [TK.HEADER_ABOUT_OUR_HISTORY]: 'Our history',
    [TK.HEADER_ABOUT_FOUNDER]: 'Founder, Tariq Farid',
    [TK.HEADER_ABOUT_CONTACT_US]: 'Contact Us',
    [TK.HEADER_OTHER_CAREERS]: 'Careers',
    [TK.HEADER_OTHER_PRESS]: 'Press',
    [TK.HEADER_OTHER_BLOG]: 'Blog',
    [TK.HEADER_NAV_CUSTOMER_SERVICE]: 'Customer Service',
    [TK.HEADER_NAV_STORES]: 'Stores',
    [TK.HEADER_NAV_ABOUT]: 'About',
    [TK.HEADER_NAV_ABOUT_EDIBLE]: 'About Edible Arrangements',
    [TK.HEADER_NAV_OTHER]: 'Other',
    [TK.HEADER_NAV_MENU]: 'Menu',
    [TK.HEADER_NAV_MORE_LINKS]: 'More Links',
    [TK.HEADER_NAV_LANGUAGE]: 'Language',
    [TK.SLIDER_NEXT]: 'Next',
    [TK.SLIDER_PREVIOUS]: 'Previous',
    [TK.SUBSCRIPTION_FOOTER_TITLE]:
      'Sign up for promotions and special offers.',
    [TK.SUBSCRIPTION_FOOTER_FORM_EMAIL_LABEL]: 'Email Address',
    [TK.SUBSCRIPTION_FOOTER_FORM_SUBMIT]: 'Submit',
    [TK.SUBSCRIPTION_FOOTER_FORM_EMAIL_ERROR]:
      'Please enter a valid email address',
    [TK.SUBSCRIPTION_FOOTER_SIGNUP_FORM_EMAIL_ERROR]: 'Enter a valid email',
    [TK.SUBSCRIPTION_SHOP_NOW]: 'Shop Now',
    [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BEFORE]: 'See ',
    [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BETWEEN_AND]: 'and',
    [TK.SUBSCRIPTION_EMAIL_FORM_TERMS_AND_CONDITIONS]: 'Terms & Conditions',
    [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_AFTER]: ' for details.',
    [TK.SUBSCRIPTION_EMAIL_FORM_LABEL]: 'Email Address*',
    [TK.SUBSCRIPTION_SHOP_NOW]: 'Shop Now',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_TITLE]: 'Unlock your discount',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_JOIN_EMAIL_TEXT]:
      'Join our email list for ',
    [TK.NEW_SUBSCRIPTION_SIGNUP_FIRST_JOIN_EMAIL_SMS_TEXT]:
      'Join our email or SMS list for ',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_SAVINGS_TEXT]: 'instant savings',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_TEXT]: 'instant',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_SAVINGS_TEXT]: 'savings',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_PICKUP_ORDER_TEXT]:
      'on your next pickup order',
    [TK.SUBSCRIPTION_FIRST_POPUP_TITLE]: 'Something You’ll Love!',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_VAL]: '15',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_PER]: '%',
    [TK.VIEW_DETAILS]: 'View Details',
    [TK.HIDE_DETAILS]: 'Hide Details',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_TEXT]: 'off',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_STAR]: '*',
    [TK.SUBSCRIPTION_FIRST_POPUP_DESCRIPTION]:
      'your next pickup order when you sign up to receive our emails today!',
    [TK.SUBSCRIPTION_NEW_SUCCESS_POPUP_TITLE]: 'sweet!',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_FIRST]:
      'Thanks for signing up! Check your email inbox',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_LAST]:
      'for 15% off your next pickup purchase.',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_SOCIAL_LINKS_TITLE]:
      'Connect with us on social media:',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_GIFT_TEXT]:
      'Order a freshly-crafted gift for any occasion!',
    [TK.SUBSCRIPTION_IMAGE_TITLE]:
      'Delicious Fruit design® Dipped Strawberries',
    [TK.SUBSCRIPTION_EMAIL_FORM_BUTTON_LABEL]: 'Sign Up',
    [TK.ERROR_PHONE]: 'Invalid phone number',
    [TK.ERROR_EMAIL]: 'Invalid email',
    [TK.REQUIRED_EMAIL]: 'Email is required',
    [TK.ERROR_AGREE]: 'You must agree to the policy',
    [TK.LOG_IN]: 'Log In',
    [TK.SUBSCRIPTION_TERMS_OF_USE]: 'See Terms of Use',
    [TK.SUBSCRIPTION_FORM_PHONE_LABEL]: 'Phone Number',
    [TK.SUBSCRIPTION_FORM_PHONE_ERROR]: 'Enter a valid phone #',
    [TK.SUBSCRIPTION_FORM_POLICY_LABEL]: 'I agree to the',
    [TK.SUBSCRIPTION_FORM_POLICY_TOOLTIP]:
      'I agree to receive periodic automated text messages from or on behalf of Edible Arrangements, LLC edible.com promotions, special offers, and discounts at the phone number provided above. I understand that my consent is not required and is not a condition of any purchase. Standard text message and data rates apply. Not all carriers covered. I understand that checking this box constitutes my signature. To opt-out, reply to an EA text with STOP to stop, and HELP for Help.',
    [TK.SUBSCRIPTION_FORM_POLICY_TOOLTIP_LABEL]: 'Communications Policy.',
    [TK.SUBSCRIPTION_FORM_POLICY_ERROR]: 'Please check the policy',
    [TK.SUBSCRIPTION_SIGNUP_POLICY_ERROR]: 'Please agree to continue',
    [TK.SOCIAL_LINKS_TITLE]: 'Follow Us',
    [TK.PAYMENT_OPTIONS_TITLE]: 'Secure Payments By',
    [TK.NEAREST_STORE_TITLE]: 'Your Nearest Edible Store',
    [TK.NEAREST_STORE_TODAY_LABEL]: 'Today',
    [TK.NEAREST_STORE_DIRECTIONS_LABEL]: 'Get Directions',
    [TK.TERMS_OF_USE_LABEL]: 'Terms of Use',
    [TK.PRIVACY_POLICY_LABEL]: 'Privacy Policy',
    [TK.CALIFORNIA_PRIVACY_RIGHTS_LABEL]: 'California Privacy Rights',
    [TK.CORPORATE_PROMOTION_LABEL]: 'Promotion Terms and Conditions',
    [TK.COOKIE_SETTINGS_LABEL]: 'Your Privacy Choices',
    [TK.CORPORATE_COPYRIGHT_LABEL]:
      'Edible Arrangements, LLC. All rights reserved.',
    [TK.DESKTOP_SEARCH_PLACEHOLDER]: 'Looking for something special?',
    [TK.SEARCH_HELPER_TEXT]: 'Search for products, stores and information...',
    [TK.SEARCH_SUGGESTED_RESULTS]: 'Suggested Results:',
    [TK.SEARCH_NO_SUGGESTIONS]: 'No suggestions found',
    [TK.SEARCHING_TEXT]: 'Searching for suggestions',
    [TK.ALL_RESULTS]: 'All Results',
    [TK.CHOOSE_VARIANT]: 'Choose an Option:',
    [TK.SINGLE_VARIANT]: 'Single Size:',
    [TK.ENTER_ONE_PHONE_NUMBER]: 'Enter a valid phone number',
    [TK.ENTER_ONE_VALID_PHONE_NUMBER]:
      'Please enter a valid home/work/cell phone number.',
    [TK.CANT_BEGIN_WITH_O_OR_1]:
      "Number can't begin with '0' or '1'. Please provide a 10-digit phone number, starting with the area code.",
    [TK.HOME_PAGE_VIEW_ALL]: 'View All',
    [TK.HOME_PAGE_CAROUSEL_FORWARD_CONTROL]:
      'Move carousel forward to revel more products',
    [TK.HOME_PAGE_CAROUSEL_BACKWARDS_CONTROL]:
      'Move carousel backwards to previous products',
    [TK.NEAREST_STORE_WEEKLY_HOURS]: 'Display nearest store weekly hours',
    [TK.NEAREST_STORE_PHONE_CONTACT]:
      'Call the Edible Store closest to your location',
    [TK.NEAREST_STORE_DIRECTIONS]:
      'Get directions to the closest Edible Store to your location',
    [TK.HOME_PAGE_H1_HEADING]:
      'Edible Arrangements Fruit Bouquets, Chocolate Covered Strawberries & Gift Delivery',
    [TK.FRANCHISE_BANNER_TITLE]: 'the sweetest deal in franchising!',
    [TK.FRANCHISE_BANNER_BODY]:
      'Open your own Edible® storefront and join our passionate, global community of franchise owners',
    [TK.FRANCHISE_BANNER_BUTTON]: 'Learn More',
    [TK.POPULAR_CATEGORIES]: 'Popular Categories',
    [TK.EDIBLE_GUARANTEE_TITLE]: 'edible guarantee',
    [TK.EDIBLE_GUARANTEE_DESCRIPTION]:
      'We’re here for you. For life’s big moments, the just-because, and even when things don’t go according to plan. That’s why we’re devoted to the Edible Guarantee – our promise to give our best.',
    [TK.EDIBLE_GUARANTEE_POLICY]:
      'Your happiness is our happiness, and it’s 100% guaranteed.',
    [TK.EDIBLE_GUARANTEE_HASSLEFREE]: 'guaranteed satisfaction, hassle-free',
    [TK.EDIBLE_GUARANTEE_SATISFACTION_HEADER]: 'satisfaction',
    [TK.EDIBLE_GUARANTEE_SUPPORT_HEADER]: 'guarantee',
    [TK.EDIBLE_GUARANTEE_STREAMLINED_HEADER]: 'streamlined',
    [TK.EDIBLE_GUARANTEE_SATISFACTION]:
      'We will replace or refund your order if you aren’t 100% satisfied.',
    [TK.EDIBLE_GUARANTEE_SUPPORT]: 'Hassle-free support via email, or phone.',
    [TK.EDIBLE_GUARANTEE_STREAMLINED]:
      'We take your happiness seriously! Tell us how we did.',
    [TK.ALERT_OWN_BOX_ADDONS]: 'Please select at least 1 item',
    [TK.ALERT_PRODUCT_OPTION]: 'Please, select an option',
    [TK.ALERT_AVAILABILITY_ZIP]: 'Please enter ZIP Code to Continue',
    [TK.ALERT_AVAILABILITY_DATE]: 'Please select Date to Continue',
    [TK.ALERT_AVAILABILITY_DELIVERY_METHOD]:
      'Please select Fullfilment Type to Continue',
    [TK.ALERT_AVAILABILITY_STORE]: 'Please select the Store to Continue',
    [TK.ALERT_SUGGESTED_DATE]:
      'Please select a suggested date to continue with your order',
    [TK.ALERT_SUGGESTED_PRODUCT]:
      'Please choose from suggested products to continue with shopping',
    [TK.ALERT_DESSERT_OWN_BOX_ADDONS]:
      'Please select at least 1 item from each of the 3 options below to continue.',
    [TK.ALERT_6_COOKIE_BOX_ADDONS]:
      'Please select at least 1 cookie to continue.',
    [TK.ALERT_12_COOKIE_BOX_ADDONS]:
      'Please select at least 1 cookie from the first list below to continue.',
    [TK.PDP_TITLE]: 'Make A Selection',
    [TK.PICKUP]: 'Pickup for {{name}} on {{date}}',
    [TK.MENU_TITLE]: 'Select Recipient',
    [TK.GUIDE]:
      'You can add a new recipient below or continue shopping for {{name}}',
    [TK.NEW_RECIPIENT_SUBTITLE]: 'Different address or date',
    [TK.SWITCH_RECIPIENT]: 'Switch recipient',
    [TK.DELIVERY_FROM]:
      'Your order will be serviced from {{store}} on {{date}}',
    [TK.DELIVERY_FOR]: 'Delivery to {{name}} on {{date}}',
    [TK.PICKUP_FROM]:
      'Your order will be ready for pickup from {{store}} on {{date}} at {{time}}',
    [TK.PICKUP_FOR]: 'Pickup for {{name}} on {{date}}',
    [TK.SHIPMENT_SERVICED_ON]: 'Your order will be serviced on {{date}}',
    [TK.NEW_RECIPIENT]: 'New Recipient',
    [TK.DELIVERY]: 'Delivery',
    [TK.PICK_UP]: 'Pick Up',
    [TK.DELIVERY_DESCRIPTION]:
      'What could be sweeter than hand delivery? Treats that arrive fresh with picture-perfect presentation, of course. With Edible® delivery, that’s just standard.',
    [TK.AVAILABLE_FOR_OTHER_DATES]: 'Available for other dates',
    [TK.CHOOSE_ANOTHER_DATE]: 'Select another date for pickup',
    [TK.AVAILABLE_FOR_OTHER_PRODUCTS]: 'Available for other products',
    [TK.OTHER_PRODUCTS_AVAILABLE]:
      'We’re sorry, but this product is not available on the date you selected. Please choose another product available on your selected date.',
    [TK.DELIVERY_NOT_AVAILABLE_FOR_DATE_SUGGEST_DATES]:
      'Sorry! Delivery is unavailable for the selected date. Click to view available dates.',
    [TK.DELIVERY_NOT_AVAILABLE_FOR_DATE_SUGGEST_PRODUCTS]:
      'Sorry! Delivery is unavailable for the selected product. Click to see other products which are available for delivery for the selected date.',
    [TK.PICKUP_NOT_AVAILABLE_FOR_DATE_SUGGEST_DATES]:
      'Sorry! Pick up is unavailable for the selected date. Click to view available dates.',
    [TK.PICKUP_NOT_AVAILABLE_FOR_DATE_SUGGEST_PRODUCTS]:
      'Sorry! The product you selected is not available for in-store pick up. See if there’s something sweet you’d rather grab!',
    [TK.PICKUP_IN_STORE]: 'Pick Up In Store',
    [TK.PICKUP_DESCRIPTION]:
      "Stores nationwide for fast, convenient pickup that's always",
    [TK.FREE]: 'FREE',
    [TK.NO_APPROPRIATE_DATE]: 'Not seeing the date you’d like?',
    [TK.EXPLORE_AVAILABLE_PRODUCTS]:
      'Let’s explore available products on your selected date.',
    [TK.SEE_AVAILABLE_PRODUCTS]: 'See Available Products',
    [TK.FLEX_FREE]: 'FREE*',
    [TK.FLEX_NEXT_DAY_PRICE]: '$5.99*',
    [TK.FLEX_DATES_AVAILABLE]: 'Flex Dates Available',
    [TK.FLEX_DELIVERY_DATES]: 'Flexible with Delivery Date?',
    [TK.FLEX_DELIVERY_DATES_SUBTITLE]:
      'We offer free delivery for most next day and beyond delivery orders.',
    [TK.FLEX_DATES_AVAILABLE_SUBTITLE]:
      'We’ve found additional delivery dates you might be interested in. Happy with your selected date? Just skip this selection.',
    [TK.CHOOSE_ANOTHER_DATE]: 'Select another date for delivery',
    [TK.DISCLAIMER_MESSAGE]:
      '*Additional surcharges may incur for Delivery that exceeds 15 miles and/or for Same Day or 1 Hour Delivery orders based on the time zone of the store receiving the order. See Terms of Use for more details',
    [TK.DISCLAIMER_MESSAGE_KMS]:
      '*Additional surcharges may incur for Delivery that exceeds 24 kilometers and/or for Same Day or 1 Hour Delivery orders based on the time zone of the store receiving the order. See Terms of Use for more details',
    [TK.CURBSIDE]: 'Curbside',
    [TK.DISTANCE]: 'distance',
    [TK.KOSHER]: 'Kosher',
    [TK.MILES]: '~{{miles}} miles',
    [TK.KILOMETERS]: '~{{km}} km',
    [TK.KILOMETERS_TEXT]: 'Kilometers',
    [TK.SMOOTHIE]: 'Smoothie',
    [TK.TITLE_PICKUP]: 'Select local pickup shop',
    [TK.TITLE_DELIVERY]: 'Select shop for delivery',
    [pluralize(TK.SHOW_MORE)]: 'Show {{count}} More',
    [TK.MONDAY_FULL]: 'Monday',
    [TK.TUESDAY_FULL]: 'Tuesday',
    [TK.WEDNESDAY_FULL]: 'Wednesday',
    [TK.THURSDAY_FULL]: 'Thursday',
    [TK.FRIDAY_FULL]: 'Friday',
    [TK.SATURDAY_FULL]: 'Saturday',
    [TK.SUNDAY_FULL]: 'Sunday',
    [TK.MONDAY_SHORT]: 'Mon',
    [TK.TUESDAY_SHORT]: 'Tue',
    [TK.WEDNESDAY_SHORT]: 'Wed',
    [TK.THURSDAY_SHORT]: 'Thu',
    [TK.FRIDAY_SHORT]: 'Fri',
    [TK.SATURDAY_SHORT]: 'Sat',
    [TK.SUNDAY_SHORT]: 'Sun',
    [TK.ERROR_NO_STORES]:
      'Oops, we can not suggest you any of our store at the moment. Please select another date or change the fulfillment method.',
    [TK.ERROR_DELIVERY_CUTOFF_PASSED]:
      'Oops, the delivery cutoff time has passed for today. Please select another date.',
    [TK.ERROR_PICKUP_CUTOFF_PASSED]:
      'Oops, the pickup cutoff time has passed for today. Please select another date.',
    [TK.ERROR_PICKUP_DATE_IN_PAST]:
      'Oops, the pickup date may not be in the past. Please select another date.',
    [TK.ERROR_SAME_DAY_SHIPMENT]:
      'Oops, we currently do not offer same day shipping, please select another date.',
    [TK.ERROR_NEXT_DAY_SHIPMENT_CUTOFF_PASSED]:
      'Oops, Next Day shipment is no longer available today, please select another date.',
    [TK.ERROR_SIZE_IS_NOT_AVAILABLE]:
      "We're sorry, the option you selected is not available at this store. Please select a different size or date.",
    [TK.ERROR_STORE_IS_CLOSED]:
      "We're sorry, this store is currently closed. Please select another zip or date.",
    [TK.ERROR_PICKUP_CUTOFF_DATE]:
      "We're sorry but the store you’ve selected does not accept same day pickup orders after {{time}}. Please select a different date or another store.",
    [TK.ERROR_BEFORE_MINIMUM_PICKUP_TIME]:
      'Oops, the pickup time selected is not available. Please choose a time after {{time}} or contact the store.',
    [TK.ERROR_SERVICING_STORE_CLOSED]:
      "We're sorry but the store you’ve selected is closed on {{date}}. Please select another date.",
    [TK.ERROR_SERVICING_STORE_CANNOT_FULFILL_ORDER]:
      "We're sorry but the store you’ve selected cannot complete the order for {{date}}. Please select another date.",
    [TK.ERROR_DELIVERY_IS_NOT_AVAILABLE_FOR_STORE_ON_DATE]:
      "We're sorry, delivery is currently not available on {{date}} from the store you selected {{store}}. Please select another date.",
    [TK.ERROR_PICKUP_IS_NOT_AVAILABLE_FOR_STORE_ON_DATE]:
      "We're sorry, pick up is currently not available on {{date}} from the store you selected {{store}}. Please selected another date.",
    [TK.ERROR_NO_STORES]:
      'Oops, we can not suggest you any of our store at the moment. Please select another date or change the fulfillment method.',
    [TK.ERROR_DELIVERY_CUTOFF_PASSED]:
      'Oops, the delivery cutoff time has passed for today. Please select another date.',
    [TK.ERROR_PICKUP_CUTOFF_PASSED]:
      'Oops, the pickup cutoff time has passed for today. Please select another date.',
    [TK.ERROR_PICKUP_DATE_IN_PAST]:
      'Oops, the pickup date may not be in the past. Please select another date.',
    [TK.ERROR_SAME_DAY_SHIPMENT]:
      'Oops, we currently do not offer same day shipping, please select another date.',
    [TK.ERROR_NEXT_DAY_SHIPMENT_CUTOFF_PASSED]:
      'Oops, Next Day shipment is no longer available today, please select another date.',
    [TK.ERROR_SIZE_IS_NOT_AVAILABLE]:
      "We're sorry, the option you selected is not available at this store. Please select a different size or date.",
    [TK.ERROR_STORE_IS_CLOSED]:
      "We're sorry, this store is currently closed. Please select another zip or date.",
    [TK.ERROR_PICKUP_CUTOFF_DATE]:
      "We're sorry but the store you’ve selected does not accept same day pickup orders after {{time}}. Please select a different date or another store.",
    [TK.ERROR_BEFORE_MINIMUM_PICKUP_TIME]:
      'Oops, the pickup time selected is not available. Please choose a time after {{time}} or contact the store.',
    [TK.ERROR_SERVICING_STORE_CLOSED]:
      "We're sorry but the store you’ve selected is closed on {{date}}. Please select another date.",
    [TK.ERROR_SERVICING_STORE_CANNOT_FULFILL_ORDER]:
      "We're sorry but the store you’ve selected cannot complete the order for {{date}}. Please select another date.",
    [TK.ERROR_DELIVERY_IS_NOT_AVAILABLE_FOR_STORE_ON_DATE]:
      "We're sorry, delivery is currently not available on {{date}} from the store you selected {{store}}. Please select another date.",
    [TK.ERROR_PICKUP_IS_NOT_AVAILABLE_FOR_STORE_ON_DATE]:
      "We're sorry, pick up is currently not available on {{date}} from the store you selected {{store}}. Please selected another date.",
    [TK.SHOW_MORE_REVIEW]: 'Show {{count}} More Review',
    [pluralize(TK.SHOW_MORE_REVIEW)]: 'Show {{count}} More Reviews',
    [TK.PRODUCT_NO_REVIEW]: 'No Reviews',
    [TK.PRODUCT_REVIEW]: 'Review: {{count}}',
    [pluralize(TK.PRODUCT_REVIEW)]: 'Reviews: {{count}}',
    [TK.PRODUCT_REVIEWS]: 'Reviews',
    [TK.PRODUCT_DESCRIPTION]: 'About',
    [TK.PRODUCT_INGREDIENTS]: 'Ingredients',
    [TK.QUESTIONS]: 'Questions? Ask Away!',
    [TK.COUPONS_APPLICABLE_TITLE]: 'Coupons Not Applicable',
    [TK.ALLERGY_WARNING_TITLE]: 'Allergy Warning: ',
    [TK.DISCLAIMER_TITLE]: 'Disclaimer: ',
    [TK.SHOW_MORE_DELIVERY_STORES]: 'Show More Delivery Stores',
    [TK.WHEN]: 'When?',
    [TK.TODAY]: 'Today ({{date}})',
    [TK.TOMORROW]: 'Tomorrow ({{date}})',
    [TK.DATE_PICKER]: 'Select Date',
    [TK.UPSELLS_DECREASE_QUANTITY]: 'Decrease quantity',
    [TK.UPSELLS_INCREASE_QUANTITY]: 'Increase quantity',
    [TK.UPSELLS_REMOVE]: 'Remove',
    [TK.UPSELLS_ADD]: 'Add',
    [TK.UPSELLS_CONTINUE]: 'Continue',
    [TK.UPSELLS_SAVE_SELECTION]: 'Save selections ({{count}})',
    [TK.UPSELLS_TITLE]: 'upgrade your gift',
    [TK.UPSELLS_OPTIONS_SINGLE]: 'Single',
    [TK.UPSELLS_OPTIONS_OPTION]: 'Option',
    [TK.UPSELL_OPTIONS_STARTING_AT]: 'Options Starting At',
    [TK.UPSELL_OPTIONS_SHOW_ALL]: 'Show all',
    [TK.UPSELL_OPTIONS_EXPANDED_SINGLE]: 'Single Option',
    [TK.UPSELL_OPTIONS_EXPANDED_SELECT_ONE]: 'Select Only One Option',
    [TK.UPSELL_OPTIONS_EXPANDED_SELECT_MULTIPLE]: 'Select 1–{{count}} Options',
    [TK.UPSELL_OPTIONS_EXPANDED_SHOW_LESS]: 'Show less',
    [TK.UPSELL_OPTIONS_WARNING]:
      'Warning: We cant fetch the upsells, just go to the recipient form',
    [TK.UPSELL_OPTIONS_WARNING_DESIGN_VARY]: 'Design may vary',
    [TK.UPSELL_OPTIONS_NOTICE]:
      'Notice: There is no upsells here. You should go to the recipient form',
    [TK.UPSELL_SHOW_MORE]: 'Show More',
    [TK.UPSELL_SHOWING]: 'Showing {{shown}} of {{count}}.',
    [TK.UPSELL_SHOW]: 'Show {{shownCount}} More',
    [TK.UPSELL_OPTIONS_PRODUCT_BADGE_LABEL]: 'Your gift',
    [TK.SAVE]: 'Save',
    [TK.ADD]: 'Add',
    [TK.CANCEL]: 'Cancel',
    [TK.LETTERS_OPTION_TITLE]: 'Letter (1–5 chars)',
    [TK.LETTERS_OPTION_MODAL_TITLE]: 'Letter',
    [TK.LETTERS_OPTION_EXAMPLE]: 'Example: Birthday — add their name',
    [TK.LETTERS_OPTION_WARN]: 'Only upper case letters are allowed',
    [TK.LETTERS_INPUT_LABEL]: 'Enter Letters',
    [TK.NUMBERS_OPTION_TITLE]: 'Number (1–3 chars)',
    [TK.NUMBERS_OPTION_MODAL_TITLE]: 'Number',
    [TK.NUMBERS_OPTION_EXAMPLE]: "Example: Sweet Sixteen - add a '16'",
    [TK.NUMBERS_OPTION_WARN]: 'Only numbers are allowed',
    [TK.NUMBERS_INPUT_LABEL]: 'Enter Your Number',
    [TK.YEAR_OPTION_TITLE]: 'Year (4 chars)',
    [TK.YEAR_OPTION_MODAL_TITLE]: 'Year',
    [TK.YEAR_OPTION_EXAMPLE]:
      'Example: Class Reunion — type the graduation year (YYYY)',
    [TK.YEAR_OPTION_WARN]: 'Only numbers are allowed',
    [TK.YEAR_INPUT_LABEL]: 'Enter Year',
    [TK.EMPTY_VALUE_VALIDATION_ERROR_MESSAGE]:
      'Enter at least one character to continue',
    [TK.CENSORED_WORD_VALIDATION_ERROR_MESSAGE]:
      'This combination of letters is prohibited. Please try another combination',
    [TK.SWITCH_TO_THIS_OPTION]: 'Switch to This Option',
    [TK.MAXIMUM_3_CHARACTERS]: 'Maximum 3 characters.',
    [TK.MAXIMUM_5_CHARACTERS]: 'Maximum 5 characters.',
    [TK.ONLY_NUMBERS_ALLOWED]: 'Only numbers are allowed.',
    [TK.MUST_BE_4_CHARACTERS_LONG]: 'Must be 4 characters long.',
    [TK.ONLY_UPPERCASE_LETTERS_ALLOWED]: 'Only upper case letters are allowed.',
    [TK.PDP_RECIPIENT_NAME]: 'Recipient contact information',
    [TK.PDP_REQUIRED_FIELD]: '* Required field',
    [TK.PDP_FIRST_NAME_PLACEHOLDER]: 'First Name*',
    [TK.PDP_FIRST_NAME_TEXT]: 'The recipient is the one RECEIVING this item',
    [TK.PDP_FIRST_NAME_TEXT_PICKUP]: 'The recipient is the one PICKING UP',
    [TK.PDP_LAST_NAME_PLACEHOLDER]: 'Last Name*',
    [TK.PDP_ADDRESS_TITLE]: 'Recipient address',
    [TK.PDP_ADDRESS_SUBTITLE]:
      'Select Residential or Business for your address type, then enter details.',
    [TK.PDP_ADDRESS_TYPE_LABEL]: 'Address Type*',
    [TK.PDP_ADDRESS_TYPE_RESIDENCE_TITLE]: 'Residence',
    [TK.PDP_ADDRESS_TYPE_RESIDENCE_SUBTITLE]:
      'We deliver to residences during our regular delivery window.',
    [TK.PDP_ADDRESS_TYPE_BUSINESS_TITLE]: 'Business',
    [TK.PDP_ADDRESS_TYPE_BUSINESS_SUBTITLE]:
      'We deliver to businesses during their regular operating hours.',
    [TK.PDP_ADDRESS_TYPE_ERROR]:
      'To continue, select the address type above that matches your gift’s destination.',
    [TK.PDP_BUSINESS_NAME_LABEL]: '{{type}} Name',
    [TK.PDP_CITY_LABEL]: 'City / Town*',
    [TK.PDP_PO_BOX_NOTE]: 'We cannot deliver to P.O. Boxes.',
    [TK.PDP_STREET_ADDRESS]: 'Street Address*',
    [TK.PDP_APT_FLOOR_SUITE_BUTTON]: 'Apt/Floor/Suite',
    [TK.PDP_APT_FLOOR_SUITE_LABEL]: 'Apt/Floor/Suite',
    [TK.PDP_PICKUP_TIME]: 'Pickup Time',
    [TK.PDP_CHOOSE_OPTION]: 'Please Choose',
    [TK.PDP_BUSINESS_WARNING]:
      'We do not advise same day business deliveries to be placed after 3:00 PM as many businesses close at 5:00 PM. Your local store may contact you if you place a same day order for delivery to reschedule your order for another business day.',
    [TK.PDP_ENTER_FIRST_NAME]: 'Enter first name.',
    [TK.PDP_ENTER_LAST_NAME]: 'Enter last name.',
    [TK.PDP_ERROR_ALERT_ADD_TO_CART_HEADING]: 'Whoops a daisy!',
    [TK.PDP_ERROR_ALERT_ADD_TO_CART_BODY]:
      "We're having trouble adding this item to the cart. Please try again in a few minutes.",
    [TK.PDP_ERROR_PICKUP_IN_PAST]:
      'Oops, the pickup date may not be in the past. Please select another date.',
    [TK.PDP_ERROR_CHOOSE_SAME_DAY_PICKUP_TIME_AFTER]:
      'We’re sorry but store {{store}} does not accept same-day pickup orders after {{time}}. Please select a different date.',
    [TK.PDP_ERROR_CHOOSE_PICKUP_TIME_AFTER]:
      'Oops, the pickup time selected is not available. Please choose a time after {{time}} or contact the store.',
    [TK.ERROR_SAME_DAY_SHIPMENT]:
      'Oops, we currently do not offer same-day shipping, please select another date.',
    [TK.PDP_ERROR_NEXT_DAY_SHIPMENT_CUTOFF]:
      'Oops, the next-day shipment is no longer available today, please select another date.',
    [TK.PDP_ERROR_NO_SERVICE_OPTION]:
      'Oops, we are unable to process your selection. Please refresh the page.',
    [TK.PDP_ERROR_SERVICE_OPTION_STORE]:
      'We are sorry, the product size that you selected is not available at this store. Please select a different size or store.',
    [TK.PDP_ERROR_ORDER_DATE_IN_PAST]:
      'The order date is in the past and is no longer available. Please select another date.',
    [TK.PDP_ERROR_STORE_IS_CLOSED_ON_DATE]:
      'We’re sorry but the store you’ve selected is closed on this day. Please select another date.',
    [TK.PDP_ERROR_STORE_CANNOT_FULFILL_ON_DATE]:
      'We’re sorry but the store you’ve selected cannot complete the order on {{date}}. Please select another date.',
    [TK.ERROR_STORE_IS_CLOSED]:
      'We’re sorry but the store you’ve selected is closed on this day. Please select another date.',
    [TK.PDP_ERROR_DELIVERY_NOT_AVAILABLE_ON_DATE_FOR_STORE]:
      'We’re sorry, delivery is currently not available on {{date}} from the store you selected {{store}}. Please select another date.',
    [TK.PDP_ERROR_PICKUP_NOT_AVAILABLE_ON_DATE_FOR_STORE]:
      'We’re sorry, pick up is currently not available on {{date}} from the store you selected {{store}}. Please selected another date.',
    [TK.PDP_ERROR_UNCOMPLETED_REQUIRED_FIELDS]:
      'Please complete all required fields: {{fields}}',
    [TK.PDP_ERROR_FIRST_NAME_SPL_CHARS]: 'Enter a valid first name',
    [TK.PDP_ERROR_LAST_NAME_SPL_CHARS]: 'Enter a valid last name',
    [TK.PDP_ERROR_STREET_ADDRESS_SPL_CHARS]: 'Enter a valid street address.',
    [TK.PDP_ERROR_REQUIRED]: 'required',
    [TK.PDP_ERROR_NAME_SPL_CHARS]: 'Enter a valid name or send anonymously',
    [TK.PDP_ERROR_OCCASION_ID]: 'Select an occasion',
    [TK.PICKUP_FORM_IDENTIFICATION]: 'Identification',
    [TK.PICKUP_PHONE_LABEL]: 'Pickup Phone Number',
    [TK.PICKUP_PHONE]: 'Recipient Phone*',
    [TK.PICKUP_DATE_TIME_HEADER]: 'Date & Time',
    [TK.PICKUP_DATE_TIME_LABEL]: 'Pickup Time *',
    [TK.PICKUP_DATE_TIME_ALERT_ON]: 'Pickup On:',
    [TK.PICKUP_FORM_CURBSIDE_SWITCH_LABEL]: 'Curbside Pickup',
    [TK.PICKUP_CURBSIDE_MODEL_LABEL]: 'Vehicle Model*',
    [TK.PICKUP_CURBSIDE_COLOR_LABEL]: 'Vehicle Color*',
    [TK.PICKUP_INSTRUCTION_BUTTON]: 'Pickup Instructions',
    [TK.PICKUP_INSTRUCTIONS_HEADER]: 'Pickup Instructions',
    [TK.PICKUP_INSTRUCTIONS_LABEL]: 'Pickup Instructions (optional)',
    [TK.PICKUP_DETAILS]: 'Pickup Details',
    [TK.PICKUP_CONTACT_DETAILS]: 'We only reach out if there are complications',
    [TK.DELIVERY_INSTRUCTIONS]: 'Delivery Instructions',
    [TK.READ_OUR_DELIVERY_POLICIES]: 'Read our Delivery Policies',
    [TK.DELIVERY_INSTRUCTIONS_LABEL]:
      'Provide any details that can help us deliver your treats to the right place. Include crossroads, landmarks, info for gated communities, etc.',
    [TK.DELIVERY_INSTRUCTIONS_PLACEHOLDER]: 'Delivery Instructions',
    [TK.PICKUP_INSTRUCTIONS]: 'Pickup Instructions',
    [TK.PICKUP_INSTRUCTIONS_PLACEHOLDER]:
      'Provide any details that can help make your pickup as convenient as possible. Include pickup timeframe, accessibility requirements, etc.',
    [TK.COMPLIMENTARY_GIFT_MESSAGE]: 'Add a Сomplimentary Gift Message',
    [TK.COMPLIMENTARY_MESSAGE_TITLE]: 'Сomplimentary Gift Message',
    [TK.OR]: 'Or',
    [TK.REMOVE_GIFT_MESSAGE]: 'Remove Gift Message',
    [TK.GIFT_MESSAGE_FREE_EXPLAIN]:
      'Write a gift message, and include your name so the recipient knows who sent their gift.',
    [TK.HEARTFELT_MESSAGE]: 'Leave them a heartfelt message',
    [TK.TOP_PICK]: 'TOP PICK',
    [TK.PREMIUM_CARD]: 'Add Card',
    [TK.OPTIONAL]: 'optional',
    [TK.SELECT_NEW_CARD]: 'Select New Card',
    [TK.REMOVE_CARD]: 'Remove Card',
    [TK.GIFT_MESSAGE_FREE_HINT]:
      'Our treats will make an impression, but your card can be a keepsake they cherish for years to come.',
    [TK.ENTER_CARD_MESSAGE]: 'Complimentary Gift Message',
    [TK.PRINTIBLE_CARD_PREVIEW]: 'Card preview',
    [TK.PRINTIBLE_APP]: 'Printible App',
    [TK.PRINTIBLE_IFRAME_ERROR]:
      'Browser Support Error: Browser Does Not Support iframe.',
    [TK.PRINTIBLE_SUBTITLE]:
      'Pick a card you love, or <strong>add your own photo</strong> to <strong>your heartfelt message</strong>',
    [TK.PRINTIBLE_OLD_PRICE_TEXT]: 'Was ',
    [TK.PRINTIBLE_PRICE_SUBTEXT]: 'Only ',
    // eslint-disable-next-line no-template-curly-in-string
    [TK.PRINTIBLE_PRICE_TEXT]: '${{price}}',
    [TK.COMPLIMENTARY_CARD]: 'Complimentary card',
    [TK.PRINTIBLE_CARDS]: 'Printible cards',
    [TK.GIFT_CARD_MODAL_TITLE]: 'Leave Them A Heartfelt Message',
    [TK.GIFT_CARD_MODAL_SUB_TITLE]:
      'Remember to include your name! Your message is the only way the recipient will know who sent their gift.',
    [TK.GIFT_CARD_MODAL_GIFT_MESSAGE]: 'Make it Memorable',
    [TK.GIFT_CARD_MODAL_KEEPSAKE_CHERISH]: 'send a personalized greeting card',
    [TK.GIFT_CARD_MODAL_YEAR_COME]: 'for Years to Come',
    [TK.WRITE_FOR_ME_NAME]: 'Your Name*',
    [TK.ANANYMOUSLY_SWITCH_LABEL]: 'Send anonymously',
    [TK.WRITE_FOR_ME_NAME_NOTE]: 'Let the recipient know who sent their gift',
    [TK.WRITE_FOR_ME_CREATE_BUTTON]: 'Create My Gift Message',
    [TK.WRITE_FOR_ME_START_NEW_BUTTON]: 'Start A New Gift Message',
    [TK.GIFT_MESSAGE_TABS_LABEL]: 'Card message input type tabs',
    [TK.GIFT_MESSAGE_FIRST_TAB_LABEL]: 'Write My Own',
    [TK.GIFT_MESSAGE_SECOND_TAB_LABEL]: 'Write For Me',
    [TK.ALERT_OWN_BOX_ADDONS]: 'Please select at least 1 item',
    [TK.TITLE]: 'Make A Selection',
    [TK.AREA]: 'Zip Code',
    [TK.POSTAL_CODE]: 'Postal Code',
    [TK.STATE]: 'State',
    [TK.PROVINCE]: 'Province',
    [TK.CITY]: 'City/Town',
    [TK.CHANGE_AREA]: 'Need to change zip code?',
    [TK.CHANGE_POSTAL_CODE]: 'Need to change Postal Code?',
    [TK.CHANGE_AREA_INFO]:
      'Your delivery zip code and state are locked as per your selection. You can modify the delivery zip by selecting "Need to change zip code" option',
    [TK.CHANGE_POSTAL_CODE_INFO]:
      'Your delivery postal code and state are locked as per your selection. You can modify the delivery postal code by selecting "Need to change postal code" option',
    [TK.CART_POSTAL_CODE_INFO]:
      'Each store carries different treats. To change the zip code, you must start a new order. This ensures your treats are still available.',
    [TK.CHANGE_ZIP_TITLE]: 'change zip code',
    [TK.CHANGE_ZIP_DESCRIPTION]:
      'Each store carries different treats. Changing the zip code will restart the process from the product page so we can check that your selection is still available.',
    [TK.CHANGE_BACK_BUTTON]: 'Go Back',
    [TK.CHANGE_ZIP_BUTTON]: 'Change Zip Code',
    [TK.CHANGE_POSTAL_TITLE]: 'change postal code',
    [TK.CHANGE_POSTAL_DESCRIPTION]:
      'Each store carries different treats. Changing the postal code will restart the process from the product page so we can check that your selection is still available.',
    [TK.CHANGE_POSTAL_BUTTON]: 'Change Postal Code',
    [TK.BRAND_VALUE]: '{{brand_value}}',
    [TK.STORES_NEAR_YOU]: '{{amountOfStores}} Stores Near You',
    [TK.STORE_NEAR_YOU]: '{{amountOfStores}} Store Near You',
    [TK.LOAD_MORE_STORES_BUTTON_TEXT]: 'Load More',
    [TK.PRIVACY_POLICY]: 'Privacy Policy',
    [TK.TERMS_OF_USE]: 'Terms of Use',
    [TK.CONCERNED_ABOUT_PRIVACY]:
      'Concerned about privacy? View our policies below.',
    [TK.LOCATION_SERVICES_OFF]:
      'It looks like location services are turned off.',
    [TK.TURN_ON_LOCATION_SERVICES]:
      'To find an Edible store near you, use the search feature or the interactive map above. Or, turn on location services on your device.',
    [TK.STORE_SHOWING_AMOUNT_DISPLAY_TEXT]:
      'Showing {{amountOfStoresCurrentlyDisplayed}} of {{amountOfStoresTotal}}, Load 8 More',
    [TK.APPLY]: 'Apply',
    [TK.CLEAR_ALL_FILTERS]: 'Clear All Filters',
    [TK.ERROR_MESSAGE_OPEN_YOUR_OWN_STORE]: 'Open Your Own Store',
    [TK.ERROR_MESSAGE_WE_SHIP_TEXT]:
      'We’re sorry, we couldn’t find any stores nearby. But, we may be able to ship some of our gourmet treats to you or your loved one!',
    [TK.ERROR_MESSAGE_OH_NO]: 'Oh no!',
    [TK.ERROR_MESSAGE_DIFFERENT_SEARCH]:
      'Try a different city, state or zip to view results',
    [TK.ERROR_MESSAGE_DIFFERENT_SEARCH_AND_FILTER]:
      'Try a different city, state or zip, or change your filters to view results.',
    [TK.ERROR_MESSAGE_SEARCH_AND_FILTER_CRITERIA]:
      'We’re sorry, we couldn’t find any stores that match your criteria.',
    [TK.ERROR_MESSAGE_OPEN_STORE_TEXT]:
      'Want to be the first to open an Edible Arrangements store near you? You can own your own franchise location to deliver tasty treats to your neighborhood.',
    [TK.ERROR_MESSAGE_NEW_STORES_TEXT]:
      'New locations open all the time, so check back soon!',
    [TK.DISTANCE_FILTER_OPTION]: 'Within {{distance}} {{distanceMetric}}',
    [TK.DISTANCE_TEXT]: 'Distance',
    [TK.SERVICES_OFFERED_TEXT]: 'Services Offered',
    [TK.FILTERS_TEXT]: 'Filters',
    [TK.FIND_A_STORE]: 'Find a Store',
    [TK.MOBILE_FILTER_TEXT]: 'Filter Results',
    [TK.CUSTOM_GREETING_CARDS]: 'Custom Greeting Cards',
    [TK.STORE_FINDER_HOME]: 'Home',
    [TK.STORE_FINDER_TITLE]: 'Edible Store Locator',
    [TK.STORE_FINDER_DESCRIPTION]:
      'Visit one of over 900 locations worldwide for a free sample,exclusive offers & in-store specials!',
    [TK.STORE_FINDER_PLACEHOLDER]: 'Enter your City and State or Zip',
    [TK.STORE_FINDER_PLACEHOLDER_CA]: 'Enter City and Province or Postal Code',
    [TK.STORE_FINDER_LOCATION]: 'Location',
    [TK.STORE_FINDER_CHOOSE_FROM_LIST]: 'Choose from the list below',
    [TK.STORE_FINDER_VALIDATION_ERROR]: 'Enter a valid city and state or zip',
    [TK.STORE_FINDER_VALIDATION_ERROR_CA]:
      'Enter a valid City and Province or Postal Code',
    [TK.STORE_FINDER_BUTTON]: 'Find a Store',
    [TK.STORE_FINDER_BUTTON_LOADING_TEXT]: 'Retrieving Store(s)…',
    [TK.INPUT_ADORNMENT]: 'Enter City, State or Zip',
    [TK.INPUT_ADORNMENT_CA]: 'Enter City, Province or Postal Code',
    [TK.STORE_FINDER_FRANCHISE_HEAD]: 'Live the Sweet Life!',
    [TK.STORE_FINDER_FRANCHISE_SUBTITLE]: 'Own a Franchise',
    [TK.STORE_FINDER_FRANCHISE_DESCRIPTION]:
      'Learn about opening your own Edible storefront and join our passionate community of franchise owners across the globe.',
    [TK.LEARN_MORE_STORES]: 'Learn More',
    [TK.LOAD_MORE_STORES_ARIA_TEXT]: 'Load More Stores',
    [TK.OPEN_FRANCHISE_LEARN_MORE_BUTTON]:
      'Open Your Own Franchise Learn More Button',
    [TK.OPEN_FRANCHISE_BANNER]: 'Open Your Own Franchise Banner',
    [TK.INVALID_SEARCH_TEXT]:
      'Enter a valid city and state or zip in the field above to see results',
    [TK.COMING_SOON_STORE_TEXT]: 'New Stores Coming Soon!',
    [TK.CALL_EDIBLE_STORE]: 'Call this Edible Store',
    [TK.CONTACT_US]: 'Contact Us',
    [TK.EMAIL_EDIBLE_STORE]: 'Email this Edible Store',
    [TK.GET_DIRECTIONS]: 'Get Edible Store Directions',
    [TK.STORE_FINDER_PAGE_META_TAG_TITLE]:
      'Edible Arrangements Locations & Stores Near You',
    [TK.STORE_FINDER_PAGE_META_TAG_DESCRIPTION]:
      'Find an Edible Arrangements location near you. Your one stop of gift delivery, pick-up, in-store exclusive offers and more. Explore the stores near you here!',
    [TK.STORE_DETAILS_BREADCRUMB]:
      'Edible Store {{storeNumber}} - {{city}}, {{state}}',
    [TK.STORE_ADDRESS1]: 'Edible® {{storeNumber}}, {{address1}}',
    [TK.STORE_ADDRESS2]: 'Edible® {{storeNumber}}, {{address1}}, {{address2}}',
    [TK.STORE_BANNER_BODY]:
      'Shop gifts and treats for someone else (or yourself!) at Edible Arrangements {{city}}, {{state}} - {{address1}}.',
    [TK.STORE_BANNER_BUTTON]: 'Shop Now',
    [TK.FRANCHISE_BANNER_BUTTON_LABEL]:
      'Open Your Own Franchise Banner Button Text',
    [TK.STORE_BANNER_TITLE]: 'Come see us!',
    [TK.STORE_BANNER_ALT_TEXT]: 'Visit and shop our Edible Store',
    [TK.FRANCHISE_BANNER_ALT_TEXT]:
      'Learn more about Owning an Edible Franchise',
    [TK.FRANCHISE_BANNER_TITLE]: 'Live the Sweet Life!',
    [TK.FRANCHISE_BANNER_SUB_TITLE]: 'Own a Franchise',
    [TK.FRANCHISE_BANNER_SUB_TITLE_LABEL]:
      'Open Your Own Franchise Banner Subtitle',
    [TK.FRANCHISE_BANNER_TEXT_CONTAINER]:
      'Open Your Own Franchise Banner Text Container',
    [TK.FRANCHISE_BANNER_ARIA_LABEL]: 'Open Your Own Franchise Banner',
    [TK.FRANCHISE_BANNER_ARIA_TITLE_LABEL]:
      'Open Your Own Franchise Banner Title',
    [TK.FRANCHISE_BANNER_ARIA_BODY_LABEL]:
      'Open Your Own Franchise Banner Body',
    [TK.FRANCHISE_BANNER_BODY]:
      'Learn about opening your own Edible storefront and join our passionate community of franchise owners across the globe.',
    [TK.FRANCHISE_BANNER_BUTTON]: 'Learn More',
    [TK.SERVICED_AREAS_TITLE]: 'Service Areas',
    [TK.ABOUT_STORE_TITLE]: 'About Our Store',
    [TK.ABOUT_STORE_ONE]:
      "Edible Arrangements® {{storeNumber}} in {{city}}, {{state}} first opened in {{opening}}. Ever since, we’ve been helping people in our local community celebrate all kinds of occasions – big and small. Our fruit arrangements and gifts are always freshly-crafted using fruit that's grown and picked to our Fruit Expert® standards. And, we have the best variety of gourmet chocolate Dipped Fruit™, too! Visit us any time for a FREE sample!",
    [TK.ABOUT_STORE_TWO]:
      'Did you know we also have fresh fruit treats you can enjoy on the go? Stop by today to grab a fresh fruit salad, Edible® Donut, chocolate Dipped Fruit™ Cone, or any one of our other delicious fresh fruit snacks! They’re perfect for picnics, lunch break snacks, and even as an afternoon pick-me-up!',
    [TK.ABOUT_STORE_THREE]:
      'If you need a birthday gift, wedding favors, a holiday centerpiece, or even a little something just because, give us a call at <phone>{{phoneNumber}}</phone>. We can’t wait to meet you!',
    [TK.SOCIAL_MEDIA_TITLE]: "Let's Connect!",
    [TK.SOCIAL_MEDIA_BODY]:
      'Follow this store on social media for exclusive promotions!',
    [TK.SOCIAL_FACEBOOK_ARIA_LABEL]: 'Follow Edible Store on Facebook',
    [TK.SOCIAL_INSTAGRAM_ARIA_LABEL]: 'Follow Edible Store on Facebook',
    [TK.SOCIAL_PINTEREST_ARIA_LABEL]: 'Follow Edible Store on Pinterest',
    [TK.SOCIAL_TWITTER_ARIA_LABEL]: 'Follow Edible Store on Twitter',
    [TK.SOCIAL_YOUTUBE_ARIA_LABEL]: 'Follow Edible Store on YouTube',
    [TK.CATEGORIES_TITLE]: "We Think You'll Like These Popular Gifts",
    [TK.DISTANCE_AWAY]: '~{{distance}} {{distanceMetric}} Away',
    [TK.DISTANCE_METRIC]: '~{{distance}} {{distanceMetric}}',
    [TK.POPULAR_GIFT]: 'Popular Gift Section',
    [TK.BLOG_CAROUSEL]: 'Blog Carousel Display',
    [TK.STORE_DETAILS_ADDRESS1]:
      'Edible Arrangements {{city}}, {{state}} - {{address1}}',
    [TK.STORE_DETAILS_ADDRESS2]:
      'Edible Arrangements {{city}}, {{state}} - {{address1}} {{address2}}',
    [TK.STORE_DETAILS_META_TITLE]:
      'Edible Arrangements {{city}}, {{state}}: {{address1}} {{address2}}',
    [TK.STORE_DETAILS_META_SCHEMA_DESCRIPTION]:
      "Edible Arrangements® {{storeNumber}} in {{city}}, {{state}}  first opened in  {{openingDate}}. Ever since, we've been helping people in our local community celebrate all kinds of occasions - big and small. Our fruit arrangements and gifts are always freshly-crafted using fruit that's grown and picked to our Fruit Expert® standards. And, we have the best variety of gourmet chocolate Dipped Fruit™, too! (Visit us any time for a FREE sample!)  Did you know we also have fresh fruit treats you can enjoy on the go? Stop by today to grab a fresh fruit smoothie, Froyo Fruit Shake™, Edible® Donut, chocolate Dipped Fruit™ Cone, or any one of our other delicious fresh fruit snacks! They're perfect for picnics, lunch break snacks, and even as an afternoon pick-me-up!If you need a birthday gift, wedding favors, a holiday centerpiece, or even a little something just because, give us a call at {{storePhoneNumber}}. We can't wait to meet you!",
    [TK.STORE_DETAILS_META_DESCRIPTION]:
      'Grab a healthy delicious fruit smoothie, chocolate dipped fruit treat, donut or even fresh fruit salad at the Edible Arrangements store in {{city}} today!',
    [TK.STORE_DETAILS_BREADCRUMB]:
      'Edible Store {{storeNumber}} - {{city}}, {{state}}',
    [TK.STORE_ADDRESS1]: 'Edible® {{storeNumber}}, {{address1}}',
    [TK.STORE_ADDRESS2]: 'Edible® {{storeNumber}}, {{address1}}, {{address2}}',
    [TK.STORE_BANNER_BODY]:
      'Shop gifts and treats for someone else (or yourself!) at Edible Arrangements {{city}}, {{state}} - {{address1}}.',
    [TK.STORE_BANNER_BUTTON]: 'Shop Now',
    [TK.FRANCHISE_BANNER_BUTTON_LABEL]:
      'Open Your Own Franchise Banner Button Text',
    [TK.STORE_BANNER_TITLE]: 'Come see us!',
    [TK.STORE_BANNER_ALT_TEXT]: 'Visit and shop our Edible Store',
    [TK.FRANCHISE_BANNER_ALT_TEXT]:
      'Learn more about Owning an Edible Franchise',
    [TK.FRANCHISE_BANNER_SUB_TITLE]: 'Own a Franchise',
    [TK.FRANCHISE_BANNER_SUB_TITLE_LABEL]:
      'Open Your Own Franchise Banner Subtitle',
    [TK.FRANCHISE_BANNER_TEXT_CONTAINER]:
      'Open Your Own Franchise Banner Text Container',
    [TK.FRANCHISE_BANNER_ARIA_LABEL]: 'Open Your Own Franchise Banner',
    [TK.FRANCHISE_BANNER_ARIA_TITLE_LABEL]:
      'Open Your Own Franchise Banner Title',
    [TK.FRANCHISE_BANNER_ARIA_BODY_LABEL]:
      'Open Your Own Franchise Banner Body',
    [TK.FRANCHISE_BANNER_BODY]:
      'Learn about opening your own Edible storefront and join our passionate community of franchise owners across the globe.',
    [TK.DIRECTIONS]: 'Get Directions',
    [TK.STORE_COMING_SOON_ADDRESS]: 'Edible® {{storeNumber}}, {{address1}}',
    [TK.STORE_BANNER_COMING_SOON_BODY]:
      'Follow Edible Arrangements {{city}}, {{state}} - {{address1}} for early access to exciting offers and exclusive treats.',
    [TK.STORE_BANNER_COMING_SOON_BUTTON]: 'Shop Now',
    [TK.STORE_BANNER_COMING_SOON_TITLE]: 'Opening Soon!',
    [TK.STORE_BANNER_COMING_SOON_ALT_TEXT]: 'Opening Soon Store Banner.',
    [TK.FRANCHISE_BANNER_STORE_COMING_SOON_ALT_TEXT]:
      'Learn more about Owning an Edible Franchise',
    [TK.FRANCHISE_BANNER_STORE_COMING_SOON_TITLE]: 'Live the Sweet Life!',
    [TK.FRANCHISE_BANNER_STORE_COMING_SOON_SUB_TITLE]: 'Own a Franchise',
    [TK.FRANCHISE_BANNER_STORE_COMING_SOON_BODY]:
      'Learn about opening your own Edible storefront and join our passionate community of franchise owners across the globe.',
    [TK.FRANCHISE_BANNER_STORE_COMING_SOON_BUTTON]: 'Learn More',
    [TK.COMING_SOON_TEXT]: 'This Store is Opening Soon!',
    [TK.STORE_COMING_SOON_ABOUT_STORE_TITLE]: 'About Our Store',
    [TK.STORE_COMING_SOON_ABOUT_STORE_ONE]: `Edible Arrangements® {{storeNum}} in {{city}}, {{state}} will be opening soon to Make Life a Little Sweeter® for our local community!`,
    [TK.STORE_COMING_SOON_ABOUT_STORE_TWO]: `Our fruit arrangements and gifts are always made fresh daily, using fruit that's grown and picked to our Fruit Expert® standards. And, we have the best variety of gourmet chocolate dipped fruit, too! Try our legendary chocolate dipped strawberries, apples, pineapple, bananas, oranges and pears`,
    [TK.STORE_COMING_SOON_ABOUT_STORE_THREE]: `Here, you'll truly find oly the freshest fruit gifts. When we open, stop by and see why ours is considered The Best Dipped Fruit, Period®.`,
    [TK.STORE_COMING_SOON_ABOUT_STORE_FOUR]: `We'll be opening our doors to the public, soon. Once we do, we can't wait to help you choose the perfect fruit bouquet or dipped fruit box for your special occasion! So stop by soon if you need a birthday gift, wedding favors, a holiday centerpiece, or even a little something just because.`,
    [TK.SOCIAL_MEDIA_TITLE]: "Let's Connect!",
    [TK.SOCIAL_MEDIA_BODY]:
      'Follow this store on social media for exclusive promotions!',
    [TK.STORE_COMING_SOON_SOCIAL_FACEBOOK_ARIA_LABEL]:
      'Follow Edible Store on Facebook',
    [TK.STORE_COMING_SOON_SOCIAL_INSTAGRAM_ARIA_LABEL]:
      'Follow Edible Store on Instagram',
    [TK.STORE_COMING_SOON_SOCIAL_PINTEREST_ARIA_LABEL]:
      'Follow Edible Store on Pinterest',
    [TK.STORE_COMING_SOON_SOCIAL_TWITTER_ARIA_LABEL]:
      'Follow Edible Store on Twitter',
    [TK.STORE_COMING_SOON_SOCIAL_YOUTUBE_ARIA_LABEL]:
      'Follow Edible Store on YouTube',
    [TK.STORE_COMING_SOON_CATEGORIES_TITLE]:
      "We Think You'll Like These Popular Gifts",
    [TK.STORE_COMING_SOON_DISTANCE_AWAY]:
      '~{{distance}} {{distanceMetric}} Away',
    [TK.STORE_COMING_SOON_DETAILS_NO_ADDRESS]:
      'Edible Arrangements {{city}}, {{state}}',
    [TK.STORE_COMING_SOON_DETAILS_ADDRESS1]:
      'Edible Arrangements {{city}}, {{state}} - {{address1}}',
    [TK.STORE_COMING_SOON_DETAILS_ADDRESS2]:
      'Edible Arrangements {{city}}, {{state}} - {{address1}} {{address2}}',
    [TK.STORE_COMING_SOON_DETAILS_META_TITLE]:
      'Edible Arrangements {{city}}, {{state}}: {{address1}} {{address2}}',
    [TK.STORE_COMING_SOON_DETAILS_META_SCHEMA_DESCRIPTION]:
      "Edible Arrangements® {{storeNumber}} in {{city}}, {{state}}  first opened in  {{openingDate}}. Ever since, we've been helping people in our local community celebrate all kinds of occasions - big and small. Our fruit arrangements and gifts are always freshly-crafted using fruit that's grown and picked to our Fruit Expert® standards. And, we have the best variety of gourmet chocolate Dipped Fruit™, too! (Visit us any time for a FREE sample!)  Did you know we also have fresh fruit treats you can enjoy on the go? Stop by today to grab a fresh fruit smoothie, Froyo Fruit Shake™, Edible® Donut, chocolate Dipped Fruit™ Cone, or any one of our other delicious fresh fruit snacks! They're perfect for picnics, lunch break snacks, and even as an afternoon pick-me-up!If you need a birthday gift, wedding favors, a holiday centerpiece, or even a little something just because, give us a call at {{storePhoneNumber}}. We can't wait to meet you!",
    [TK.STORE_COMING_SOON_DETAILS_META_DESCRIPTION]:
      'Grab a healthy delicious fruit smoothie, chocolate dipped fruit treat, donut or even fresh fruit salad at the Edible Arrangements store in {{city}} today!',
    [TK.SUBSCRIPTION_TITLE]: 'Be Notified When This Store Opens!',
    [TK.SUBSCRIPTION_SUBTITLE]:
      'Provide your email address for store updates and special promotions.',
    [TK.REDIRECT_SECTION_TITLE]: 'Need to Order Your Treats Today?',
    [TK.REDIRECT_SECTION_SUBTITLE]:
      'Search for an available store in your area and order online for pickup or delivery.',
    [TK.FIND_STORE_BUTTON]: 'Find a Store',
    [TK.SUBSCRIPTION_FOOTER_DIALOG_TITLE]: 'Sweet!',
    [TK.SUBSCRIPTION_FOOTER_DIALOG_SUBTITLE]: 'Thanks for signing up.',
    [TK.SUBSCRIPTION_FOOTER_DIALOG_INFORM_EMAIL]:
      'Check your inbox for your first email from us soon!',
    [TK.SUBSCRIPTION_FOOTER_DIALOG_INFORM_TEXT]:
      'Check your messages for your first text from us soon!',
    [TK.SUBSCRIPTION_FOOTER_DIALOG_SOCIAL_LINKS_TITLE]:
      'Make sure you connect with us here, too:',
    [TK.SUBSCRIPTION_EMAIL_DIALOG_FORM_STEP_BACKGROUND_ALT]:
      'Email subscription form step background',
    [TK.SUBSCRIPTION_EMAIL_DIALOG_SUBMIT_SUCCESS_STEP_BACKGROUND_ALT]:
      'Email subscription submit success step background',
    [TK.SUBSCRIPTION_FACEBOOK]: 'facebook',
    [TK.SUBSCRIPTION_TWITTER]: 'twitter',
    [TK.SUBSCRIPTION_INSTAGRAM]: 'instagram',
    [TK.SUBSCRIPTION_LINKEDIN]: 'linkedin',
    [TK.SUBSCRIPTION_YOUTUBE]: 'youtube',
    [TK.SUBSCRIPTION_EMAIL_FORM_ERROR]: 'Enter a valid email address',
    [TK.SUBSCRIPTION_EMAIL_FORM_PLACEHOLDER]: 'yourname@email.com',
    [TK.POPULAR_GIFTS]: 'Popular Gifts',
    [TK.TODAYS_STORE_HOURS]: `Today's Store Hours`,
    [TK.HOURS]: `Hours`,
    [TK.LIMITED_LINES_HELPER_TEXT]:
      'We have limited space for printing, so we have to limit the number of lines available as well. Lines here do not represent the real amount of lines, pay attention to the indicators.',
    [TK.FIELD_REQUIRED_HELPER_TEXT]: 'This is required field',
    [TK.LINES_LIMIT_EXCEEDED]: 'Line limit reached',
    [TK.TYPING_LAST_LINE]: 'You are in the last line',
    [TK.REACHING_LAST_LINE]: 'Reaching limit',
    [TK.SPL_CHARS_ERROR]: 'Characters (<>) are not allowed',
    [TK.EMOJIS_ENTERED]: 'Emojis are not allowed',
    [TK.REDUCE_MESSAGE_LENGTH_HELPER_TEXT]: 'Reduce the length of your message',
    [TK.ORDER_CONFIRMATION_PAGE_THANK_YOU_MSG]: 'Thank You for Your Order!',
    [TK.ORDER_CONFIRMATION_PAGE_ORDER_DETAILS_TITLE]: 'order details',
    [TK.ORDER_CONFIRMATION_PAGE_CONFIRM_MAIL]:
      'You should receive a confirmation email at',
    [TK.ORDER_CONFIRMATION_PAGE_CONFIRM_MSG]: `shortly. If you don’t receive an email, re-send it using the button below.`,
    [TK.ORDER_CONFIRMATION_PAGE_PRINT_CONFIRMATION]: 'Print Confirmation',
    [TK.ORDER_CONFIRMATION_PAGE_PRINT_ORDER_DETAILS]: 'Print Order Details',
    [TK.ORDER_CONFIRMATION_PAGE_RESEND_CONFIRMATION]: 'Re-send Confirmation',
    [TK.ORDER_CONFIRMATION_PAGE_RECIPIENT_IMG]: 'Recipient Image',
    [TK.ORDER_CONFIRMATION_PAGE_ORDER_TXT]: 'In This Order',
    [TK.ORDER_CONFIRMATION_PAGE_CREDIT_CARD_IMAGE]: 'Credit Card Image',
    [TK.ORDER_CONFIRMATION_PAGE_CREATE_ACCOUNT]: 'Create Account',
    [TK.ORDER_CONFIRMATION_PAGE_VIEW_REWARDS]: 'View Your Rewards',
    [TK.ORDER_CONFIRMATION_PAGE_TRACK_ORDER]: 'Track Order',
    [TK.ORDER_CONFIRMATION_PAGE_TRACK_ORDER_IMG]: 'Track Order Image',
    [TK.ORDER_CONFIRMATION_PAGE_HELP_GREY_ICON]: 'Help_Grey_Icon',
    [TK.ORDER_CONFIRMATION_PAGE_DISCLAIMER_TEXT_LINE1]:
      'Unless specified, product containers may vary.',
    [TK.ORDER_CONFIRMATION_PAGE_DISCLAIMER_TEXT_LINE2]:
      'For flower arrangements, floral type and colors may vary.',
    [TK.ORDER_CONFIRMATION_PAGE_BILLING_INFO_TITLE]: 'Billing Info',
    [TK.ORDER_CONFIRMATION_PAGE_PICKUP_INFO_TITLE]: 'Pickup Information',
    [TK.ORDER_CONFIRMATION_PAGE_PICKUP_IN_STORE]: 'Pickup In-Store',
    [TK.ORDER_CONFIRMATION_PAGE_CURB_PICKUP]: 'Curbside Pickup',
    [TK.ORDER_CONFIRMATION_PAGE_PICKUP_INFO_METHOD]: 'Pickup Method',
    [TK.ORDER_CONFIRMATION_PAGE_PICKUP_INFO_DATE]: 'Pickup Date & Time',
    [TK.ORDER_CONFIRMATION_PAGE_PICKUP_INFO_INSTRUCTION]: 'Pickup Instructions',
    [TK.ORDER_CONFIRMATION_PAGE_PICKUP_INFO_NAME]: 'Name On Order',
    [TK.ORDER_CONFIRMATION_PAGE_PICKUP_INFO_FROM]: 'Pickup From',
    [TK.ORDER_CONFIRMATION_PAGE_DELIVERY_TITLE]: 'Delivery Information',
    [TK.ORDER_CONFIRMATION_PAGE_SHIPMENT_TITLE]: 'Shipment Information',
    [TK.ORDER_CONFIRMATION_PAGE_DELIVERY_POLICY]: 'View our Delivery Policies',
    [TK.ORDER_CONFIRMATION_PAGE_SHIPMENT_POLICY]: 'View our Shipping Policies',
    [TK.ORDER_CONFIRMATION_PAGE_DELIVERY_METHOD_NAME]: 'Delivery Method',
    [TK.ORDER_CONFIRMATION_PAGE_DELIVERY_METHOD]: 'Hand Delivery',
    [TK.ORDER_CONFIRMATION_PAGE_ONE_HOUR_DELIVERY_METHOD]: 'One-Hour Delivery',
    [TK.ORDER_CONFIRMATION_PAGE_SHIPMENT_METHOD]: 'FedEx',
    [TK.ORDER_CONFIRMATION_PAGE_MORE_INFO]: 'For more info',
    [TK.ORDER_CONFIRMATION_PAGE_MORE_INFORMATION]: 'For more information',
    [TK.ORDER_CONFIRMATION_PAGE_SHIPMENT_INFO]: 'contact Customer Service at',
    [TK.ORDER_CONFIRMATION_PAGE_CUSTOMER_CARE]: '678-992-2390',
    [TK.ORDER_CONFIRMATION_PAGE_DELIVERY_INFO_ON]: 'Delivering On',
    [TK.ORDER_CONFIRMATION_PAGE_DELIVERY_INFO_INSTRUCTION]:
      'Delivery Instructions',
    [TK.ORDER_CONFIRMATION_PAGE_DELIVERY_INFO_TO]: 'Delivering To',
    [TK.ORDER_CONFIRMATION_PAGE_DELIVERY_INFO_FROM]: 'Delivering From',
    [TK.ORDER_CONFIRMATION_PAGE_AMAZON_PAY_IMAGE]: 'Amazon Pay Image',
    [TK.ORDER_CONFIRMATION_PAGE_APPLE_PAY_IMAGE]: 'Apple Pay Image',
    [TK.ORDER_CONFIRMATION_PAGE_GOOGLE_PAY_IMAGE]: 'Google Pay Image',
    [TK.ORDER_CONFIRMATION_PAGE_LINK_IMAGE]: 'LINK Image',
    [TK.ORDER_CONFIRMATION_PAGE_PAID_BY]: 'Paid By Apple pay',
    [TK.ORDER_CONFIRMATION_PAGE_PAID_BY_GPAY]: 'Paid By Google pay',
    [TK.ORDER_CONFIRMATION_PAGE_PAID_BY_LINK]: 'Paid By Link',
    [TK.ORDER_CONFIRMATION_PAGE_TRANSACTION_ID]: 'Transaction ID: ',
    [TK.ORDER_CONFIRMATION_PAGE_NEED_MORE_SHOPPING]:
      'Need to Do More Shopping?',
    [TK.ORDER_CONFIRMATION_PAGE_PAYMENT_ID]: 'PAYMENT ID: ',
    [TK.ORDER_CONFIRMATION_PAGE_VENMO_IMAGE]: 'Venmo Image',
    [TK.ORDER_CONFIRMATION_PAGE_PAYPAL_IMAGE]: 'Paypal Image',
    [TK.ORDER_CONFIRMATION_PAGE_PRODUCT_IMAGE]: 'product image',
    [TK.ORDER_CONFIRMATION_PAGE_STRAWBERRY]: 'STRAWBERRY',
    [TK.ORDER_CONFIRMATION_PAGE_CHECK_ICON]: 'check icon',
    [TK.ORDER_CONFIRMATION_PAGE_NO_INSTRUCTIONS]: 'NO INSTRUCTIONS',
    [TK.ORDER_CONFIRMATION_PAGE_TERMS_TEXT]: 'Terms and Conditions',
    [TK.ORDER_CONFIRMATION_PAGE_LIST_TEXT1]:
      'With your account, you have access to exclusive rewards.',
    [TK.ORDER_CONFIRMATION_PAGE_LIST_TEXT2]: 'See what you’ll earn next!',
    [TK.ORDER_CONFIRMATION_PAGE_ACC_TXT]: 'Why Not Create an Account?',
    [TK.ORDER_CONFIRMATION_PAGE_THANKS_MSG]:
      'Thank You for Being a Rewards Member!',
    [TK.ORDER_CONFIRMATION_PAGE_ENJOY_MSG]: 'You’ll Enjoy',
    [TK.ORDER_CONFIRMATION_PAGE_TERMS_MSG]: 'See Terms of Use',
    [TK.ORDER_CONFIRMATION_PAGE_ORDERS_MSG]: '3 more orders',
    [TK.ORDER_CONFIRMATION_PAGE_FREE_TXT]: 'FREE',
    [TK.ORDER_CONFIRMATION_PAGE_FRUIT_TXT]:
      '6-count Chocolate Dipped Fruit Box',
    [TK.ORDER_CONFIRMATION_PAGE_GIFT_MSG]: 'Complimentary Gift Message',
    [TK.ORDER_CONFIRMATION_PAGE_GIFT_TXT]: 'Gift Message:',
    [TK.ORDER_CONFIRMATION_PAGE_GREETING_TXT]: 'Premium Greeting Card',
    [TK.ORDER_CONFIRMATION_PAGE_EMAIL_TXT]:
      'Your email confirmation was re-sent and should arrive in your inbox shortly. If you can’t find it, please check your spam folder.',
    [TK.ORDER_CONFIRMATION_PAGE_ACC_LIST_TEXT1]: '$5 off with sign up',
    [TK.ORDER_CONFIRMATION_PAGE_ACC_LIST_TEXT2]: 'Free gifts',
    [TK.ORDER_CONFIRMATION_PAGE_ACC_LIST_TEXT3]: 'Fast checkout',
    [TK.ORDER_CONFIRMATION_PAGE_ACC_LIST_TEXT4]: 'Gift reminders',
    [TK.ORDER_CONFIRMATION_PAGE_ACC_LIST_TEXT5]: 'In-store offers',
    [TK.ORDER_CONFIRMATION_PAGE_ACC_LIST_TEXT6]: 'Birthday rewards',
    [TK.ORDER_CONFIRMATION_PAGE_ACC_LIST_TEXT7]: 'and more',
    [TK.ORDER_CONFIRMATION_PAGE_SPL_TXT]: 'You Made it Special With',
    [TK.ORDER_CONFIRMATION_PAGE_GREETING_MSG]:
      'Your premium greeting card includes your chosen card front and personal gift message. It will be printed by the selected Edible store and placed in an envelope alongside your order.',
    [TK.ORDER_CONFIRMATION_PAGE_NEW_ORDER]: 'Start New Order',
    [TK.ORDER_CONFIRMATION_PAGE_KEEP_SHOPPING_TITLE]: 'Want to Keep Shopping?',
    [TK.ORDER_CONFIRMATION_PAGE_UPGRADE_TEXT]: 'You Selected These Upgrades',
    [TK.ORDER_CONFIRMATION_PAGE_CYOA_TITLE1]: 'Box with',
    [TK.ORDER_CONFIRMATION_PAGE_CYOA_TITLE2]: 'fruit/topping combinations',
    [TK.ORDER_CONFIRMATION_PAGE_CYODB_TITLE]:
      'Board with baked goods and dipped fruit/berries',
    [TK.ORDER_CONFIRMATION_PAGE_CYODB_NAME]: 'Create Your Own Desserts Board',
    [TK.ORDER_CONFIRMATION_PAGE_CYO_6COOKIEBOX_TITLE]:
      'Box with up to 3 cookie combinations',
    [TK.ORDER_CONFIRMATION_PAGE_CYO_12COOKIEBOX_TITLE]:
      'Box with up to 6 cookie combinations',
    [TK.ORDER_CONFIRMATION_PAGE_CYO_6COOKIEBOX_NAME]:
      'Create Your Own Cookie Box - 6 Cookies',
    [TK.ORDER_CONFIRMATION_PAGE_CYO_12COOKIEBOX_NAME]:
      'Create Your Own Cookie Box - 12 Cookies',
    [TK.ORDER_CONFIRMATION_PAGE_CYO_DESSERT_NAME]:
      'Create Your Own Desserts Box - One Size',
    [TK.ORDER_CONFIRMATION_PAGE_CYO_DIPPEDFRUIT_NAME]:
      'Create Your Own Dipped Fruit Box',
    [TK.ORDER_CONFIRMATION_PAGE_PACKAGE_TITLE1]: 'Bundle containing',
    [TK.ORDER_CONFIRMATION_PAGE_PACKAGE_TITLE2]: 'special treats',
    [TK.ORDER_CONFIRMATION_PAGE_ORDER_HISTORY]: 'Order History',
    [TK.ORDER_CONFIRMATION_PAGE_SALE_TITLE]: 'Total Charged:',
    [TK.ORDER_CONFIRMATION_PAGE_ORDER_NUMBER]: 'Order Number:',
    [TK.ORDER_CONFIRMATION_PAGE_SALE_ITEM_COUNT]: '{{Number}} Item',
    [TK.ORDER_CONFIRMATION_PAGE_SALE_QUANTITY]: '{{#}} x',
    [TK.ORDER_CONFIRMATION_PAGE_SALE_ADDON_QUANTITY]: 'x{{#}}',
    // eslint-disable-next-line no-template-curly-in-string
    [TK.ORDER_CONFIRMATION_PAGE_SALE_PRICE]: '${{Price}}',
    [TK.ORDER_CONFIRMATION_PAGE_SALE_SUMMARY]: 'Order Summary',
    [TK.ORDER_CONFIRMATION_PAGE_SALE_DELIVERY_FEE]: 'Delivery Fees',
    [TK.ORDER_CONFIRMATION_PAGE_SALE_SHIPMENT_CHARGE]: 'Shipment Charge',
    [TK.ORDER_CONFIRMATION_PAGE_SALE_SERVICE_FEE]: 'Service Fee:',
    [TK.ORDER_CONFIRMATION_PAGE_SALE_SUBTOTAL]: 'Subtotal',
    [TK.ORDER_CONFIRMATION_PAGE_SALE_TOTAL]: 'Order Total',
    [TK.ORDER_CONFIRMATION_PAGE_COUPON_CODE]: 'Coupon Code ({{CouponCode}})',
    [TK.ORDER_CONFIRMATION_PAGE_RECIPIENT]: 'Recipient {{RecipientNumber}}',
    [TK.ORDER_CONFIRMATION_PAGE_TRANSACTION_ID]: 'Transaction ID:',
    [TK.ORDER_CONFIRMATION_CONTINUE_SHOPPING]: 'Continue Shopping',
    [TK.ORDER_CONFIRMATION_RECIPIENT_TEXT]: 'Recipient',
    [TK.ORDER_CONFIRMATION_RECIPIENTS_TEXT]: 'Recipients',
    [TK.ORDER_CONFIRMATION_PAGE_PAYMENT_INFO]: 'Payment Information',
    [TK.ORDER_CONFIRMATION_PAGE_PAYMENT_METHOD]: 'Payment Method',
    [TK.ORDER_CONFIRMATION_PAGE_PAYMENT_CREDIT]: 'Credit Card',
    [TK.CART_ITEM_COUNT_MULTI]: 'Cart: {{productCount}} Items',
    [TK.CART_ITEM_COUNT_SINGLE]: 'Cart: {{productCount}} Item',
    [TK.RECIPIENT_COUNT_MULTI]: '{{recipientCount}} Recipients',
    [TK.RECIPIENT_COUNT_SINGLE]: '{{recipientCount}} Recipient',
    [TK.CONTINUE_SHOPPING]: 'Continue Shopping',
    [TK.RETURN_TO_CART]: 'Return to Cart',
    [TK.CHECKOUT_NOW]: 'Checkout Now',
    [TK.RECIPIENT]: 'Recipient {{recipientNumber}}',
    [TK.RECIPIENT_VIEW_EDIT_BUTTON]: 'View/Edit',
    [TK.RECIPIENT_VIEW_EDIT_HIDE_BUTTON]: 'Hide',
    [TK.FREE_DELIVERY_COMPLETED]: 'Congrats! You’ve unlocked FREE Delivery!',
    [TK.FREE_DELIVERY_REMAINING]:
      // eslint-disable-next-line no-template-curly-in-string
      '${{amount}} away from FREE Next-Day & Beyond Delivery!',
    [TK.FREE_DELIVERY_COMPLETED_MOBILE]:
      'Congrats! You’ve unlocked FREE Delivery!',
    [TK.CART_TITLE]: 'Order Summary',
    [TK.TOTAL]: 'Order Total:',
    [TK.CART_TOTAL]: 'Cart Total:',
    [TK.SUBTOTAL]: 'Subtotal:',
    [TK.DELIVERY_FEE]: 'Delivery Fees:',
    [TK.DISTANCE_FEE]: 'Distance Surcharge:',
    [TK.SHIPMENT_CHARGE]: 'Shipment Charge:',
    [TK.DISCOUNT_TOTAL]: 'Discount Total:',
    [TK.STATE_TAX]: 'State Sales Tax',
    [TK.LOCAL_TAX]: 'Local Sales Tax',
    [TK.PROMO]: 'Promo Code:',
    [TK.DELIVERY_POLICY]: 'View our Delivery Policies',
    [TK.SHIPMENT_POLICY]: 'View our Shipping Policies',
    [TK.EDIT_DELIVERY_INFO]: 'Edit Delivery Info',
    [TK.EDIT_DELIVERY_TITLE]: 'Delivering To',
    [TK.RECIPIENT_TOTAL]: 'Recipient {{number}} Order Total:',
    [TK.MADE_IT_SPECIAL]: 'You Made it Special With:',
    [TK.MAKE_IT_EXTRA_SPECIAL]: 'Make This Gift Extra Special',
    [TK.GIFT_TXT]: 'Gift Message:',
    [TK.GREETING_TXT]: 'Premium Greeting Card',
    [TK.SAVE_EDIT_TEXT]: 'SAVE',
    [TK.CANCEL_EDIT_TEXT]: 'CANCEL',
    [TK.GIFT_MESSAGE_LABEL]: 'Gift Message',
    [TK.HELP_GREY_ICON]: 'Help_Grey_Icon',
    [TK.GREETING_MSG]:
      'Your premium greeting card includes your chosen card front and personal gift message. It will be printed by the selected Edible store and placed in an envelope alongside your order.',
    [TK.UPGRADE_TEXT]: 'You selected these upgrades',
    [TK.CYOA_TITLE1]: 'Box with',
    [TK.CYOA_TITLE2]: 'fruit/topping combinations',
    [TK.CYODB_TITLE]: 'Board with baked goods and dipped fruit/berries',
    [TK.CYODB_NAME]: 'Create Your Own Desserts Board',
    [TK.CYO_DESSERT_NAME]: 'Create Your Own Desserts Box - One Size',
    [TK.CYO_DIPPEDFRUIT_NAME]: 'Create Your Own Dipped Fruit Box',
    [TK.CYO_6COOKIEBOX_TITLE]: 'Box with up to 3 cookie combinations',
    [TK.CYO_12COOKIEBOX_TITLE]: 'Box with up to 6 cookie combinations',
    [TK.CYO_6COOKIEBOX_NAME]: 'Create Your Own Cookie Box - 6 Cookies',
    [TK.CYO_12COOKIEBOX_NAME]: 'Create Your Own Cookie Box - 12 Cookies',
    [TK.PROMO_TEXT]: 'Add Promo Code',
    [TK.PROMO_CODE_TEXT]: 'Promo Code:',
    [TK.PROMO_EXPIRES]: 'Expires: ',
    [TK.PROMO_MESSAGE]: ' - {{message}}',
    [TK.MY_EDIBLE_REWARDS_TITLE]: 'My Edible Rewards®',
    [TK.PACKAGE_TITLE1]: 'Bundle containing',
    [TK.PACKAGE_TITLE2]: 'special treats',
    [TK.ADDON_IMAGE]: 'addon image',
    [TK.UPGRADE_IMAGE]: 'upgrade image',
    [TK.COMBINATION_IMAGE]: 'combination image',
    [TK.PROMO_TOOLTIP]: 'You can use only one Reward or Promo code per order.',
    [TK.DELIVERY_TOOLTIP]:
      'These fees help cover the cost of delivery for store owners.',
    [TK.DISTANCE_FEE_TOOLTIP]:
      'Additional surcharges may incur for delivery that exceeds 15 miles. See Terms of Use for more details',
    [TK.CURB_PICKUP]: 'Curbside Pickup',
    [TK.NO_INSTRUCTIONS]: 'NO INSTRUCTIONS',
    [TK.ONE_HOUR_DELIVERY]: 'One-Hour Delivery',
    [TK.DELIVERY_TITLE]: 'delivery information',
    [TK.DELIVERY_METHOD]: 'Delivery Method',
    [TK.DELIVERY_DAY]: 'Delivering On',
    [TK.CART_DELIVERY_INSTRUCTIONS]: 'Delivery Instructions',
    [TK.CART_DELIVERY_INSTRUCTIONS_PLACEHOLDER]:
      'Provide delivery instructions like crossroads, landmarks, door codes, and where to deliver your order.',
    [TK.DELIVERING_TO]: 'Delivering To',
    [TK.DELIVERY_TO]: 'Delivery To',
    [TK.DELIVERY_ON]: 'Delivery on',
    [TK.CART_DELIVERY_FROM]: 'Delivering From',
    [TK.HAND_DELIVERY]: 'Hand Delivery',
    [TK.PICKUP_TITLE]: 'Pickup Information',
    [TK.PICKUP_METHOD]: 'Pickup Method',
    [TK.PICKUP_DAY]: 'Pickup Date & Time',
    [TK.CART_PICKUP_INSTRUCTIONS]: 'Pickup Instructions',
    [TK.CART_PICKUP_INSTRUCTIONS_LABEL]: 'Pickup Instructions (optional)',
    [TK.CART_PICKUP_INSTRUCTIONS_PLACEHOLDER]:
      'Provide pickup instructions like wrapping requests, whether you may be late or early, or if someone else might pickup your order on your behalf.',
    [TK.CART_PICKUP_TO]: 'Name On Order',
    [TK.CART_PICKUP_FROM]: 'Pickup From',
    [TK.IN_STORE_PICKUP]: 'In-Store Pickup',
    [TK.VEHICLE_MODEL]: 'Vehicle Model',
    [TK.VEHICLE_COLOR]: 'Vehicle Color',
    [TK.EDIT_PICKUP_INFO]: 'Edit Pickup Info',
    [TK.PICKUP_TIME_PASSED_TITLE]: 'Oh no! This pickup time has passed.',
    [TK.PICKUP_TIME_PASSED_DESCRIPTION]:
      'To continue, please change your pickup time using the field above.',
    [TK.PICKUP_DATE_PASSED_TITLE]: 'Oh no! This pickup date has passed.',
    [TK.PICKUP_DATE_PASSED_DESCRIPTION]:
      'To continue, please change your pickup date by clicking the link provided.',
    [TK.PICKUP_CHANGE_DATE]: 'Change Pickup Date',
    [TK.PICKUP_CHANGE_TIME]: 'Change your pickup time',
    [TK.PICKUP_TO_CONTINUE]: 'To continue, click',
    [TK.PICKUP_BELOW_CHANGE_TIME]: 'below to change your pickup time.',
    [TK.CURB_SIDE_INFO]: 'curbside pickup information',
    [TK.IN_STORE_PICK_UP_INFO]: 'in-store pickup information',
    [TK.SHIPPING_INFORMATION]: 'shipping information',
    [TK.SHIPMENT_TITLE]: 'Shipment Information',
    [TK.SHIPMENT_METHOD]: 'Delivery Method',
    [TK.SHIPMENT_DAY]: 'Delivering On',
    [TK.SHIPMENT_TO]: 'Delivering To',
    [TK.SHIPPER]: 'FedEx',
    [TK.EDIT_SHIPPING_INFO]: 'Edit Shipping Info',
    [TK.SHIPMENT_METHOD_NAME]: 'For more info, contact Customer Service ',
    [TK.SHIPMENT_METHOD_NAME_2]: 'at: 678-992-2390',
    [TK.DISCLAIMER_1]: 'Unless specified, product containers may vary.',
    [TK.DISCLAIMER_2]:
      'For flower arrangements, floral type and colors may vary.',
    [TK.HELP_ICON]: 'Product disclaimer for flowers',
    [TK.CART_SALE_TITLE]: 'Total Charged:',
    [TK.CART_SALE_ITEM_COUNT]: '{{Number}} Item',
    [TK.CART_SALE_QTY]: '{{#}}x',
    [TK.CART_SALE_ADDON_QTY]: 'x{{#}}',
    // eslint-disable-next-line no-template-curly-in-string
    [TK.CART_SALE_PRICE]: '${{Price}}',
    [TK.CART_SALE_SUMMARY]: 'Order Summary',
    [TK.CART_SALE_DELIVERY_FEE]: 'Delivery Fees',
    [TK.CART_SALE_SHIPMENT_CHARGE]: 'Shipment Charge',
    [TK.CART_SALE_SERVICE_FEE]: 'Service Fee:',
    [TK.CART_SALE_SUBTOTAL]: 'Subtotal',
    [TK.CART_SALE_TOTAL]: 'Order Total',
    [TK.CART_COMPLIMENTARY_MESSAGE_TITLE]: 'Complimentary Gift Message',
    [TK.CART_COMPLIMENTARY_GIFT_MESSAGE]: 'Gift Message:',
    [TK.COMPLIMENTARY_NO_CARD_MESSAGE]: 'NO CARD MESSAGE',
    [TK.ADD_GIFT_MESSAGE]: 'Add Gift Message',
    [TK.ADD_GIFT_MESSAGE_WARING_TEXT1]: 'Remember to include your name!',
    [TK.ADD_GIFT_MESSAGE_WARING_TEXT2]:
      'Your message is the only way the recipient will know who sent their gift.',
    [TK.CART_FREE]: 'FREE',
    [TK.EDIT]: 'Edit',
    [TK.REMOVE]: 'Remove',
    [TK.START_SHOPPING]: 'Start Shopping',
    [TK.EMPTY_TITLE]: 'Oh No!',
    [TK.EMPTY_SUBTITLE]: 'It Looks Like Your Cart Is Currently Empty.',
    [TK.EMPTY_DESCRIPTION]:
      'Click the button below to browse our products and select the perfect treat for you or your loved one. Then come back here when you’re ready to place your order!',
    [TK.CART_ACCOUNT_TITLE]: 'Log In or Create An Account',
    [TK.CART_ACCOUNT_BODY]: 'With your account, you can',
    [TK.CART_ACCOUNT_EARN_TXT]: ' earn and use rewards ',
    [TK.CART_ACCOUNT_ORDER_TXT]: 'on this order.',
    [TK.CART_ACCOUNT_ACCOUNT_TXT]: 'With your account, you’ll have access to',
    [TK.CART_ACCOUNT_REWARDS_TXT]: ' exclusive rewards. ',
    [TK.CART_ACCOUNT_LOGIN]: 'Log In',
    [TK.CART_ACCOUNT_CREATE_ACCOUNT]: 'Create Account',
    [TK.CART_ACCOUNT_WELCOME_BACK]: 'Welcome Back!',
    [TK.CART_ACCOUNT_APPLY_REWARDS]:
      'BCART_ACCOUNT_y logging in, you can now apply one of your Edible Rewards® to this order.',
    [TK.CART_ACCOUNT_REWARD_TOOLTIP]:
      'You can use only one Reward or Promo code per order. ',
    [TK.CART_ACCOUNT_ALLERGY_TEXT]: 'Allergy & Safety Information',
    [TK.CART_ACCOUNT_FAQ_TEXT]: 'FAQs',
    [TK.CART_ACCOUNT_NO_REWARDS]: 'You currently have no rewards available.',
    [TK.CART_ACCOUNT_NO_REWARDS_MESSAGE]:
      'With your account, every order you place gets you closer to your next reward! Checkout now to earn more.',
    [TK.CART_ACCOUNT_CONTACTUS_TXT]:
      'Manage your orders, earn rewards, and get exclusive offers with your account.',
    [TK.ORDER_CONFIRMATION_PAGE_PAYMENT_EXPIRY_DATE]: 'Expiry Date:',
    [TK.VDAY_SLIDER_CONTAINER_TITLE]: 'edible official',
    [TK.VDAY_SLIDER_CONTAINER_TITLE1]: 'make things',
    [TK.VDAY_SLIDER_CONTAINER_TITLE2]: 'this valentine’s day',
    [TK.VDAY_SLIDER_CONTAINER_SUBTITLE]:
      'Select your relationship status to find the perfect gift for the love you’re celebrating!',
    [TK.VDAY_SLIDER_TITLE]: 'What’s Your Relationship Status?',
    [TK.VDAY_SLIDER_SHOP_BESTIE]: 'Shop For Your bestie',
    [TK.VDAY_SLIDER_SHOP_SITUATIONSHIP]: 'Shop For Your Situationship',
    [TK.VDAY_SLIDER_SHOP_ESSENTIALS]: 'Shop Valentine’s Essentials',
    [TK.VDAY_SLIDER_SHOP_FLIRT]: 'Shop For Your Forever Flirt',
    [TK.VDAY_SLIDER_SHOP_LOVE]: 'Shop For Your Love',
    [TK.VDAY_SLIDER_URL_BESTIE]: '/besties',
    [TK.VDAY_SLIDER_URL_SITUATIONSHIP]: '/situationship',
    [TK.VDAY_SLIDER_URL_ESSENTIALS]: '/on-thin-ice',
    [TK.VDAY_SLIDER_URL_FLIRT]: '/flirting-with-forever',
    [TK.VDAY_SLIDER_URL_LOVE]: '/love-of-my-life',
    [TK.VDAY_SLIDER_BESTIE]: 'Besties',
    [TK.VDAY_SLIDER_SITUATIONSHIP]: 'Situationship',
    [TK.VDAY_SLIDER_ESSENTIALS]: 'On thin ice',
    [TK.VDAY_SLIDER_FLIRT]: 'Flirting with forever',
    [TK.VDAY_SLIDER_LOVE]: 'Love of my life',
    [TK.ORDER_CONFIRMATION_NO_MESSAGE_TEXT]: 'NO CARD MESSAGE',
  },
};
