import { CallCenterSession } from 'api/session/types/call-center-session.interface';

export interface QuantityEditParams {
  quantity?: number;
  cartRecipientItemId: number;
  isParent: boolean;
  userId?: number;
  callCenterMode?: CallCenterSession;
  region?: number;
  action?: DynamicActionType;
  mpCartRecipientItemId?: number;
}

export enum DynamicActionType {
  add = 'add',
  remove = 'remove',
}

export interface MpQuantityEditParams {
  quantity: number;
  mpCartRecipientItemId: number;
  userId?: number;
  region?: number;
  action?: DynamicActionType;
}
