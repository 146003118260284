import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { createSelectors } from 'redux/factories/create-selectors';
import { isNotEmpty } from 'utils/array/size';
import { selectUser } from 'providers/Session/feature/user.selectors';

export const { selectData, selectIsInit: selectAreRecipientsInit } =
  createSelectors((state) => state.recipients);

export const selectExistsRecipients = (state: RootState) =>
  isNotEmpty(selectData(state));

export const selectRecipients = createSelector(selectData, (recipients) =>
  recipients.slice().reverse(),
);

export const selectMarketplaceRecipient = createSelector(
  selectUser,
  (userSession) => userSession.marketPlaceRecipients,
);

export const selectActualRecipientMarketPlace = createSelector(
  selectUser,
  (userSession) => userSession.currentMarketplaceRecipient,
);

export const selectMarketplaceRecipientId = createSelector(
  selectUser,
  (userSession) =>
    userSession.currentMarketplaceRecipient &&
    userSession.currentMarketplaceRecipient?.CartRecipientMarketplaceID,
);

export const selectMarketplaceRecipientName = createSelector(
  selectUser,
  (userSession) =>
    userSession.currentMarketplaceRecipient &&
    userSession.currentMarketplaceRecipient?.FirstName,
);

export const selectIsNewMarketplaceRecipientSelected = createSelector(
  selectUser,
  (userSession) =>
    userSession.currentMarketplaceRecipient?.CartRecipientMarketplaceID !== 0,
);

export const selectCurrentMpRecipientId = ({
  userSession: {
    data: { marketPlaceRecipients },
  },
}: RootState): number => {
  return marketPlaceRecipients && marketPlaceRecipients.length > 0
    ? marketPlaceRecipients[marketPlaceRecipients.length - 1]
        .CartRecipientMarketplaceID
    : -1;
};
