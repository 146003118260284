import { SxProps } from '@mui/system';

export const bannerImg: SxProps = {
  height: ['160px', '160px', '200px'],
  objectFit: 'cover',
};

export const saveBtn: SxProps = {
  width: { xs: '100%', sm: '280px' },
};

export const alertError: SxProps = {
  my: '24px',
};
