import {
  MarketplaceRecipientSession,
  UserSessionState,
} from 'providers/Session/feature/user.types';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchUserAccountInformation,
  fetchUserSession,
  resetCurrentMarketplaceRecipient,
  saveStore,
  updateCartCount,
  updateCurrentMarketplaceRecipient,
  updateSaleId,
} from 'providers/Session/feature/user.action';

const initialState: UserSessionState = {
  data: {
    userId: 0,
    userEmail: '',
    userName: '',
    cartId: 0,
    cartHostedRegion: -1,
    saleId: 0,
    itemsInCart: 0,
    vaultedCartId: '',
    visitLogged: false,
    orderConfirmationShown: false,
    marketPlaceRecipients: [],
    currentMarketplaceRecipient: {} as MarketplaceRecipientSession,
  },
  userAccountInformation: undefined,
  userAccountFetchStatus: Status.INIT,
  cartCountUpdateStatus: Status.INIT,
  updateSaleIdStatus: Status.INIT,
  status: Status.INIT,
  error: null,
  isCreditedStoreNumberSaved: false,
};

const userSessionSlice = createSlice({
  name: 'userSession',
  initialState,
  reducers: {
    setSessionSInitialState: () => initialState,
    clearUserAccountInformation: (state) => {
      state.userAccountInformation = undefined;
      state.userAccountFetchStatus = Status.INIT;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserSession.pending, () => ({
        ...initialState,
        status: Status.PENDING,
      }))
      .addCase(fetchUserSession.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.status = Status.ERROR;
      })
      .addCase(fetchUserSession.fulfilled, (state, { payload }) => {
        state.data = { ...state.data, ...payload };
        state.status = Status.FULFILLED;
      })
      .addCase(fetchUserAccountInformation.pending, (state) => ({
        ...state,
        userAccountFetchStatus: Status.PENDING,
      }))
      .addCase(fetchUserAccountInformation.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.userAccountFetchStatus = Status.ERROR;
      })
      .addCase(fetchUserAccountInformation.fulfilled, (state, { payload }) => {
        state.userAccountInformation = { ...payload };
        state.userAccountFetchStatus = Status.FULFILLED;
      })
      .addCase(saveStore.pending, () => ({
        ...initialState,
        status: Status.PENDING,
      }))
      .addCase(saveStore.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.status = Status.ERROR;
      })
      .addCase(saveStore.fulfilled, (state, { payload }) => {
        state.isCreditedStoreNumberSaved = !!payload;
        state.status = Status.FULFILLED;
      })
      .addCase(updateCartCount.pending, (state) => ({
        ...state,
        cartCountUpdateStatus: Status.PENDING,
      }))
      .addCase(updateCartCount.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.cartCountUpdateStatus = Status.ERROR;
      })
      .addCase(updateCartCount.fulfilled, (state) => {
        state.cartCountUpdateStatus = Status.FULFILLED;
      })
      .addCase(updateSaleId.pending, (state) => ({
        ...state,
        updateSaleIdStatus: Status.PENDING,
      }))
      .addCase(updateSaleId.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.updateSaleIdStatus = Status.ERROR;
      })
      .addCase(updateSaleId.fulfilled, (state) => {
        state.updateSaleIdStatus = Status.FULFILLED;
      })
      .addCase(updateCurrentMarketplaceRecipient.fulfilled, (state, action) => {
        state.data.currentMarketplaceRecipient = {
          ...state.data.currentMarketplaceRecipient,
          ...action.payload,
        };
      })
      .addCase(resetCurrentMarketplaceRecipient.fulfilled, (state) => {
        state.data.currentMarketplaceRecipient =
          initialState.data.currentMarketplaceRecipient;
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: userSessionReducer } = userSessionSlice;

export const { clearUserAccountInformation } = userSessionSlice.actions;

export const userSessionActions = {
  ...userSessionReducer,
  getUserSession: fetchUserSession,
  getUserAccountInfo: fetchUserAccountInformation,
};
