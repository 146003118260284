import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/Rewards/RewardLanding/styles';
import { Box, Typography } from '@mui/material';
import { Image } from 'components/common/Image/Image';
import { disabledStrawberryBadge } from 'utils/cloudinaryAssets';
import React, { FC } from 'react';
import i18next from 'i18next';

export const NoReward: FC = () => (
  <Box sx={styles.noRewardStyles} mb={5}>
    <Box sx={styles.noRewardImage}>
      <Image
        src={disabledStrawberryBadge}
        alt={i18next.t(TK.CART_ACCOUNT_REWARD_TOOLTIP)}
      />
    </Box>
    <Box sx={styles.noRewardMessageHeading}>
      <Typography sx={styles.noRewardMessageHeadingText} variant="subtitle3">
        {i18next.t(TK.CART_ACCOUNT_NO_REWARDS)}
      </Typography>
      <Typography sx={styles.noRewardMessageHeadingSubText} variant="body3">
        {i18next.t(TK.CART_ACCOUNT_NO_REWARDS_MESSAGE)}
      </Typography>
    </Box>
  </Box>
);
