import { Skeleton, Stack } from '@mui/material';
import React from 'react';

export const ForgotPasswordSkeleton: React.VoidFunctionComponent = () => (
  <Stack spacing={2} width="100%">
    <Skeleton width="60%" height={30} />
    <Skeleton width="100%" height={30} />
    <Skeleton width="100%" height={30} />
  </Stack>
);
