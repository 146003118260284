import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/OrderConfirmation/PaymentInfo/styles';
import { Billing } from 'components/OrderConfirmation/PaymentInfo/billing';
import { Box, Typography } from '@mui/material';
import { PaymentMode } from 'redux/order-confirmation/types/types';
import { PaymentModeInfo } from 'components/OrderConfirmation/PaymentInfo/paymentModeInfo';
import { roundOffNumberValue } from 'utils/number-calculations/roundTwoDecimals';
import { selectSaleData } from 'redux/order-confirmation/selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const Payment: FC = () => {
  const saleData = useSelector(selectSaleData);
  const pathUrl = window.location.pathname;
  const OrderDetailsUrl = pathUrl.includes('/order-details');
  return (
    <>
      {saleData && (
        <Box
          display="flex"
          flexDirection="column"
          width={1}
          maxWidth={1060}
          my={10}
          mx="auto"
          px={[2.5, 5, 0]}
          sx={OrderDetailsUrl ? styles.orderWrapper : styles.wrapper}
          key={saleData.SaleID}
        >
          <Typography sx={styles.title}>
            {i18next.t(TK.ORDER_CONFIRMATION_PAGE_PAYMENT_INFO)}
          </Typography>
          <Box sx={styles.cardStyle}>
            <Typography sx={styles.payMethod}>
              {i18next.t(TK.ORDER_CONFIRMATION_PAGE_PAYMENT_METHOD)}
            </Typography>
            <PaymentModeInfo saleData={saleData} />
          </Box>
          {saleData.paymentMode !== PaymentMode.VENMO && <Billing />}
          <hr className="lineStyle" />
          <Typography variant="h6" sx={styles.orderDetailsTitle}>
            {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SALE_TITLE)}{' '}
            {`$${roundOffNumberValue(saleData.total)}`}
          </Typography>
        </Box>
      )}
    </>
  );
};
